import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function CalculusSeparableODE() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirdNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    while(secondNumber === thirdNumber) {
        secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1 = firstNumber+1;
    let result2 = roundNumber(secondNumber/result1);
    let result3 = 2*result2;
    let result4 = 1 - firstNumber;
    let result5 = roundNumber(1/(secondNumber*result4));
   
    generatedProblem.push({
      key: "1-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$y = ${secondNumber}x + C$`,
      format: `$y' = ${secondNumber}$`
    });

    generatedProblem.push({
      key: "2-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$y = ${result2}x^{${result1}} + ${thirdNumber}x + C$`,
      format: `$y' = ${secondNumber}x^{${firstNumber}} + ${thirdNumber}$`
    });

    generatedProblem.push({
      key: "3-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$y = Ce^{${secondNumber}x}$`,
      format: `$y' = ${secondNumber}y$`
    });

    generatedProblem.push({
      key: "4-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$y = Ce^{${result2}x^{${result1}}}$`,
      format: `$y' = ${secondNumber}x^{${firstNumber}}y$`
    });

    generatedProblem.push({
      key: "5-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: `$y = \\sqrt{${result3}x^{${result1}} + C}$`,
      format: `$y' = \\dfrac{${secondNumber}x^{${firstNumber}}}{y}$`
    });

    generatedProblem.push({
      key: "6-csode",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      result: `$y = Ce^{${result5}x^{${result4}}}$`,
      format: `$y' = \\dfrac{y}{${secondNumber}x^{${firstNumber}}}$`
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function helpText() {
    return `
        $$ y' = f(x,y) $$
        \
        $$ f(x,y) \\hspace{2mm} \\text{is separable, it separates into} \\hspace{2mm} g(x) \\cdot h(y) $$
        \
        $$ y' = f(x,y) = g(x) \\cdot h(y) $$
        \
        $$ \\text{In these examples} \\hspace{2mm} h(y) = y \\hspace{2mm} \\text{or} \\hspace{2mm} \\frac{1}{y} \\hspace{2mm} \\text{or} \\hspace{2mm} 1. $$
        \
        $$  \\text{Let's look at when} \\hspace{2mm} h(y) = y. $$
        \
        $$ y' = \\frac{dy}{dx} = g(x) \\cdot h(y) $$
        \
        $$ \\frac{dy}{dx} = g(x) \\cdot y $$
        \
        $$ \\text{Gather all} \\hspace{2mm} y \\hspace{2mm} \\text{and} \\hspace{2mm} dy \\hspace{2mm} \\text{on one side of the quation.} $$
        \
        $$ \\text{Gather all} \\hspace{2mm} x \\hspace{2mm} \\text{and} \\hspace{2mm} dx \\hspace{2mm} \\text{on the other side of the equation.} $$
        \
        $$ dx \\cdot \\left( \\frac{dy}{dx} = g(x) \\cdot y \\right) $$
        \
        $$ \\frac{dy \\cdot dx}{dx} = g(x) \\cdot y \\cdot dx  $$
        \
        $$ dy = g(x) \\cdot y \\cdot dx $$
        \
        $$ \\frac{dy = g(x) \\cdot y \\cdot dx}{y} $$
        \
        $$ \\frac{dy}{y} = \\frac{g(x) \\cdot y \\cdot dx}{y} $$
        \
        $$ \\frac{dy}{y} = g(x) \\cdot dx $$
        \
        $$ \\text{Take the integral of both sides.} $$
        \
        $$ \\int \\left( \\frac{dy}{y} = g(x) \\cdot dx \\right)  $$
        \
        $$ \\int  \\frac{dy}{y} = \\int g(x) \\cdot dx $$
        \
        $$ \\int  \\frac{1}{y} \\cdot dy = \\int g(x) \\cdot dx $$
        \
        $$ Ln(y) + C_{1} = \\textbf{G(x)} + C_{2} $$
        \
        $$ \\text{Solve for} \\hspace{2mm} y. $$
        \
        $$ Ln(y) + C_{1} = \\textbf{G(x)} + C_{2} $$
        \
        $$ - C_{1} \\hspace{7mm} - C_{1} $$
        \
        $$ Ln(y) = \\textbf{G(x)} + C_{2} - C_{1} $$
        \
        $$ \\text{The sum of arbitrary constants = another arbitrary constant.} $$
        \
        $$ Ln(y) = \\textbf{G(x)} + C $$
        \
        $$ y = e^{\\textbf{G(x)} + C} $$
        \
        $$ y = e^{\\textbf{G(x)}} \\cdot e^{C} $$
        \
        $$ y = e^{\\textbf{G(x)}} \\cdot C $$
        \
        $$ y = C e^{\\textbf{G(x)}} $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Separable ODE"}
        instructions={"Solve the Differential Equation."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Separable ODE Help", helpTextFunc: helpText}}
      />
    </div>
  );
}