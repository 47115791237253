import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearOneStepA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * secondNumber;
      let sign = (step % 2 === 0) ? "*" : "/";


      generatedProblem.push({
        key: `${step}-losb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let formattedDisplay;
    let answer;

    if(equation.sign === "*") {
      formattedDisplay = `$${equation.secondNumber}x = ${equation.result}$`;
      answer = equation.firstNumber
    } else {
      formattedDisplay = `$\\dfrac{x}{${equation.secondNumber}} = ${equation.firstNumber}$`;
      answer = equation.result
    }

    return {
      equation: formattedDisplay,
      answer: `$x = ${answer}$`
    }
  }

  function helpText() {
    return `

    $$ \\hspace{-7mm} 1. \\hspace{3mm} 4x = 36 $$       
    \
    $$ \\dfrac{4x = 36}{4} $$
    \
    $$ \\dfrac{4x}{4} = \\dfrac{36}{4} $$
    \
    $$ \\dfrac{4 \\cdot x}{4 \\cdot 1} = \\dfrac{4 \\cdot 9}{4 \\cdot 1} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{9}{1} $$
    \
    $$ x = 9 $$
    \
    $$ \\hspace{-10mm} 2. \\hspace{3mm} \\dfrac{x}{-4} = -9 $$
    \
    $$ -4 \\cdot \\left(\\dfrac{x}{-4} = -9 \\right) $$
    \
    $$ \\dfrac{-4 \\cdot x}{-4} = -4 \\cdot -9 $$
    \
    $$ \\dfrac{-4 \\cdot x}{-4 \\cdot 1} = -4 \\cdot -9 $$
    \
    $$ \\dfrac{x}{1} = 36 $$
    \
    $$ x = 36 $$

    `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear One Step B"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "One Step B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}