import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';


export default function ArithmeticGreatestCommonDivisor() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function gcd(a, b) {
    return b === 0 ? a : gcd(b, a % b);
  }

  function equationMaker() {
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 
        16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 
        32, 33, 34, 35, 36, 38, 39, 40, 42, 44, 45, 46, 
        48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 60, 62, 63, 64];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = gcd(firstNumber,secondNumber);

      generatedProblem.push({
        key: `${step}-agcd`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ \\text{GCD} (${equation.firstNumber},${equation.secondNumber}) $`,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `
        $$ \\text{GCD} (30, 36) $$
        \
        $$ \\text{Choose the largest factor that} \\; 30 \\; \\text{and} \\; 36 \\; \\text{have in common.}$$
        \
        $$ \\text{Factors of} \\; 30 = \\; 1, 2, 3, 5, \\textbf{6}, 10, 15, 30 $$
        \
        $$ \\text{Factors of} \\; 36 = \\: 1, 2, 3, 4, \\textbf{6}, 9, 12, 18, 36 $$
        \
        $$ \\text{GCD} (30, 36) = \\textbf{6} $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Greatest Common Divisor"}
        instructions={"Find the largest factor that fits inside both numbers."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Greatest Common Divisor", helpTextFunc: helpText}}
      />
    </div>
  );
}