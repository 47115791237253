import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepLikeTermsB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, -2, -3, -4, -5, -6, -7, -8, -9, -10];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = (firstNumber * secondNumber) + (thirdNumber * secondNumber);
      if(result === 0) {
        step--;
        continue;
      }

      let sign = "-"
      let secondSign = "-"

      if(result < 0) {
        result = Math.abs(result);
        sign = "+";
      }

      if (thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        secondSign = "";
      }

      generatedProblem.push({
        key: `${step}-ltsltb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign,
        secondSign: secondSign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}x ${equation.sign} ${equation.result} = ${equation.secondSign}${equation.thirdNumber}x$`,
      answer: `$x = ${equation.secondNumber}$`
    }
  }

  function helpText() {
    return `

    $$ 5x + 32 = -3x $$       
    \
    $$ -5x \\hspace{7mm} -5x $$
    \
    $$ 0 + 32 = -3x - 5x $$
    \
    $$ 0 + 32 = (-3 - 5) \\cdot x $$
    \
    $$ 32 = (-8) \\cdot x $$
    \
    $$ 32 = -8x $$
    \
    $$ \\dfrac{32 = -8x}{-8} $$
    \
    $$ \\dfrac{32}{-8} = \\dfrac{-8x}{-8} $$
    \
    $$ \\dfrac{-8 \\cdot -4}{-8 \\cdot 1} = \\dfrac{-8 \\cdot x}{-8 \\cdot 1} $$
    \
    $$ \\dfrac{-4}{1} = \\dfrac{x}{1} $$
    \
    $$ -4 = x $$

    `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear Two Step Like Terms B"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step Like Terms B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}