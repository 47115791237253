import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';
import { simplifyFraction } from '../../../../utils/mathHelpers';

export default function PolynomialSimplify() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 8, 10, 12, 14, 15, 16, 18, 20, 21, 22, 24, 25]
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let num1 = nums[Math.floor(Math.random()*nums.length)];
      let num2 = nums[Math.floor(Math.random()*nums.length)];
      let num3 = nums[Math.floor(Math.random()*nums.length)];
      let num4 = nums[Math.floor(Math.random()*nums.length)];
      let num5 = nums[Math.floor(Math.random()*nums.length)];
      let num6 = nums[Math.floor(Math.random()*nums.length)];
      let num7 = nums[Math.floor(Math.random()*nums.length)];
      let num8 = nums[Math.floor(Math.random()*nums.length)];
      let result1 = displayFraction(num1,num5); 
      let result2 = num2 - num6;
      let result3 = num3 - num7; 
      let result4 = num4 - num8;
      let rr2 = Math.abs(result2);
      let rr3 = Math.abs(result3);
      let rr4 = Math.abs(result4);
      let xt1 = `x^{${num2}}`;
      let yt1 = `y^{${num3}}`;
      let zt1 = `z^{${num4}}`;
      let xb1 = `x^{${num6}}`;
      let yb1 = `y^{${num7}}`;
      let zb1 = `z^{${num8}}`;
      let xt2 = `x^{${rr2}}`;
      let yt2 = `y^{${rr3}}`;
      let zt2 = `z^{${rr4}}`;
      let xb2 = `x^{${rr2}}`;
      let yb2 = `y^{${rr3}}`;
      let zb2 = `z^{${rr4}}`;
      let dot = `\\cdot`;

      if (rr2 == 1) {
        xt2 = "x";
        xb2 = "x"
      }

      if (rr3 == 1) {
        yt2 = "y";
        yb2 = "y"
      }

      if (rr4 == 1) {
        zt2 = "z";
        zb2 = "z"
      }

      if (result2 > 0) {
        xb2 = " ";
      }

      if (result2 == 0) {
        xt2 = " ";
        xb2 = " ";
      }

      if (result2 < 0) {
        xt2 = " ";
      }

      if (result3 > 0) {
        yb2 = " ";
      }

      if (result3 == 0) {
        yt2 = " ";
        yb2 = " ";
      }

      if (result3 < 0) {
        yt2 = " ";
      }

      if (result4 > 0) {
        zb2 = " ";
      }

      if (result4 == 0) {
        zt2 = " ";
        zb2 = " ";
      }

      if (result4 < 0) {
        zt2 = " ";
      }

      if(result1 == 1) {
        result1 = " ";
        dot = " ";
      }

      generatedProblem.push({
        key: `${step}-pfa`,
        num1: num1,
        num2: num2,
        num3: num3,
        num4: num4,
        num5: num5,
        num6: num6,
        num7: num7,
        num8: num8,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        rr2: rr2,
        rr3: rr3,
        rr4: rr4,
        xt1: xt1,
        yt1: yt1,
        zt1: zt1,
        xb1: xb1,
        yb1: yb1,
        zb1: zb1,
        xt2: xt2,
        yt2: yt2,
        zt2: zt2,
        xb2: xb2,
        yb2: yb2,
        zb2: zb2,
        dot: dot
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {

    let answerToDisplay = `$ ${equation.result1} ${equation.dot} \\dfrac{ ${equation.xt2} ${equation.yt2} ${equation.zt2} }{ ${equation.xb2} ${equation.yb2} ${equation.zb2} } $`
    if(equation.xb2 == " " && equation.yb2 == " " && equation.zb2 == " ") {
      answerToDisplay = `$ ${equation.result1} ${equation.dot} ${equation.xt2} ${equation.yt2} ${equation.zt2} $`
    }
    if(equation.xt2 == " " && equation.yt2 == " " && equation.zt2 == " ") {
      answerToDisplay = `$ ${equation.result1} ${equation.dot} \\dfrac{ 1 }{ ${equation.xb2} ${equation.yb2} ${equation.zb2} } $`
    }

    let equationToDisplay = `$ \\dfrac{${equation.num1} ${equation.xt1} ${equation.yt1} ${equation.zt1}}{${equation.num5} ${equation.xb1} ${equation.yb1} ${equation.zb1}} $`

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
      $$ \\dfrac{15x^{5}y^{16}z^{6}}{18x^{8}y^{6}z^{21}} $$    
      \
      $$ = \\dfrac{15}{18} \\cdot \\dfrac{x^{5}}{x^{8}} \\cdot \\dfrac{y^{16}}{y^{6}} \\cdot \\dfrac{z^{6}}{z^{21}} $$
      \
      $$ = \\dfrac{3 \\cdot 5}{3 \\cdot 6} \\cdot x^{5 - 8} \\cdot y^{16 - 6} \\cdot z^{6 - 21} $$
      \
      $$ = \\dfrac{5}{6} \\cdot x^{-3} \\cdot y^{10} \\cdot z^{-15} $$
      \
      $$ = \\dfrac{5}{6} \\cdot \\dfrac{1}{x^{3}} \\cdot \\dfrac{y^{10}}{1} \\cdot \\dfrac{1}{z^{15}} $$
      \
      $$ = \\dfrac{5}{6} \\cdot \\dfrac{y^{10}}{x^{3}z^{15}} $$
      \
      $$ = \\dfrac{5y^{10}}{6x^{3}z^{15}} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Simplify"}
        instructions={"Simplify the expression so that it is written as briefly as possible."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Simplify Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
