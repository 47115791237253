import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrixMultiplication3x3() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eigthNumber = nums[Math.floor(Math.random()*nums.length)];
      let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
      let tenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eleventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let twelfthNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventeenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eighteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }

      let result1a = firstNumber*tenthNumber + secondNumber*thirteenthNumber + thirdNumber*sixteenthNumber;
      let result2a = firstNumber*eleventhNumber + secondNumber*fourteenthNumber + thirdNumber*seventeenthNumber;
      let result3a = firstNumber*twelfthNumber + secondNumber*fifteenthNumber + thirdNumber*eighteenthNumber;
      let result4a = fourthNumber*tenthNumber + fifthNumber*thirteenthNumber + sixthNumber*sixteenthNumber;
      let result5a = fourthNumber*eleventhNumber + fifthNumber*fourteenthNumber + sixthNumber*seventeenthNumber;
      let result6a = fourthNumber*twelfthNumber + fifthNumber*fifteenthNumber + sixthNumber*eighteenthNumber;
      let result7a = seventhNumber*tenthNumber + eigthNumber*thirteenthNumber + ninthNumber*sixteenthNumber;
      let result8a = seventhNumber*eleventhNumber + eigthNumber*fourteenthNumber + ninthNumber*seventeenthNumber;
      let result9a = seventhNumber*twelfthNumber + eigthNumber*fifteenthNumber + ninthNumber*eighteenthNumber;
      
      generatedProblem.push({
        key: `${step}-mm3`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eigthNumber: eigthNumber,
        ninthNumber: ninthNumber,
        tenthNumber: tenthNumber,
        eleventhNumber: eleventhNumber,
        twelfthNumber: twelfthNumber,
        thirteenthNumber: thirteenthNumber,
        fourteenthNumber: fourteenthNumber,
        fifteenthNumber: fifteenthNumber,
        sixteenthNumber: sixteenthNumber,
        seventeenthNumber: seventeenthNumber,
        eighteenthNumber: eighteenthNumber,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a,
        result5a: result5a,
        result6a: result6a,
        result7a: result7a,
        result8a: result8a,
        result9a: result9a
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {
          
          `$\\begin{pmatrix}
          ${equation.firstNumber} & ${equation.secondNumber} & ${equation.thirdNumber}\\\\
          ${equation.fourthNumber} & ${equation.fifthNumber} & ${equation.sixthNumber}\\\\
          ${equation.seventhNumber} & ${equation.eigthNumber} & ${equation.ninthNumber}
          \\end{pmatrix} 
          
        \\cdot
          
          \\begin{pmatrix}
          ${equation.tenthNumber} & ${equation.eleventhNumber} & ${equation.twelfthNumber}\\\\
          ${equation.thirteenthNumber} & ${equation.fourteenthNumber} & ${equation.fifteenthNumber}\\\\
          ${equation.sixteenthNumber} & ${equation.seventeenthNumber} & ${equation.eighteenthNumber}
          \\end{pmatrix}$`
          
          }

        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 

        <span>
        <span>
         {`$\\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a} & ${equation.result3a}\\\\
          ${equation.result4a} & ${equation.result5a} & ${equation.result6a}\\\\
          ${equation.result7a} & ${equation.result8a} & ${equation.result9a}
          \\end{pmatrix}$`}
        </span>
        </span>
    };
  }

  function helpText() {
    return `
        $$ 
        \\left( \\begin{matrix}
            \\leftarrow & Top & \\rightarrow \\\\
            \\leftarrow & Middle & \\rightarrow \\\\
            \\leftarrow & Bottom & \\rightarrow \\\\
            \\end{matrix} \\right) 
        
        \\cdot 

        \\left( \\begin{matrix}
            \\uparrow & \\uparrow & \\uparrow \\\\
            Left & Center & Right \\\\
            \\downarrow & \\downarrow & \\downarrow \\\\
            \\end{matrix} \\right) $$    

        \

        $$ = \\left( \\begin{matrix}
            Top \\cdot Left & Top \\cdot Center & Top \\cdot Right \\\\
            Middle \\cdot Left & Middle \\cdot Center & Middle \\cdot Right \\\\
            Bottom \\cdot Left & Bottom \\cdot Center & Bottom \\cdot Right \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            TL & TC & TR \\\\
            ML & MC & MR \\\\
            BL & BC & BR \\\\
            \\end{matrix} \\right) $$

        \

        $$ \\left( \\begin{matrix}
            \\textbf{2} & \\textbf{-5} & \\textbf{-3} \\\\
            -4 & 7 & 9 \\\\
            6 & 1 & -8 \\\\
            \\end{matrix} \\right) 

        \\cdot

        \\left( \\begin{matrix}
        \\textbf{1} & 7 & 2 \\\\
        \\textbf{4} & -9 & 0 \\\\
        \\textbf{6} & 0 & -1 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            \\bf{(2 \\cdot 1) + (-5 \\cdot 4) + (-3 \\cdot 6)} & TC & TR \\\\
            ML & MC & MR \\\\
            BL & BC & BR \\\\
            \\end{matrix} \\right)  $$
        
        \

        $$ = \\left( \\begin{matrix}
            \\bf{(2) + (-20) + (-18)} & TC & TR \\\\
            ML & MC & MR \\\\
            BL & BC & BR \\\\
            \\end{matrix} \\right)  $$

        \

        $$ = \\left( \\begin{matrix}
            \\textbf{2 - 20 - 18} & TC & TR \\\\
            ML & MC & MR \\\\
            BL & BC & BR \\\\
            \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            \\textbf{-36} & TC & TR \\\\
            ML & MC & MR \\\\
            BL & BC & BR \\\\
            \\end{matrix} \\right) $$

        \

        $$ \\left( \\begin{matrix}
                2 & -5 & -3 \\\\
                \\bf{-4} & \\bf{7} & \\bf{9} \\\\
                6 & 1 & -8 \\\\
                \\end{matrix} \\right) 
    
            \\cdot
    
            \\left( \\begin{matrix}
            1 & 7 & \\bf{2} \\\\
            4 & -9 & \\bf{0} \\\\
            6 & 0 & \\bf{-1} \\\\
            \\end{matrix} \\right) $$
    
        \
    
            $$ = \\left( \\begin{matrix}
                -36 & TC & TR \\\\
                ML & MC & \\bf{(-4 \\cdot 2) + (7 \\cdot 0) + (9 \\cdot -1)} \\\\
                BL & BC & BR \\\\
                \\end{matrix} \\right)  $$
            
            \
    
            $$ = \\left( \\begin{matrix}
                -36 & TC & TR \\\\
                ML & MC & \\bf{(-8) + (0) + (-9)} \\\\
                BL & BC & BR \\\\
                \\end{matrix} \\right) $$
    
            \

            $$ = \\left( \\begin{matrix}
                -36 & TC & TR \\\\
                ML & MC & \\bf{- 8 - 9} \\\\
                BL & BC & BR \\\\
                \\end{matrix} \\right) $$

            \
    
            $$ = \\left( \\begin{matrix}
                -36 & TC & TR \\\\
                ML & MC & \\bf{-17} \\\\
                BL & BC & BR \\\\
                \\end{matrix} \\right) $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Matrix Multiplication 3x3 "}
        instructions={"Multiply the 2 Matricies."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Matrix Multiplication 3x3 Help", helpTextFunc: helpText}}
      />
    </div>
  );
}