import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet, PI, roundNumber } from '../../../../utils/mathHelpers';

export default function Vector_MatrixDotProduct() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }
      let secondSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }

      let finalAnswer = (firstSet.one * secondSet.one) + (firstSet.two * secondSet.two) + (firstSet.three * secondSet.three)

      let squareRootFirstSet = Math.sqrt(Math.pow(firstSet.one, 2) + Math.pow(firstSet.two, 2) + Math.pow(firstSet.three, 2))
      let squareRootSecondSet = Math.sqrt(Math.pow(secondSet.one, 2) + Math.pow(secondSet.two, 2) + Math.pow(secondSet.three, 2))

      let vectorAngle = Math.acos(finalAnswer/(squareRootFirstSet * squareRootSecondSet)) * (180/PI)

      generatedProblem.push({
        key: `${step}-lv`,
        firstSet: firstSet,
        secondSet: secondSet,
        finalAnswer: finalAnswer,
        vectorAngle: vectorAngle
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$${equation.finalAnswer}$`}</span>
      <br/>
      <span>{`$\\text{Angle Between Vectors} = ${roundNumber(equation.vectorAngle)}^\\circ$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$<${equation.firstSet.one}, ${equation.firstSet.two}, ${equation.firstSet.three}> \\cdot <${equation.secondSet.one}, ${equation.secondSet.two}, ${equation.secondSet.three}>$`,
      answer: answerToDisplay
    }
  }

  function helpText() {
    return `
    $$ < 4, \\hspace{1mm} -6, \\hspace{1mm} 5> \\cdot <7, \\hspace{1mm} 8, \\hspace{1mm} -1> $$
    \
    $$ = (4 \\cdot 7) + (-6 \\cdot 8) + (5 \\cdot (-1)) $$
    \
    $$ = 28 - 48 - 5 $$
    \
    $$ = -25 $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Dot Product"}
        instructions={"Compute the following and find the angle between vectors."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Dot Product Help", helpTextFunc: helpText}}
      />
    </div>
  );
}