import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticNegatives() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1 = firstNumber - secondNumber;
    let result2 = secondNumber - firstNumber;
    let result3 = -firstNumber - secondNumber;
    let result4 = firstNumber + secondNumber;
    let result5 = firstNumber - secondNumber;
    let result6 = (-firstNumber)*secondNumber;
    let result7 = firstNumber*(-secondNumber);
    let result8 = firstNumber*secondNumber;
    let result9 = (-firstNumber)+(-secondNumber);
    let result10 = firstNumber+(-secondNumber);
    let result11 = firstNumber+secondNumber;
    let result12 = (-firstNumber)+secondNumber;
    let result13 = -(firstNumber+secondNumber);
    let result14 = -(firstNumber-secondNumber);
    let result15 = -(-firstNumber+secondNumber);
    let result16 = firstNumber+secondNumber;

    generatedProblem.push({
      key: "1-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result1,
      format: <span>{`$${firstNumber} - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "2-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result2,
      format: <span>{`$${secondNumber} - ${firstNumber}$`}</span>
    });

    generatedProblem.push({
      key: "3-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result3,
      format: <span>{`$-${firstNumber} - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "4-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result4,
      format: <span>{`$${firstNumber} - - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "5-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result5,
      format: <span>{`$- - ${firstNumber} - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "6-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result6,
      format: <span>{`$(- ${firstNumber}) \\cdot (${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "7-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result7,
      format: <span>{`$(${firstNumber}) \\cdot (- ${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "8-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result8,
      format: <span>{`$(- ${firstNumber}) \\cdot (- ${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "9-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result9,
      format: <span>{`$- ${firstNumber} + - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "10-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result10,
      format: <span>{`$${firstNumber} + - ${secondNumber}$`}</span>
    });

    generatedProblem.push({
      key: "11-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result11,
      format: <span>{`$- - ${firstNumber} + ${secondNumber}$`}</span>
    });

    // Leave it just in case
    // generatedProblem.push({
    //   key: "12-an",
    //   firstNumber: firstNumber,
    //   secondNumber: secondNumber,
    //   result: result12,
    //   format: <span>{`$- ${firstNumber} + ${secondNumber}$`}</span>
    // });

    generatedProblem.push({
      key: "13-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result13,
      format: <span>{`$- (${firstNumber} + ${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "14-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result14,
      format: <span>{`$- (${firstNumber} - ${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "15-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result15,
      format: <span>{`$- (- ${firstNumber} + ${secondNumber})$`}</span>
    });

    generatedProblem.push({
      key: "16-an",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result: result16,
      format: <span>{`$- (- ${firstNumber} - ${secondNumber})$`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `$$
    
      { - - = +} $$
            
      \
    
      $$ {+ + = +} $$

      \

      $$ {- + = -} $$

      \

      $$ {+ - = -} $$

      \

      $$ {4 - 6 = -(6 - 4) = -(2) = -2}

     $$`
  }

  return (
    <div>
     
      <EquationLayout
        title={"Arithmetic Negatives"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={3}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Negatives Help", helpTextFunc: helpText}}
      />
    </div>
  );
}