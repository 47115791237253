import React from 'react';
import Container from 'react-bootstrap/Container';

export default function ContactUs() {
  return (
    <Container>
      <h1 style={{ color: "#0d6efd" }}>Contact Us</h1>
      Contact us at <a href="mailto:camilod111@gmail.com" target="_blank" rel="noopener noreferrer">camilod111 at gmail.com</a>
    </Container>
  )
}
