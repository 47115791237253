import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearSystemOfEquations2x2() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = (firstNumber * secondNumber) + (thirdNumber * fourthNumber);
      let result2 = (fifthNumber * secondNumber) + (sixthNumber * fourthNumber);

      if(result1 === 0 || result2 === 0) {
        step--;
        continue;
      }

      let sign = "+";
      let secondSign = "+";

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }

      if(sixthNumber < 0) {
        sixthNumber = Math.abs(sixthNumber);
        secondSign = "-";
      }

      generatedProblem.push({
        key: `${step}-lsoe22`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        result1: result1,
        result2: result2,
        sign: sign,
        secondSign: secondSign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {`$${equation.firstNumber}x ${equation.sign} ${equation.thirdNumber}y = ${equation.result1}$`}
        </span>
        <br/>
        <span>
          {`$${equation.fifthNumber}x ${equation.secondSign} ${equation.sixthNumber}y = ${equation.result2}$`}
        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$(x, y) = (${equation.secondNumber}, ${equation.fourthNumber})$`
    }
  }

  function helpText() {
    return `
      $$ \\begin{Bmatrix} 8x - 2y = 70 \\\\ -6x + 5y = -63 \\end{Bmatrix} $$    
      \
      $$ 8x - 2y = 70 $$
      \
      $$ 8x = 70 + 2y $$
      \
      $$ x = \\dfrac{70 + 2y}{8} $$
      \
      $$ -6 \\cdot (x) + 5y = -63 $$
      \
      $$ -6 \\cdot \\left(\\dfrac{70 + 2y}{8} \\right) + 5y = -63 $$
      \
      $$ 8 \\cdot \\left( -6 \\cdot \\left(\\dfrac{70 + 2y}{8} \\right) + 5y = -63\\right) $$
      \
      $$ -6 \\cdot \\left(\\dfrac{8 \\cdot (70 + 2y)}{8} \\right) + 8 \\cdot (5y) = 8 \\cdot (-63) $$
      \
      $$ -6 \\cdot (70 + 2y) + 40y = -504 $$
      \
      $$ -420 - 12y + 40y = -504 $$
      \
      $$ -420 + 28y = -504 $$
      \
      $$ 28y = -84 $$
      \
      $$ y = -3 $$
      \
      $$ x = \\dfrac{70 + 2y}{8} $$
      \
      $$ x = \\dfrac{70 + 2 \\cdot (-3)}{8} $$
      \
      $$ x = \\dfrac{64}{8} $$
      \
      $$ x = 8 $$
      \
      $$ (x, y) = (8, -3) $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Linear System Of Equations 2x2"}
        instructions={"Solve for (x,y)."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Systems Of Equations 2x2 Help", helpTextFunc: helpText}}
      />
    </div>
  );
}