import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function RationalTwoStep() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*(secondNumber+thirdNumber);
      let sign = "+"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-ros`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.result1}}{x ${equation.sign} ${equation.thirdNumber}} = ${equation.firstNumber}$`,
      answer: `$x = ${equation.secondNumber}$`,
    }
  }

  function helpText() {
    return `
      $$ \\frac{-48}{x - 11} = 8  $$    
      \
      $$ (x - 11) \\cdot \\left( \\frac{-48}{x - 11} = 8 \\right) $$
      \
      $$ \\frac{-48(x - 11)}{x - 11} = 8(x - 11) $$
      \
      $$ \\frac{-48 \\cdot (x - 11)}{1 \\cdot (x - 11)} = 8(x - 11) $$
      \
      $$ \\frac{-48 \\cdot (x - 11)}{1 \\cdot (x - 11)} = 8(x - 11) $$
      \
      $$ \\frac{-48}{1} = 8(x - 11) $$
      \
      $$ -48 = 8(x - 11) $$
      \
      $$ -48 = 8x - 88 $$
      \
      $$ +88 \\hspace{7mm} +88 $$
      \
      $$ 40 = 8x $$
      \
      $$ \\frac{40 = 8x}{8} $$
      \
      $$ \\frac{40}{8} = \\frac{8x}{8} $$
      \
      $$ \\frac{8 \\cdot 5}{8 \\cdot 1} = \\frac{8 \\cdot x}{8 \\cdot 1} $$
      \
      $$ \\frac{5}{1} = \\frac{x}{1} $$
      \
      $$ 5 = x $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Rational Two Step"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
