export function funcMappings() {
  return {
    ...polynomialMapping(),
    ...exponentialMapping(),
    ...naturalExponentMappings(),
    ...logarithmsMappings(),
    ...naturalLogarithmMappings(),
    ...sinMappings(),
    ...cosMappings()
  }
}

export function polynomial(params) {
  let a = params[0];
  let n = params[1];

  return `${a}x^{${n}}`
}

export function polynomialPrime(params) {
  let a = params[0];
  let n = params[1];

  return `${a * n}x^{${n - 1}}`
}

export function polynomialMapping() {
  return {
    polynomial: {
      normalFunc: polynomial,
      primeFunc: polynomialPrime,
      params: 2
    }
  }
}

export function exponential(params) {
  let b = params[0];

  return `${b}^{x}`
}

export function exponentialPrime(params) {
  let b = params[0];

  return `${b}^{x}Ln(${b})`
}

export function exponentialMapping() {
  return {
    exponential: {
      normalFunc: exponential,
      primeFunc: exponentialPrime,
      params: 1
    }
  }
}

export function naturalExponent(_) {
  return `e^{x}`
}

export function naturalExponentPrime(_) {
  return naturalExponent()
}

export function naturalExponentMappings() {
  return {
    naturalExponent: {
      normalFunc: naturalExponent,
      primeFunc: naturalExponentPrime,
      params: 0
    }
  }
}

export function logarithms(params) {
  let b = params[0];

  return `Log_{${b}}(x)`
}

export function logarithmsPrime(params) {
  let b = params[0];

  return `\\dfrac{1}{Ln(${b}) \\cdot x}`
}

export function logarithmsMappings() {
  return {
    logarithms: {
      normalFunc: logarithms,
      primeFunc: logarithmsPrime,
      params: 1
    }
  }
}

export function naturalLogarithm(_) {
  return `Ln(x)`
}

export function naturalLogarithmPrime(_) {
  return `\\dfrac{1}{x}`
}

export function naturalLogarithmMappings() {
  return {
    naturalLogarithm: {
      normalFunc: naturalLogarithm,
      primeFunc: naturalLogarithmPrime,
      params: 0
    }
  }
}

export function sin(_) {
  return `\\sin(x)`
}

export function sinPrime(_) {
  return `\\cos(x)`
}

export function sinMappings() {
  return {
    sin: {
      normalFunc: sin,
      primeFunc: sinPrime,
      params: 0
    }
  }
}

export function cos(_) {
  return `\\cos(x)`
}

export function cosPrime(_) {
  return `-\\sin(x)`
}

export function cosMappings() {
  return {
    cos: {
      normalFunc: cos,
      primeFunc: cosPrime,
      params: 0
    }
  }
}
