import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticPercentB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums1 = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12 ,13 ,14 ,15, 16, 17, 18, 19, 20];
    const nums2 = [1, 2, 4, 5, 10, 20, 25, 50, 100]
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums1[Math.floor(Math.random()*nums1.length)];
      let secondNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let result1 = 100/secondNumber;
      let result2 = firstNumber*secondNumber;

      generatedProblem.push({
        key: `${step}-apb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber} \\: is \\: what \\: percent \\: of \\: ${equation.result2} \? $`,
      answer: `$${equation.result1} \\% $`
    }
  }

  function helpText() {
    return `$$
      { 9 \\hspace{2mm} is \\hspace{2mm} what \\hspace{2mm} \\% \\hspace{2mm} of \\hspace{2mm} 45?} $$
      
      \

      $$ { = \\frac{9}{45} \\cdot 100} $$
            
      \
      
      $$ { = \\frac{1 \\cdot 9}{5 \\cdot 9} \\cdot 100} $$
      
      \
      
      $$ { = \\frac{1}{5} \\cdot 100} $$
      
      \
      
      $$ { = \\frac{1}{5} \\cdot \\dfrac{100}{1}} $$
    
      \
      
      $$ { = \\frac{100}{5}} $$
      
      \
      
      $$ { = \\frac{20 \\cdot 5}{1 \\cdot 5}} $$
      
      \
      
      $$ { = \\frac{20}{1}} $$
      
      \

      $$ {= 20 \\%}
    $$`
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Percent B"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Percent B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}