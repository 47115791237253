import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearSystemOfEquations3x3() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eigthNumber = nums[Math.floor(Math.random()*nums.length)];
      let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
      let tenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eleventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let twelvethNumber = nums[Math.floor(Math.random()*nums.length)];

      let result1 = (firstNumber * secondNumber) + (thirdNumber * fourthNumber) + (fifthNumber * sixthNumber);
      let result2 = (seventhNumber * secondNumber) + (eigthNumber * fourthNumber) + (ninthNumber * sixthNumber);
      let result3 = (tenthNumber * secondNumber) + (eleventhNumber * fourthNumber) + (twelvethNumber * sixthNumber);

      if(result1 === 0 || result2 === 0 || result3 === 0) {
        step--;
        continue;
      }

      let sign = "+";
      let secondSign = "+";
      let thirdSign = "+";
      let fourthSign = "+";
      let fifthSign = "+";
      let sixthSign = "+";

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }

      if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        secondSign = "-";
      }

      if(eigthNumber < 0) {
        eigthNumber = Math.abs(eigthNumber);
        thirdSign = "-";
      }

      if(ninthNumber < 0) {
        ninthNumber = Math.abs(ninthNumber);
        fourthSign = "-";
      }

      if(eleventhNumber < 0) {
        eleventhNumber = Math.abs(eleventhNumber);
        fifthSign = "-";
      }

      if(twelvethNumber < 0) {
        twelvethNumber = Math.abs(twelvethNumber);
        sixthSign = "-";
      }

      generatedProblem.push({
        key: `${step}-lsoe33`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eigthNumber: eigthNumber,
        ninthNumber: ninthNumber,
        tenthNumber: tenthNumber,
        eleventhNumber: eleventhNumber,
        twelvethNumber: twelvethNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        sign: sign,
        secondSign: secondSign,
        thirdSign: thirdSign,
        fourthSign: fourthSign,
        fifthSign: fifthSign,
        sixthSign: sixthSign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {`$${equation.firstNumber}x ${equation.sign} ${equation.thirdNumber}y ${equation.secondSign} ${equation.fifthNumber}z = ${equation.result1}$`}
        </span>
        <br/>
        <span>
          {`$${equation.seventhNumber}x ${equation.thirdSign} ${equation.eigthNumber}y ${equation.fourthSign} ${equation.ninthNumber}z = ${equation.result2}$`}
        </span>
        <br/>
        <span>
          {`$${equation.tenthNumber}x ${equation.fifthSign} ${equation.eleventhNumber}y ${equation.sixthSign} ${equation.twelvethNumber}z = ${equation.result3}$`}
        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$(x, y, z) = (${equation.secondNumber}, ${equation.fourthNumber}, ${equation.sixthNumber})$`
    };
  }

  function helpText() {
    return `
      $$ \\begin{Bmatrix} -2x - 6y - 3z = -50 \\\\ -3x + 7y + 3z = 33 \\\\ -7x + 6y -8z = -74 \\end{Bmatrix} $$    
      \
      $$ \\left(
        \\begin{matrix}
        -2 & -6 & -3 \\\\
        -3 & 7 & 3 \\\\
        -7 & 6 & -8 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            -50  \\\\
            33  \\\\
            -74  \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{1} = -{\\scriptstyle{\\frac{1}{2}}} \\cdot R_{1} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 3 & \\frac{3}{2} \\\\
        -3 & 7 & 3 \\\\
        -7 & 6 & -8 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            25  \\\\
            33  \\\\
            -74  \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{2} = 3 \\cdot R_{1} + R_{2} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 3 & \\frac{3}{2} \\\\
        0 & 16 & \\frac{15}{2} \\\\
        -7 & 6 & -8 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            25  \\\\
            108  \\\\
            -74  \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{3} = 7 \\cdot R_{1} + R_{3} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 3 & \\frac{3}{2} \\\\
        0 & 16 & \\frac{15}{2} \\\\
        0 & 27 & \\frac{5}{2} \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            25  \\\\
            108  \\\\
            101 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{2} = {\\scriptstyle{\\frac{1}{16}}} \\cdot R_{2} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 3 & \\frac{3}{2} \\\\
        0 & 1 & \\frac{15}{32} \\\\
        0 & 27 & \\frac{5}{2} \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            25  \\\\
            \\frac{27}{4}  \\\\
            101 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{1} = -3 \\cdot R_{2} + R_{1} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 0 & \\frac{3}{32} \\\\
        0 & 1 & \\frac{15}{32} \\\\
        0 & 27 & \\frac{5}{2} \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            \\frac{19}{4} \\\\
            \\frac{27}{4} \\\\
            101 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{3} = -27 \\cdot R_{2} + R_{3} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 0 & \\frac{3}{32} \\\\
        0 & 1 & \\frac{15}{32} \\\\
        0 & 0 & \\frac{-325}{32} \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            \\frac{19}{4} \\\\
            \\frac{27}{4} \\\\
            \\frac{-325}{4} \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{3} = -{\\scriptstyle{\\frac{32}{325}}} \\cdot R_{3} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 0 & \\frac{3}{32} \\\\
        0 & 1 & \\frac{15}{32} \\\\
        0 & 0 & 1 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            \\frac{19}{4} \\\\
            \\frac{27}{4} \\\\
            8 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{1} = -{\\scriptstyle{\\frac{3}{32}}} \\cdot R_{3} + R_{1} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 0 & 0 \\\\
        0 & 1 & \\frac{15}{32} \\\\
        0 & 0 & 1 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            4 \\\\
            \\frac{27}{4} \\\\
            8 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ New \\hspace{2mm} R_{2} = -{\\scriptstyle{\\frac{15}{32}}} \\cdot R_{3} + R_{2} $$
      \
      $$ \\left(
        \\begin{matrix}
        1 & 0 & 0 \\\\
        0 & 1 & 0 \\\\
        0 & 0 & 1 \\\\
        \\end{matrix}
        \\left|
          \\begin{matrix}
            4 \\\\
            3 \\\\
            8 \\\\
          \\end{matrix}
        \\right.
      \\right) $$
      \
      $$ \\begin{Bmatrix} 1x + 0y + 0z = 4 \\\\ 0x + 1y + 0z = 3 \\\\ 0x + 0y + 1z = 8 \\end{Bmatrix} $$
      \
      $$ \\begin{Bmatrix} x = 4 \\\\ y = 3 \\\\ z = 8 \\end{Bmatrix} $$
      \
      $$ (x, y, z) = (4, 3, 8) $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear System Of Equations 3x3"}
        instructions={"Solve for (x,y,z)."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Systems Of Equations 3x3 Help", helpTextFunc: helpText}}
      />
    </div>
  );
}