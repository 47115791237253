import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMagnitude() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [-8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = Math.sqrt((Math.pow(firstNumber,2)) + (Math.pow(secondNumber,2)) + (Math.pow(thirdNumber,2)));

      generatedProblem.push({
        key: `${step}-lvm`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$||<${equation.firstNumber}, ${equation.secondNumber}, ${equation.thirdNumber}>||$`,
      answer: `$${Math.round(equation.result*100)/100}$`
    }
  }

  function helpText() {
    return `
    $$ || < 3, \\hspace{1mm} -6, \\hspace{1mm} 5> || $$
    \
    $$ = \\sqrt{(3)^{2} + (-6)^{2} + (5)^{2}} $$
    \
    $$ = \\sqrt{(9) + (36) + (25)} $$
    \
    $$ = \\sqrt{70} = 8.37 $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Magnitude"}
        instructions={"Compute the magnitude."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Magnitude Help", helpTextFunc: helpText}}
      />
    </div>
  );
}