import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrix3x3LinearCombinations() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eigthNumber = nums[Math.floor(Math.random()*nums.length)];
      let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
      let tenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eleventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let twelfthNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventeenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eighteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let nineteenthNumber = nums[Math.floor(Math.random()*nums.length)];
      let twentiethNumber = nums[Math.floor(Math.random()*nums.length)];
      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }

      let result1a = nineteenthNumber*firstNumber + twentiethNumber*tenthNumber;
      let result2a = nineteenthNumber*secondNumber + twentiethNumber*eleventhNumber;
      let result3a = nineteenthNumber*thirdNumber + twentiethNumber*twelfthNumber;
      let result4a = nineteenthNumber*fourthNumber + twentiethNumber*thirteenthNumber;
      let result5a = nineteenthNumber*fifthNumber + twentiethNumber*fourteenthNumber;
      let result6a = nineteenthNumber*sixthNumber + twentiethNumber*fifteenthNumber;
      let result7a = nineteenthNumber*seventhNumber + twentiethNumber*sixteenthNumber;
      let result8a = nineteenthNumber*eigthNumber + twentiethNumber*seventeenthNumber;
      let result9a = nineteenthNumber*ninthNumber + twentiethNumber*eighteenthNumber;
      
      generatedProblem.push({
        key: `${step}-vm3`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eigthNumber: eigthNumber,
        ninthNumber: ninthNumber,
        tenthNumber: tenthNumber,
        eleventhNumber: eleventhNumber,
        twelfthNumber: twelfthNumber,
        thirteenthNumber: thirteenthNumber,
        fourteenthNumber: fourteenthNumber,
        fifteenthNumber: fifteenthNumber,
        sixteenthNumber: sixteenthNumber,
        seventeenthNumber: seventeenthNumber,
        eighteenthNumber: eighteenthNumber,
        nineteenthNumber: nineteenthNumber,
        twentiethNumber: twentiethNumber,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a,
        result5a: result5a,
        result6a: result6a,
        result7a: result7a,
        result8a: result8a,
        result9a: result9a
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let sign = "+";
    let twentiethNumber = Math.abs(equation.twentiethNumber)
    if (equation.twentiethNumber < 0) {
      sign = "-"
    }

    let equationToDisplay = (
      <span>
        {
        
        `$${equation.nineteenthNumber} \\cdot \\begin{pmatrix}
        ${equation.firstNumber} & ${equation.secondNumber} & ${equation.thirdNumber}\\\\
        ${equation.fourthNumber} & ${equation.fifthNumber} & ${equation.sixthNumber}\\\\
        ${equation.seventhNumber} & ${equation.eigthNumber} & ${equation.ninthNumber}
        \\end{pmatrix} 

        ${sign}
        
        ${twentiethNumber} \\cdot \\begin{pmatrix}
        ${equation.tenthNumber} & ${equation.eleventhNumber} & ${equation.twelfthNumber}\\\\
        ${equation.thirteenthNumber} & ${equation.fourteenthNumber} & ${equation.fifteenthNumber}\\\\
        ${equation.sixteenthNumber} & ${equation.seventeenthNumber} & ${equation.eighteenthNumber}
        \\end{pmatrix}$`
        
        }
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 
        <span>
         {`$\\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a} & ${equation.result3a}\\\\
          ${equation.result4a} & ${equation.result5a} & ${equation.result6a}\\\\
          ${equation.result7a} & ${equation.result8a} & ${equation.result9a}
          \\end{pmatrix}$`}
        </span>
    };
  }

  function helpText() {
    return `
      $$ 5 \\cdot 
      \\left( \\begin{matrix}
        2 & -6 & -3 \\\\
        -4 & 7 & 9 \\\\
        5 & 1 & -8 \\\\
        \\end{matrix} \\right) 
        
        -

        2 \\cdot 
      \\left( \\begin{matrix}
        1 & 7 & 2 \\\\
        4 & -9 & 0 \\\\
        6 & 0 & -1 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
        5 \\cdot 2 & 5 \\cdot -6 & 5 \\cdot -3 \\\\
        5 \\cdot -4 & 5 \\cdot 7 & 5 \\cdot 9 \\\\
        5 \\cdot 5 & 5 \\cdot 1 & 5 \\cdot -8 \\\\
        \\end{matrix} \\right) 
        
        -
 
      \\left( \\begin{matrix}
        2 \\cdot 1 & 2 \\cdot 7 & 2 \\cdot 2 \\\\
        2 \\cdot 4 & 2 \\cdot -9 & 2 \\cdot 0 \\\\
        2 \\cdot 6 & 2 \\cdot 0 & 2 \\cdot -1 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            10 & -30 & -15 \\\\
            -20 & 35 & 45 \\\\
            25 & 5 & -40 \\\\
            \\end{matrix} \\right) 
            
            -
     
          \\left( \\begin{matrix}
            2 & 14 & 4 \\\\
            8 & -18 & 0 \\\\
            12 & 0 & -2 \\\\
            \\end{matrix} \\right) $$

            \

            $$ = \\left( \\begin{matrix}
                10 - 2 & -30 - 14 & -15 - 4 \\\\
                -20 - 8 & 35 - -18 & 45 - 0 \\\\
                25 - 12 & 5 - 0 & -40 - -2 \\\\
                \\end{matrix} \\right) $$

                \

                $$ = \\left( \\begin{matrix}
                    8 & -44 & -19 \\\\
                    -28 & 53 & 45 \\\\
                    13 & 5 & -38 \\\\
                    \\end{matrix} \\right) $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Vector/Matrix Matrix 3x3 Linear Combinations"}
        instructions={"Compute the Linear Combination of the Matricies."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Matrix 3x3 Linear Combinations Help", helpTextFunc: helpText}}
      />
    </div>
  );
}