import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticDividingFractions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const practice_problems_num = 8;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*fourthNumber;
      let result2 = secondNumber*thirdNumber;

      generatedProblem.push({
        key: `${step}-adf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}}{${equation.secondNumber}} \\div \\dfrac{${equation.thirdNumber}}{${equation.fourthNumber}}$`,
      answer: `$${displayFraction(equation.result1, equation.result2)}$`
    }
  }

  function helpText() {
    return `
    
    $$ \\dfrac{3}{5} \\div \\dfrac{2}{8} $$  
      \
      $$ = \\dfrac{3}{5} \\cdot \\dfrac{8}{2} $$
      \
      $$ = \\dfrac{3 \\cdot 8}{5 \\cdot 2} $$
      \     
      $$ = \\dfrac{24}{10} $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Arithmetic Dividing Fractions"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Dividing Fractions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}