import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticLeastCommonMultiple() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function gcd(a, b) {
    return b === 0 ? a : gcd(b, a % b);
  }

  function lcm(a, b) {
    return (a * b) / gcd(a, b);
  }

  function equationMaker() {
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 
        16, 17, 18, 19, 20 
        
        /*
        , 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 
        48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64
        */

    ];

    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random() * nums.length)];
      let secondNumber = nums[Math.floor(Math.random() * nums.length)];
      let result = lcm(firstNumber, secondNumber);

      generatedProblem.push({
        key: `${step}-alcm`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem));
    });
    setFormattedProblems(formattedProblemList);
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ \\text{LCM} (${equation.firstNumber},${equation.secondNumber}) $`,
      answer: `$${equation.result}$`
    };
  }

  function helpText() {
    return `
        $$ \\text{LCM} (6,8) $$
        \
        $$ \\text{Choose the smallest multiple that both} \\; 6 \\; \\text{and} \\; 8 \\; \\text{fit inside of.} $$
        \
        $$ 6 \\cdot 8 = 48 $$
        \
        $$ \\text{Multiples of} \\; 6 = \\; 6, 12, 18, \\textbf{24}, 30, 36, 42, \\textbf{48} $$
        \
        $$ \\text{Multiples of} \\; 8 = \\: 8, 16, \\textbf{24}, 32, 40, \\textbf{48} $$
        \
        $$ \\textbf{24} , \\textbf{48} $$
        \
        $$ \\textbf{24} < \\textbf{48} $$
        \
        $$ \\text{LCM} (6,8) = \\textbf{24} $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Least Common Multiple"}
        instructions={"Find the smallest product that both numbers fit inside of."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Least Common Multiple Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
