import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';


export default function LinearFourStepLikeTermsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * ((secondNumber * thirdNumber) + (fourthNumber * thirdNumber)) + fifthNumber * thirdNumber;
      if(result === 0) {
        step--;
        continue;
      }
      let sign = "+";
      let secondSign = "+";

      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign = "-";
      }

      if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        secondSign = "-";
      }

      generatedProblem.push({
        key: `${step}-lfslta`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        result: result,
        sign: sign,
        secondSign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(${equation.secondNumber}x ${equation.sign} ${equation.fourthNumber}x) ${equation.secondSign} ${equation.fifthNumber}x = ${equation.result}$`,
      answer: `$x = ${equation.thirdNumber}$`
    };
  }

  function helpText() {
    return `

    $$ 3(-2x + 6x) - 5x = 56 $$       
    \
    $$ 3((-2 + 6) \\cdot x) - 5x = 56 $$
    \
    $$ 3((4) \\cdot x) - 5x = 56 $$
    \
    $$ 3(4x) - 5x = 56 $$
    \
    $$ 3 \\cdot 4 \\cdot x - 5x = 56 $$
    \
    $$ 12 \\cdot x - 5x = 56 $$
    \
    $$ 12x - 5x = 56 $$
    \
    $$ (12 - 5) \\cdot x = 56 $$
    \
    $$ 7x = 56 $$
    \
    $$ \\dfrac{7x = 56}{7}$$
    \
    $$ \\dfrac{7x}{7} = \\dfrac{56}{7} $$
    \
    $$ \\dfrac{7 \\cdot x}{7 \\cdot 1} = \\dfrac{7 \\cdot 8}{7 \\cdot 1} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{8}{1} $$
    \
    $$ x = 8 $$
    
    `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Linear Four Step Like Terms A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Four Step Like Terms A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}