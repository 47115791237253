import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = (firstNumber * secondNumber) + thirdNumber;
      let sign = "-"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "+";
      }

      generatedProblem.push({
        key: `${step}-ltsb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{x ${equation.sign} ${equation.thirdNumber}}{${equation.secondNumber}} = ${equation.firstNumber}$`,
      answer: `$x = ${equation.result}$`
    }
  }
  function helpText() {
    return `

    $$ \\dfrac{x + 12}{10} = 7 $$       
    \
    $$ 10 \\cdot \\left(\\dfrac{x + 12}{10} = 7 \\right) $$
    \
    $$ \\dfrac{10(x + 12)}{10} = 10 \\cdot 7 $$
    \
    $$ x + 12 = 70 $$
    \
    $$ -12 \\hspace{7mm} -12 $$
    \
    $$ x + 0 = 70 - 12 $$
    \
    $$ x = 58 $$

    `
  }

  return (
    <div>
  
      <EquationLayout
        title={"Linear Two Step B"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}