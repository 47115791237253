import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';
import { trigFuncMappings, unitCircleMappings } from '../../../../utils/trigonometryHelper';

export default function TrigonometryUnitCircleA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let trigFunc = randomNumFromSet(trigFuncMappings())
      let inputs = randomNumFromSet(unitCircleMappings())
      let answer = trigFunc.answer(inputs)

      generatedProblem.push({
        key: `${step}-tuca`,
        trigFunc: trigFunc.func(inputs),
        answer: `${answer.sign}${answer.value}`
      })
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.trigFunc}$`,
      answer: `$${equation.answer}$`,
    }
  }

  function helpText() {
    return `
    $$ \\text{Unit = Length of 1} $$
    \
    $$ \\text{Unit Circle = Circle with Radius of 1} $$
    \
    $$ \\text{Cosine} = cos(\\theta) = \\frac{x}{r} = \\frac{x}{1} = x = \\text{x-coordinate} $$
    \
    $$ \\text{Sine} = sin(\\theta) = \\frac{y}{r} = \\frac{y}{1} = y = \\text{y-coordinate} $$
    \
    $$ \\text{Tangent} = tan(\\theta) = \\frac{sin(\\theta)}{cos(\\theta)} = \\frac{y}{x} $$
    \
    $$ \\text{Secant} = sec(\\theta) = \\frac{1}{cos(\\theta)} = \\frac{1}{x} $$
    \
    $$ \\text{Cosecant} = csc(\\theta) = \\frac{1}{sin(\\theta)} = \\frac{1}{y} $$
    \
    $$ \\text{Cotangent} =cot(\\theta) = \\frac{1}{tan(\\theta)} = \\frac{1}{\\frac{sin(\\theta)}{cos(\\theta)}} = \\frac{cos(\\theta)}{sin(\\theta)} = \\frac{x}{y} $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Trigonometry Unit Circle A"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Unit Circle A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
