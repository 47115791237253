import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import rightTrinagle from '../images/right-triangle.png';

export default function TrigonometryRightTriangles() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.sqrt(Math.pow(firstNumber,2) + Math.pow(secondNumber,2));
      let result2 = (180*Math.atan(firstNumber/secondNumber))/3.14159265359;
      let result3 = 90 - result2;
      let result4 = (firstNumber*secondNumber)/2;
      let result5 = firstNumber + secondNumber + result1;

      generatedProblem.push({
        key: `${step}-trt`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$H = ${roundNumber(equation.result1)}$`}</span>
      <br/>
      <span>{`$a = ${roundNumber(equation.result2)}^\\circ$`}</span>
      <br/>
      <span>{`$b = ${roundNumber(equation.result3)}^\\circ$`}</span>
      <br/>
      <span>{`$Area = ${roundNumber(equation.result4)}$`}</span>
      <br/>
      <span>{`$Perimeter = ${roundNumber(equation.result5)}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$A = ${equation.firstNumber}, B = ${equation.secondNumber}$`,
      answer: answerToDisplay,
    }
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function helpText() {
    return `
    $$ \\text{Pythagorean Theorem}: A^{2} + B^{2} = H^{2} $$
    \
      $$ a + b + 90^{\\circ} = 180^{\\circ} $$    
      \
      $$ sin(a) = \\frac{\\text{opposite}}{\\text{hypotenuse}} = \\frac{A}{H} $$
      \
      $$ cos(a) = \\frac{\\text{adjacent}}{\\text{hypotenuse}} = \\frac{B}{H} $$
      \
      $$ tan(a) = \\frac{\\text{opposite}}{\\text{adjacent}} = \\frac{A}{B} $$
      \
      $$ a = sin^{-1} \\left(\\frac{A}{H} \\right) = arcsin \\left(\\frac{A}{H} \\right) $$
      \
      $$ \\text{Area of a Right Triangle} = \\frac{A \\cdot B}{2} $$
      \
      $$ \\text{Perimeter} = A + B + H $$
      `
  }

  return (
    <div>
     
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <img src={rightTrinagle} alt="Generic Trinagle" height={200} width={250}/>
      </div>

      <EquationLayout
        title={"Trigonometry Right Triangle"}
        instructions={"Compute all sides, angles, area, and perimeter."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Right Triangles Help", helpTextFunc: helpText}}
      />
    </div>
  );
}