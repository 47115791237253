import React, { useState } from 'react';

import Exp_LogExpOneStep from './Exp_LogExpOneStep';
import Exp_LogExpTwoStepA from './Exp_LogExpTwoStepA';
import Exp_LogExpTwoStepB from './Exp_LogExpTwoStepB';
import Exp_LogExpTwoStepC from './Exp_LogExpTwoStepC';
import Exp_LogExpThreeStep from './Exp_LogExpThreeStep';
import Exp_LogExpAdd from './Exp_LogExpAdd';
import Exp_LogExpSubtract from './Exp_LogExpSubtract';
import Exp_LogExpMultiply from './Exp_LogExpMultiply';
import Exp_LogLogOneStepA from './Exp_LogLogOneStepA';
import Exp_LogLogTwoStepA from './Exp_LogLogTwoStepA';
import Exp_LogLogOneStepB from './Exp_LogLogOneStepB';
import Exp_LogLogAddition from './Exp_LogLogAddition';
import Exp_LogLogSubtraction from './Exp_LogLogSubtraction';
import Exp_LogLogExponent from './Exp_LogLogExponent';
import Exp_LogChangeofBase from './Exp_LogChangeofBase';
import Button from 'react-bootstrap/Button';

const EXP_ONE_STEP = "exp_one_step"
const EXP_TWO_STEP_A = "exp_two_step_a"
const EXP_TWO_STEP_B = "exp_two_step_b"
const EXP_TWO_STEP_C = "exp_two_step_c"
const EXP_THREE_STEP = "exp_three_step"
const EXP_ADD = "exp_add"
const EXP_SUBTRACT = "exp_subtract"
const EXP_MULTIPLY = "exp_multiply"
const LOG_ONE_STEP_A = "log_one_step_a"
const LOG_ONE_STEP_B = "log_one_step_b"
const LOG_TWO_STEP_A = "log_two_step_a"
const LOG_SUBTRACTION = "log_subtraction"
const LOG_ADDITION = "log_addition"
const LOG_EXPONENT = "log_exponent"
const CHANGE_OF_BASE = "change_of_base"
const FUNCTION_TYPES = [EXP_ONE_STEP, EXP_TWO_STEP_A, EXP_TWO_STEP_B, EXP_TWO_STEP_C, EXP_THREE_STEP, 
  EXP_ADD, EXP_SUBTRACT, EXP_MULTIPLY, LOG_ONE_STEP_A, LOG_ONE_STEP_B, LOG_TWO_STEP_A, LOG_ADDITION, 
  LOG_SUBTRACTION, LOG_EXPONENT, CHANGE_OF_BASE];

const functionTypeDisplayNames = {
  "exp_one_step": "Exp One Step",
  "exp_two_step_a": "Exp Two Step A",
  "exp_two_step_b": "Exp Two Step B",
  "exp_two_step_c": "Exp Two Step C",
  "exp_three_step": "Exp Three Step",
  "exp_add": "Exp Add",
  "exp_subtract": "Exp Subtract",
  "exp_multiply": "Exp Multiply",
  "log_one_step_a": "Log One Step A",
  "log_one_step_b": "Log One Step B",
  "log_two_step_a": "Log Two Step A",
  "log_addition": "Log Addition",
  "log_subtraction": "Log Subtraction",
  "log_exponent": "Log Exponent",
  "change_of_base": "Change of Base"
}

export default function Exp_Log() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(EXP_ONE_STEP);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case EXP_ONE_STEP: {
        return <Exp_LogExpOneStep />;
      }
      case EXP_TWO_STEP_A: {
        return <Exp_LogExpTwoStepA />;
      }
      case EXP_TWO_STEP_B: {
        return <Exp_LogExpTwoStepB />;
      }
      case EXP_TWO_STEP_C: {
        return <Exp_LogExpTwoStepC />;
      }
      case EXP_THREE_STEP: {
        return <Exp_LogExpThreeStep />;
      }
      case EXP_ADD: {
        return <Exp_LogExpAdd />;
      }
      case EXP_SUBTRACT: {
        return <Exp_LogExpSubtract />;
      }
      case EXP_MULTIPLY: {
        return <Exp_LogExpMultiply />;
      }
      case LOG_ONE_STEP_A: {
        return <Exp_LogLogOneStepA />;
      }
      case LOG_ONE_STEP_B: {
        return <Exp_LogLogOneStepB />;
      }
      case LOG_TWO_STEP_A: {
        return <Exp_LogLogTwoStepA />;
      }
      case LOG_ADDITION: {
        return <Exp_LogLogAddition />;
      }
      case LOG_SUBTRACTION: {
        return <Exp_LogLogSubtraction />;
      }
      case LOG_EXPONENT: {
        return <Exp_LogLogExponent />;
      }
      case CHANGE_OF_BASE: {
        return <Exp_LogChangeofBase />;
      }
      
      default:
        return <div>POOP</div>;
    }
    
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}