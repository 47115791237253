import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearThreeStepLikeTermsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * ((secondNumber * thirdNumber) + (fourthNumber * thirdNumber));
      if(result === 0) {
        step--;
        continue;
      }
      let sign = "+";

      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-lthslta`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(${equation.secondNumber}x ${equation.sign} ${equation.fourthNumber}x) = ${equation.result}$`,
      answer: `$x = ${equation.thirdNumber}$`
    }
  }

  function helpText() {
    return `

    $$ -8(4x - 7x) = 48 $$       
    \
    $$ \\dfrac{-8(4x - 7x) = 48}{-8} $$
    \
    $$ \\dfrac{-8(4x - 7x)}{-8} = \\dfrac{48}{-8} $$
    \
    $$ \\dfrac{-8 \\cdot (4x - 7x)}{-8 \\cdot 1} = \\dfrac{-8 \\cdot -6}{-8 \\cdot 1} $$
    \
    $$ \\dfrac{(4x - 7x)}{1} = \\dfrac{-6}{1} $$
    \
    $$ 4x - 7x = -6 $$
    \
    $$ (4 - 7) \\cdot x = -6 $$
    \
    $$ (-3) \\cdot x = -6 $$
    \
    $$ -3x = -6 $$
    \
    $$ \\dfrac{-3x = -6}{-3} $$
    \
    $$ \\dfrac{-3x}{-3} = \\dfrac{-6}{-3} $$
    \
    $$ \\dfrac{-3 \\cdot x}{-3 \\cdot 1} = \\dfrac{-3 \\cdot 2}{-3 \\cdot 1} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{2}{1} $$
    \
    $$ x = 2 $$
    `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Linear Three Step Like Terms A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Three Step Like Terms A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}