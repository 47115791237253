import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function Exp_LogExpMultiply() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
        let firstNumber = nums[Math.floor(Math.random()*nums.length)];
        let secondNumber = nums[Math.floor(Math.random()*nums.length)];
        let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
        let result1 = Math.pow(firstNumber,secondNumber);
        let result2 = secondNumber/thirdNumber;
        let result3 = secondNumber*thirdNumber;
      
      generatedProblem.push({
        key: `${step}-em`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        result3: result3
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$(${equation.firstNumber}^{x})^{${equation.thirdNumber}} = ${equation.result1}$`,
      answer: `$x = ${displayFraction(equation.secondNumber, equation.thirdNumber)}$`
    }
  }

  function helpText() {
    return `
    $$ (4^{x})^{5} = 64 $$
    \
      $$ 4^{x \\cdot 5} = 64 $$    
      \
      $$ 4^{5x} = 64 $$
      \
      $$ 4^{5x} = 4^{3} $$
      \
      $$ 5x = 3 $$
      \
      $$ \\frac{5x = 3}{5} $$
      \
      $$ \\frac{5x}{5} = \\frac{3}{5} $$
      \
      $$ x = \\frac{3}{5} = 0.6 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Exponential Multiply"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Exponential Multiply Help", helpTextFunc: helpText}}
      />
    </div>
  );
}