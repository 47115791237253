import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialQuadraticFunctions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, -2, -3, -4, -5];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = firstNumber*Math.pow(fourthNumber,2)+secondNumber*fourthNumber+thirdNumber;
      let seventhNumber = firstNumber*Math.pow(fifthNumber,2)+secondNumber*fifthNumber+thirdNumber;
      let eighthNumber = -secondNumber/(2*firstNumber);
      let ninthNumber = Math.round((firstNumber*Math.pow(eighthNumber,2)+secondNumber*eighthNumber+thirdNumber)*100)/100;
      let bottom = ninthNumber;
      let top = "\\infty";
      let sign1 = "+";
      let sign2 = "+";

      if(firstNumber < 0) {
        bottom = "-\\infty";
        top = ninthNumber;
      }

      if(firstNumber > 0) {
        secondNumber = Math.abs(secondNumber);
        sign1 = "-";
      }
      
      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign1 = "-";
      }

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign2 = "-";
      }

      generatedProblem.push({
        key: `${step}-pqf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eighthNumber: eighthNumber,
        ninthNumber: ninthNumber,
        bottom: bottom,
        top: top,
        sign1: sign1,
        sign2: sign2
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
        <span>{`$y(x) = ${equation.firstNumber} x^{2} ${equation.sign1} ${equation.secondNumber} x ${equation.sign2} ${equation.thirdNumber}\\; \\; | \\; \\; y(${equation.fourthNumber}), \\; y(${equation.fifthNumber})$`}</span>
        <br/>
        </span>

      ),

      answer: (
        <span>
        <span>{`$y(${equation.fourthNumber}) = ${equation.sixthNumber}$`}</span>
        <br/>
        <span>{`$y(${equation.fifthNumber}) = ${equation.seventhNumber}$`}</span>
        <br/>
        <span>{`$Domain = (-\\infty, \\infty)$`}</span>
        <br/>
        <span>{`$Range = (${equation.bottom}, ${equation.top})$`}</span>
        <br/>
        </span>
      )
    };
  }

  function helpText() {
    return `
      $$ y(x) = 3x^{2} - 4x - 2 \\hspace{2mm} | \\hspace{2mm} y(-5), \\hspace{1mm} y(1) $$    
      \
      $$ y(-5) = 3 \\cdot (-5)^{2} - 4 \\cdot (-5) - 2 $$
      \
      $$ y(-5) = 3 \\cdot 25 + 20 - 2 $$
      \
      $$ y(-5) = 75 + 18 $$
      \
      $$ y(-5) = 93 $$
      \
      $$ y(1) = 3 \\cdot (1)^{2} - 4 \\cdot (1) - 2 $$
      \
      $$ y(1) = 3 \\cdot 1 - 4 - 2 $$
      \
      $$ y(1) = 3 - 6 $$
      \
      $$ y(1) = - 3 $$
      \
      $$ Domain = (-\\infty, \\infty) $$
      \
      This Domain is true for all Polynomials, including Quadratics.
      \
      All Polynomials and therefore all Quadratics go all the way to the left and all the way to the right.
      \
      $$ Range = (-\\infty, \\hspace{1mm} y(m)) \\hspace{2mm} or \\hspace{2mm} (y(m), \\hspace{1mm} \\infty) $$
      \
      $$ y(x) = ax^{2} + bx + c $$
      \
      $$ y(x) = 3x^{2} - 4x - 2 $$
      \
      $$ a = 3, \\hspace{1mm} b = -4, \\hspace{1mm} c = -2 $$
      \
      $$ m = \\frac{-b}{2a} = \\frac{--4}{2 \\cdot 3} = \\frac{4}{6} = \\frac{2}{3} $$
      \
      $$ y \\left( \\frac{2}{3} \\right) = 3 \\cdot \\left( \\frac{2}{3} \\right)^{2} - 4 \\cdot \\left( \\frac{2}{3} \\right) - 2 $$
      \
      $$ y \\left( \\frac{2}{3} \\right) = 3 \\cdot \\frac{4}{9} - \\frac{8}{3} - 2 $$
      \
      $$ y \\left( \\frac{2}{3} \\right) =  \\frac{12}{9} - \\frac{8}{3} - \\frac{6}{3} $$
      \
      $$ y \\left( \\frac{2}{3} \\right) =  \\frac{4}{3} - \\frac{8}{3} - \\frac{6}{3} $$
      \
      $$ y \\left( \\frac{2}{3} \\right) =  \\frac{4 - 8 - 6}{3} $$
      \
      $$ y \\left( \\frac{2}{3} \\right) =  \\frac{-10}{3} $$
      \
      $$ a = 3 > 0 \\rightarrow  (y(m), \\infty) $$
      \
      $$ Range = \\left( \\frac{-10}{3}, \\infty \\right) $$
      \
      $$ If \\hspace{2mm} a < 0 , \\hspace{1mm} then \\hspace{2mm} Range = (-\\infty, \\hspace{1mm} y(m)) $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Polynomial Quadratic Functions"}
        instructions={"Plug in the 2 values. Compute Domain and Range."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quadratic Functions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}