import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function AdvancedPlay() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];

    const polynomial = "6x^7 - 4x^3 + 8 - x^5 + 0.4x - 2.1 + x - 6x^6 + 3x";

// Split the polynomial into individual terms
const terms = polynomial.split(" + ").join(" +").split(" - ").join(" -").split(" ");

// Initialize an object to store the sum of coefficients for each exponent
const termMap = {};

// Iterate through the terms
for (let term of terms) {
  if (term.includes("x^")) {
    // Extract the coefficient and exponent
    const [coeffStr, exponentStr] = term.split("x^");
    let coefficient = parseFloat(coeffStr);
    const exponent = parseInt(exponentStr);

    // If coefficient is NaN, set it to 1 or -1 based on the sign
    if (isNaN(coefficient)) {
      coefficient = term.startsWith("-") ? -1 : 1;
    }

    // Add the coefficient to the corresponding exponent in the termMap
    if (termMap[exponent] !== undefined) {
      termMap[exponent] += coefficient;
    } else {
      termMap[exponent] = coefficient;
    }
  } else if (term.includes("x")) {
    // Terms with x but no exponent (x^1)
    let coefficient = parseFloat(term);

    // If coefficient is NaN, set it to 1 or -1 based on the sign
    if (isNaN(coefficient)) {
      coefficient = term.startsWith("-") ? -1 : 1;
    }

    // Add the coefficient to exponent 1 in the termMap
    if (termMap[1] !== undefined) {
      termMap[1] += coefficient;
    } else {
      termMap[1] = coefficient;
    }
  } else {
    // Constant term (no x)
    const coefficient = parseFloat(term);

    // Add the coefficient to exponent 0 in the termMap
    if (termMap[0] !== undefined) {
      termMap[0] += coefficient;
    } else {
      termMap[0] = coefficient;
    }
  }
}

// Ensure every exponent from 0 to 7 is represented in the termMap
for (let i = 0; i <= 7; i++) {
  if (termMap[i] === undefined) {
    termMap[i] = 0;
  }
}

// Convert termMap to the concatenated vector format
const concatenated = Object.entries(termMap).map(([exponent, coefficient]) => [coefficient, parseInt(exponent)]);

console.log("Concatenated Vector:", concatenated);
    
    let result1 = 3;
    let result2 = 4;

    /*
    generatedProblem.push({
      key: "1",
      firstNumber: firstNumber,
      result: `$ - ${polynomial} $`,
      format: <span>{`$ ${coefficient} $`}</span>
    });

    generatedProblem.push({
        key: "2",
        firstNumber: firstNumber,
        result: `$ - ${polynomial} $`,
        format: <span>{`$ ${exponent} $`}</span>
      });
    */

    generatedProblem.push({
        key: "3",
        firstNumber: firstNumber,
        result: `$ - ${polynomial} $`,
        format: <span>{`$ ${concatenated} $`}</span>
      });
  
    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) $$
        \
        $$ \\text{Put your finger on the left side of the graph.} $$
        \
        $$ \\text{Approach} \\; a \\; \\text{as you move your finger to the right.} $$
        \
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x) $$
        \
        $$ \\text{Put your finger on the right side of the graph.} $$
        \
        $$ \\text{Approach} \\; a \\; \\text{as you move your finger to the left.}  $$
        \
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) $$
        \
        $$ \\text{If} \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) = \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x) = c, \\text{then} \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) = c. $$
        \
        $$ \\text{If} \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) \\neq \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x), \\text{then} \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) = \\text{Does Not Exist}. $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Calculus Limits"}
        instructions={"Compute the limits."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={1}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Limits Help", helpTextFunc: helpText}}
      />
    </div>
  );
}