import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrixMultiplication3x3() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eighthNumber = nums[Math.floor(Math.random()*nums.length)];

      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }

      let result1a = firstNumber*fifthNumber + secondNumber*seventhNumber;
      let result2a = firstNumber*sixthNumber + secondNumber*eighthNumber;
      let result3a = thirdNumber*fifthNumber + fourthNumber*seventhNumber;
      let result4a = thirdNumber*sixthNumber + fourthNumber*eighthNumber;
      

      generatedProblem.push({
        key: `${step}-mm2`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eighthNumber: eighthNumber,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }
  
  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {
          
          `$\\begin{pmatrix}
          ${equation.firstNumber} & ${equation.secondNumber}\\\\
          ${equation.thirdNumber} & ${equation.fourthNumber}
          \\end{pmatrix} 
          
        \\cdot
          
          \\begin{pmatrix}
          ${equation.fifthNumber} & ${equation.sixthNumber} \\\\
          ${equation.seventhNumber} & ${equation.eighthNumber}
          \\end{pmatrix}$`
          
          }

        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 

        <span>
        <span>
         {`$\\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a}\\\\
          ${equation.result3a} & ${equation.result4a}
          \\end{pmatrix}$`}
        </span>
        </span>
    };
  }

  function helpText() {
    return `
        $$ 
        \\left( \\begin{matrix}
            & Top & \\\\
            & Bottom & \\\\
            \\end{matrix} \\right) 
        
        \\cdot 

        \\left( \\begin{matrix}
             & \\\\
            Left & Right \\\\
             &  \\\\
            \\end{matrix} \\right) $$    

        \

        $$ = \\left( \\begin{matrix}
            Top \\cdot Left & Top \\cdot Right \\\\
            Bottom \\cdot Left & Bottom \\cdot Right \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            TL & TR \\\\
            BL & BR \\\\
            \\end{matrix} \\right) $$

        \

        $$ \\left( \\begin{matrix}
            \\bf{2} & \\bf{-3} \\\\
            5 & 7 \\\\
            \\end{matrix} \\right) 

        \\cdot

        \\left( \\begin{matrix}
             \\bf{-4} & 1 \\\\
             \\bf{0} & -6 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            \\bf{(2 \\cdot -4) + (-3 \\cdot 0)} & TR\\\\
            BL & BR \\\\
            \\end{matrix} \\right)  $$
        
        \

        $$ = \\left( \\begin{matrix}
            \\bf{(-8) + (0)} & TR \\\\
            BL & BR \\\\
            \\end{matrix} \\right)  $$

        \

        $$ = \\left( \\begin{matrix}
            \\bf{-8} & TR \\\\
            BL & BR \\\\
            \\end{matrix} \\right) $$

        \
        \
        \
        \

        $$ \\left( \\begin{matrix}
            \\bf{2} & \\bf{-3} \\\\
            5 & 7 \\\\
            \\end{matrix} \\right) 

        \\cdot

        \\left( \\begin{matrix}
             -4 & \\bf{1} \\\\
             0 & \\bf{-6} \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & \\bf{(2 \\cdot 1) + (-3 \\cdot -6)} \\\\
            BL & BR \\\\
            \\end{matrix} \\right)  $$
        
        \

        $$ = \\left( \\begin{matrix}
            -8 & \\bf{(2) + (18)} \\\\
            BL & BR \\\\
            \\end{matrix} \\right)  $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & \\bf{2 + 18} \\\\
            BL & BR \\\\
            \\end{matrix} \\right) $$
        
        \

        $$ = \\left( \\begin{matrix}
            -8 & \\bf{20} \\\\
            BL & BR \\\\
            \\end{matrix} \\right) $$

        \
        \
        \
        \

        $$ \\left( \\begin{matrix}
            2 & -3 \\\\
            \\bf{5} & \\bf{7} \\\\
            \\end{matrix} \\right) 

        \\cdot

        \\left( \\begin{matrix}
            \\bf{-4} & 1 \\\\
            \\bf{0} & -6 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            \\bf{(5 \\cdot -4) + (7 \\cdot 0)} & BR \\\\
            \\end{matrix} \\right)  $$
        
        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            \\bf{(-20) + (0)} & BR \\\\
            \\end{matrix} \\right)  $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            \\bf{-20} & BR \\\\
            \\end{matrix} \\right) $$

        \
        \
        \
        \

        $$ \\left( \\begin{matrix}
            2 & -3 \\\\
            \\bf{5} & \\bf{7} \\\\
            \\end{matrix} \\right) 

        \\cdot

        \\left( \\begin{matrix}
            -4 & \\bf{1} \\\\
            0 & \\bf{-6} \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            -20 & \\bf{(5 \\cdot 1) + (7 \\cdot -6)} \\\\
            \\end{matrix} \\right)  $$
        
        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            -20 & \\bf{(5) + (-42)} \\\\
            \\end{matrix} \\right)  $$

        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            -20 & \\bf{5 - 42} \\\\
            \\end{matrix} \\right) $$
        
        \

        $$ = \\left( \\begin{matrix}
            -8 & 20 \\\\
            -20 & \\bf{-37} \\\\
            \\end{matrix} \\right) $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Matrix Multiplication 3x3 "}
        instructions={"Multiply the 2 Matricies."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Matrix Multiplication 2x2 Help", helpTextFunc: helpText}}
      />
    </div>
  );
}