import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function CalculusDefiniteIntegral() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums1 = [2, 3, 4, 5, 6, 7];
    const nums2 = [2, 3, 4];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = randomNumFromSet(nums1);
      let secondNumber = randomNumFromSet(nums2) + firstNumber;
      let thirdNumber = randomNumFromSet(nums1);
      let fourthNumber = randomNumFromSet(nums1);
      let fifthNumber = randomNumFromSet(nums1);
      let sixthNumber = randomNumFromSet(nums1);
      let result1 = thirdNumber/(fourthNumber+1);
      let result5 = result1*Math.pow(secondNumber,fourthNumber+1) - (fifthNumber/2)*Math.pow(secondNumber,2) + sixthNumber*secondNumber;
      let result6 = result1*Math.pow(firstNumber,fourthNumber+1) - (fifthNumber/2)*Math.pow(firstNumber,2) + sixthNumber*firstNumber;
      let result7 = result5 - result6;

      generatedProblem.push({
        key: `${step}-cdi`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        result7: Math.round(result7 * 100) / 100,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ \\int_{${equation.firstNumber}}^{${equation.secondNumber}} ${equation.thirdNumber} x^{${equation.fourthNumber}} - ${equation.fifthNumber}x + ${equation.sixthNumber} \\text{ } dx $`,
      answer: `$ ${equation.result7} $`
    }
  }

  function helpText() {
    return `
        $$ \\int_{4}^{6} 5 x^{2} - 3x + 7 \\; dx $$
        \
        $$ \\int_{4}^{6} 5 x^{2} - 3x^{1} + 7x^{0} \\; dx $$
        \
        $$ = \\frac{5 (x^{2 + 1})}{2 + 1} - \\frac{3 (x^{1 + 1})}{1 + 1} + \\frac{7 (x^{0 + 1})}{0 + 1} \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\frac{5 x^{3}}{3} - \\frac{3x^{2}}{2} + 7x \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\frac{5}{3} \\cdot x^{3} - \\frac{3}{2} \\cdot x^{2} + 7 \\cdot x \\hspace{3mm} \\bigg| _{4} ^{6} $$
        \
        $$ = \\left( \\frac{5}{3} (6)^{3} - \\frac{3}{2} (6)^{2} + 7(6) \\right) - \\left( \\frac{5}{3} (4)^{3} - \\frac{3}{2} (4)^{2} + 7(4) \\right) $$
        \
        $$ = \\left( \\frac{5}{3} \\cdot 216 - \\frac{3}{2} \\cdot 36 + 42 \\right) - \\left( \\frac{5}{3} \\cdot 64 - \\frac{3}{2} \\cdot 16 + 28 \\right) $$
        \
        $$ = \\left( \\frac{1080}{3} - \\frac{108}{2} +  42 \\right) - \\left( \\frac{320}{3} - \\frac{48}{2} + 28 \\right) $$
        \
        $$ = \\left( \\frac{2160}{6} - \\frac{324}{6} +  \\frac{252}{6} \\right) - \\left( \\frac{640}{6} - \\frac{144}{6} + \\frac{168}{6} \\right) $$
        \
        $$ = \\left( \\frac{2160 - 324 + 252}{6} \\right) - \\left( \\frac{640 - 144 + 168}{6} \\right) $$
        \
        $$ = \\left( \\frac{2088}{6} \\right) - \\left( \\frac{664}{6} \\right) $$
        \
        $$ = \\frac{2088 - 664}{6} $$
        \
        $$ = \\frac{1424}{6} $$
        \
        $$ = 237.33 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Calculus Definite Integral"}
        instructions={"Compute the Integral."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Definite Integral Help", helpTextFunc: helpText}}
      />
    </div>
  );
}