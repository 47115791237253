import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticFactorialA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].sort( () => .5 - Math.random() );
    const generatedProblem = [];

    for (let step = 0; step < nums.length; step++) {
      let firstNumber = nums[step];
      let result = factorialize(firstNumber);   

      generatedProblem.push({
        key: `${step}-afa`,
        firstNumber: firstNumber,
        result: result
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function factorialize(num) {
    if (num === 0 || num === 1) {
      return 1;
    }

    let result = num;
    while (num > 1) {
      num--;
      result *= num;
    }

    return result
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ ${equation.firstNumber} ! $`,
      answer: `$ ${equation.result} $`,
    }
  }

  function helpText() {
    return `
      $$ 4!  $$    
      \
      $$ = 4 \\cdot 3 \\cdot 2 \\cdot 1 $$
      \
      $$ = 24 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Arithmetic Factorial A"}
        instructions={"Compute the following."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Factorial A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}