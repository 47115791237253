import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearFourStepLikeTermsB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * ((secondNumber * thirdNumber) + (fourthNumber * thirdNumber)) + fifthNumber * thirdNumber;
      if(result === 0) {
        step--;
        continue;
      }
      let sign = "+";
      let secondSign = "-";

      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign = "-";
      }

      if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        secondSign = "+";
      }

      generatedProblem.push({
        key: `${step}-lfsltb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        result: result,
        sign: sign,
        secondSign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(${equation.secondNumber}x ${equation.sign} ${equation.fourthNumber}x) = ${equation.result} ${equation.secondSign} ${equation.fifthNumber}x$`,
      answer: `$x = ${equation.thirdNumber}$`
    };
  }

  function helpText() {
    return `

    $$ 3(7x + 5x) - 150 = 6x $$       
    \
    $$ 3((7 + 5) \\cdot x) - 150 = 6x $$
    \
    $$ 3(12x) - 150 = 6x $$
    \
    $$ 3 \\cdot 12 \\cdot x - 150 = 6x $$
    \
    $$ 36 \\cdot x - 150 = 6x $$
    \
    $$ 36x - 150 = 6x $$
    \
    $$ - 36x \\hspace {7mm} - 36x $$
    \
    $$ 0 - 150 = 6x - 36x $$
    \
    $$ -150 = (6 -36) \\cdot x $$
    \
    $$ -150 = -30x $$
    \
    $$ \\dfrac{-150 = -30x}{-30}$$
    \
    $$ \\dfrac{-150}{-30} = \\dfrac{-30x}{-30} $$
    \
    $$ \\dfrac{-30 \\cdot 5}{-30 \\cdot 1} = \\dfrac{-30 \\cdot x}{-30 \\cdot 1} $$
    \
    $$ \\dfrac{5}{1} = \\dfrac{x}{1} $$
    \
    $$ 5 = x $$
    
    `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Linear Four Step Like Terms B"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Four Step Like Terms B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}