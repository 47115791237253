import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function CalculusChainRule() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1 = firstNumber - 1;
    let result2 = secondNumber - 1;
    let result3 = firstNumber*secondNumber;

    generatedProblem.push({
      key: "1-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ ${result3} x^{${result2}} \\cdot cos(${firstNumber}x^{${secondNumber}})$`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( sin(${firstNumber}x^{${secondNumber}}) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "2-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ - ${result3} x^{${result1}} \\cdot sin(${secondNumber}x^{${firstNumber}})$`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( cos(${secondNumber}x^{${firstNumber}}) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "3-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ \\frac{${secondNumber}}{x} $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( Ln(${firstNumber}x^{${secondNumber}}) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "4-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ \\frac{cos(Ln(x))}{x} $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( sin(Ln(x)) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "5-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ - tan(x) $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( Ln(cos(x)) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "6-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ ${result3} sin^{${result1}}(x) \\cdot cos(x) $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( ${secondNumber} sin^{${firstNumber}}(x) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "7-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ - ${result3} cos^{${result2}}(x) \\cdot sin(x) $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( ${firstNumber} cos^{${secondNumber}}(x) \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "8-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ \\frac{${result3} (Ln(x))^{${result1}}}{x} $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( ${secondNumber} (Ln(x))^{${firstNumber}} \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "9-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ ${result3} x^{${result1}} \\cdot e^{${secondNumber}x^{${firstNumber}}} $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( e^{${secondNumber}x^{${firstNumber}}} \\Bigr) $`}</span>
    });

    generatedProblem.push({
      key: "10-ccr",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      result1: result1,
      result2: result2,
      result3: result3,
      result: `$ 1 $`,
      format: <span>{`$ \\dfrac{d}{dx} \\Bigl( Log_{${firstNumber}} (${firstNumber}^{x}) \\Bigr) $`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ \\frac{d}{dx} \\Bigl( \\text{Outside} (\\textbf{Inside(x)}) \\Bigr) = \\frac{d}{dx} \\Bigl( \\text{Out} (\\textbf{In(x)}) \\Bigr) $$
        \
        $$ = \\frac{d}{dx} \\Bigl( f(\\textbf{g(x)}) \\Bigr) = \\frac{d}{dx} \\Bigl( f(\\textbf{g}) \\Bigr) $$
        \
        $$ = (f(\\textbf{g}))' = f'(\\textbf{g}) \\cdot \\textbf{g}' $$
        \
        $$ \\frac{d}{dx} \\Bigl(sin(Log_{5}(x)) \\Bigr) $$
        \
        $$ Log_{5}(x) = \\textbf{g} $$
        \
        $$ = \\frac{d}{dx} \\Bigl( sin(\\textbf{g}) \\Bigr) $$
        \
        $$ = (sin(\\textbf{g}))' $$
        \
        $$ = sin'(\\textbf{g}) \\cdot (\\textbf{g})' $$
        \
        $$ = cos(\\textbf{g}) \\cdot (\\textbf{g})' $$
        \
        $$ = cos(Log_{5}(x)) \\cdot (Log_{5}(x))' $$
        \
        $$ = cos({Log_{5}(x)}) \\cdot \\biggl( \\frac{1}{x Ln(5)} \\biggr) $$
        \
        $$ =  \\frac{cos({Log_{5}(x)})}{x Ln(5)} $$
      `
  }

  return (
    <div>
      

      <EquationLayout
        title={"Calculus Chain Rule"}
        instructions={"Compute the derivative."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Chain Rule Help", helpTextFunc: helpText}}
      />
    </div>
  );
}