import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogLogAddition() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,secondNumber);
      let result2 = result1/thirdNumber;

      generatedProblem.push({
        key: `${step}-la`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$Log_{${equation.firstNumber}} (${equation.thirdNumber}) + Log_{${equation.firstNumber}} (x) = ${equation.secondNumber}$`,
      answer: `$x = ${roundNumber(equation.result2)}$`
    }
  }

  function helpText() {
    return `
    $$ Log_{2}(3) + Log_{2}(x) = 4 $$
    \
      $$ Log_{2}(3 \\cdot x) = 4 $$    
      \
      $$ Log_{2}(3x) = 4 $$
      \
      $$ 2^{4} = 3x $$
      \
      $$ 16 = 3x $$
      \
      $$ \\frac{16 = 3x}{3} $$
      \
      $$ \\frac{16}{3} = \\frac{3x}{3} $$
      \
      $$ \\frac{16}{3} = x $$
      \
      $$ x = \\frac{16}{3} = 5.33 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Logarithm Addition"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Logarithmic Addition Help", helpTextFunc: helpText}}
      />
    </div>
  );
}