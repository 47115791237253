import React, { useState } from 'react';

import Vector_MatrixVectorLinearCombinations from './Vector_MatrixVectorLinearCombinations';
import Vector_MatrixDotProduct from './Vector_MatrixDotProduct';
import Vector_MatrixCrossProduct from './Vector_MatrixCrossProduct';
import Vector_MatrixMagnitude from './Vector_MatrixMagnitude';
import Vector_MatrixUnitVector from './Vector_MatrixUnitVector';
import Vector_MatrixMatrix3x3 from './Vector_MatrixMatrix3x3';
import Vector_MatrixMatrix2x2 from './Vector_MatrixMatrix2x2';
import Vector_MatrixMatrix3x3LinearCombinations from './Vector_MatrixMatrix3x3LinearCombinations';
import Vector_MatrixMatrix2x2LinearCombinations from './Vector_MatrixMatrix2x2LinearCombinations';
import Vector_MatrixMatrixMultiplication3x3 from './Vector_MatrixMatrixMultiplication3x3';
import Vector_MatrixMatrixMultiplication2x2 from './Vector_MatrixMatrixMultiplication2x2';

import Button from 'react-bootstrap/Button';

const VECTOR_MATRIX_VECTOR_LINEAR_COMBINATIONS = "vector_matrix_vector_linear_combinations";
const VECTOR_MATRIX_DOT_PRODUCT = "vector_matrix_dot_product";
const VECTOR_MATRIX_CROSS_PRODUCT = "vector_matrix_cross_product";
const VECTOR_MATRIX_MAGNITUDE = "vector_matrix_magnitude";
const VECTOR_MATRIX_UNIT_VECTOR = "vector_matrix_unit_vector";
const VECTOR_MATRIX_MATRIX_3X3 = "vector_matrix_matrix_3x3";
const VECTOR_MATRIX_MATRIX_2X2 = "vector_matrix_matrix_2x2";
const VECTOR_MATRIX_MATRIX_3X3_LINEAR_COMBINATIONS = "vector_matrix_matrix_3x3_linear_combinations";
const VECTOR_MATRIX_MATRIX_2X2_LINEAR_COMBINATIONS = "vector_matrix_matrix_2x2_linear_combinations";
const VECTOR_MATRIX_MATRIX_MULTIPLICATION_3X3 = "vector_matrix_matrix_multiplication_3x3";
const VECTOR_MATRIX_MATRIX_MULTIPLICATION_2X2 = "vector_matrix_matrix_multiplication_2x2";
const FUNCTION_TYPES = [
  VECTOR_MATRIX_VECTOR_LINEAR_COMBINATIONS, VECTOR_MATRIX_DOT_PRODUCT, VECTOR_MATRIX_CROSS_PRODUCT,
  VECTOR_MATRIX_MAGNITUDE, VECTOR_MATRIX_UNIT_VECTOR, VECTOR_MATRIX_MATRIX_3X3, VECTOR_MATRIX_MATRIX_2X2, 
  VECTOR_MATRIX_MATRIX_3X3_LINEAR_COMBINATIONS, VECTOR_MATRIX_MATRIX_2X2_LINEAR_COMBINATIONS, 
  VECTOR_MATRIX_MATRIX_MULTIPLICATION_3X3, VECTOR_MATRIX_MATRIX_MULTIPLICATION_2X2
];

const functionTypeDisplayNames = {
  "vector_matrix_vector_linear_combinations": "Vector Linear Combinations",
  "vector_matrix_dot_product": "Dot Product",
  "vector_matrix_cross_product": "Cross Product",
  "vector_matrix_magnitude": "Magnitude",
  "vector_matrix_unit_vector": "Unit Vector",
  "vector_matrix_matrix_3x3": "Matrix 3x3",
  "vector_matrix_matrix_2x2": "Matrix 2x2",
  "vector_matrix_matrix_3x3_linear_combinations": "Matrix 3x3 Linear Combinations",
  "vector_matrix_matrix_2x2_linear_combinations": "Matrix 2x2 Linear Combinations",
  "vector_matrix_matrix_multiplication_3x3": "Matrix Multiplication 3x3",
  "vector_matrix_matrix_multiplication_2x2": "Matrix Multiplication 2x2"
}

export default function Vector_Matrix() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(VECTOR_MATRIX_VECTOR_LINEAR_COMBINATIONS);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case VECTOR_MATRIX_VECTOR_LINEAR_COMBINATIONS: {
        return <Vector_MatrixVectorLinearCombinations />;
      }
      case VECTOR_MATRIX_DOT_PRODUCT: {
        return <Vector_MatrixDotProduct />;
      }
      case VECTOR_MATRIX_CROSS_PRODUCT: {
        return <Vector_MatrixCrossProduct />;
      }
      case VECTOR_MATRIX_MAGNITUDE: {
        return <Vector_MatrixMagnitude />;
      }
      case VECTOR_MATRIX_UNIT_VECTOR: {
        return <Vector_MatrixUnitVector />;
      }
      case VECTOR_MATRIX_MATRIX_3X3: {
        return <Vector_MatrixMatrix3x3 />;
      }
      case VECTOR_MATRIX_MATRIX_2X2: {
        return <Vector_MatrixMatrix2x2 />;
      }
      case VECTOR_MATRIX_MATRIX_3X3_LINEAR_COMBINATIONS: {
        return <Vector_MatrixMatrix3x3LinearCombinations />;
      }
      case VECTOR_MATRIX_MATRIX_2X2_LINEAR_COMBINATIONS: {
        return <Vector_MatrixMatrix2x2LinearCombinations />;
      }
      case VECTOR_MATRIX_MATRIX_MULTIPLICATION_3X3: {
        return <Vector_MatrixMatrixMultiplication3x3 />;
      }
      case VECTOR_MATRIX_MATRIX_MULTIPLICATION_2X2: {
        return <Vector_MatrixMatrixMultiplication2x2 />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}