import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticCancel3() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [1, 2, 3, 4, 5];
    const nums2 = [1, 2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let result1 = Math.pow(3,firstNumber);
      let result2 = Math.pow(3,secondNumber);

      generatedProblem.push({
        key: `${step}-ac3`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2:result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.result1}}{${equation.result2}}$`,
      answer: `$${displayFraction(equation.result1, equation.result2)}$`
    }
  }

  function helpText() {
    return `$$
    
      { \\dfrac{27}{9}} $$
            
      \
    
      $$ {= \\dfrac{9 \\cdot 3}{3 \\cdot 3}} $$

      \

      $$ {= \\dfrac{9}{3}} $$

      \

      $$ {= \\dfrac{3 \\cdot 3}{1 \\cdot 3}} $$

      \

      $$ {= \\dfrac{3}{1}} $$

      \

      $$ {= 3} 

     $$`
  }

  return (
    <div>
      

      <EquationLayout
      title={"Arithmetic Cancel 3"}
      instructions={"Reduce the following by factoring and canceling out 3's."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Cancel 3 Help", helpTextFunc: helpText}}
      />
    </div>
  );
}