import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function VectorsUnitVector() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [-8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.sqrt((Math.pow(firstNumber,2)) + (Math.pow(secondNumber,2)) + (Math.pow(thirdNumber,2)));
      let result2 = firstNumber/result1;
      let result3 = secondNumber/result1;
      let result4 = thirdNumber/result1;

      generatedProblem.push({
        key: `${step}-lvm`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result1,
        result2: result2,
        result3: result3,
        result4: result4
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$<${equation.firstNumber}, ${equation.secondNumber}, ${equation.thirdNumber}>$`,
      answer: `$\\vec{u} = <${Math.round(equation.result2*100)/100},${Math.round(equation.result3*100)/100},${Math.round(equation.result4*100)/100}>$`
    }
  }

  function helpText() {
    return `
    $$ \\text{v} = \\hspace{1mm} < 3, \\hspace{1mm} -6, \\hspace{1mm} 5 > $$
    \
    $$ u_{\\text{v}} = \\frac{\\text{v}}{||\\text{v}||} $$
    \
    $$ ||\\text{v}|| = \\sqrt{(3)^{2} + (-6)^{2} + (5)^{2}} $$
    \
    $$ ||\\text{v}|| = \\sqrt{(9) + (36) + (25)} $$
    \
    $$ ||\\text{v}|| = \\sqrt{70} = 8.37 $$
    \
    $$ u_{\\text{v}} = \\frac{< 3, \\hspace{1mm} -6, \\hspace{1mm} 5>}{\\sqrt{70}} $$
    \
    $$ u_{\\text{v}} = < \\frac{3}{\\sqrt{70}}, \\hspace{1mm} \\frac{-6}{\\sqrt{70}}, \\hspace{1mm} \\frac{5}{\\sqrt{70}} > $$
    \
    $$ u_{\\text{v}} = < \\frac{3}{8.37}, \\hspace{1mm} \\frac{-6}{8.37}, \\hspace{1mm} \\frac{5}{8.37} > $$
    \
    $$ u_{\\text{v}} = < 0.36, \\hspace{1mm} -0.71, \\hspace{1mm} 0.60 > $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Vectors Unit Vector"}
        instructions={"Compute the unit vector."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Unit Vector Help", helpTextFunc: helpText}}
      />
    </div>
  );
}