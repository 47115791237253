import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = (firstNumber * secondNumber) + thirdNumber;
      let sign = "+"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }       

      generatedProblem.push({
        key: `${step}-ltsa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}x ${equation.sign} ${equation.thirdNumber} = ${equation.result}$`,
      answer: `$x = ${equation.secondNumber}$`
    }
  }

  function helpText() {
    return `

    $$ 2x + 4 = -10 $$       
    \
    $$ -4 \\hspace{11mm} -4 $$
    \
    $$ 2x + 0 = -10 - 4 $$
    \
    $$ 2x = -14 $$
    \
    $$ \\dfrac{2x}{2} = \\dfrac{-14}{2} $$
    \
    $$ \\dfrac{2 \\cdot x}{2 \\cdot 1} = \\dfrac{-7 \\cdot 2}{1 \\cdot 2} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{-7}{1} $$
    \
    $$ x = -7 $$

    `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear Two Step A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}