import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomIntFromInterval } from '../../../../utils/mathHelpers';


export default function ArithmeticModular() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums1 = [30, 40, 50, 60, 70, 80, 90];
    const nums2 = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12 ,13 ,14 ,15, 16, 17, 18, 19, 20, 21, 22];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = randomIntFromInterval(30, 99);
      let secondNumber = randomIntFromInterval(2, 29);
      let result = firstNumber % secondNumber;

      generatedProblem.push({
        key: `${step}-aa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(mod \\; ${equation.secondNumber})$`,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `
      $$ 59(mod \\; 18)$$
      \  
      $$ \\dfrac{59}{18} $$
      \
      $$ \\dfrac{54 + 5}{18} $$
      \
      $$ \\dfrac{54}{18} + \\dfrac{5}{18} $$
      \
      $$ \\dfrac{3 \\cdot 18}{1 \\cdot 18} + \\dfrac{5}{18} $$
      \
      $$ \\dfrac{3}{1} + \\dfrac{5}{18} $$
      \
      $$ 3 + \\dfrac{5}{18} $$
      \
      $$ 3 \\dfrac{5}{18} $$
      \
      $$ 3 r 5$$
      \
      $$ Remainder = 5$$
      \
      $$ 59(mod \\; 18) \\equiv 5 $$
    `
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Modular"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Modular Help", helpTextFunc: helpText}}
      />
    </div>
  );
}