import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function LinearTwoPoints() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      while(thirdNumber === firstNumber) {
        thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      }
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = fourthNumber - secondNumber;
      let result2 = thirdNumber - firstNumber;
      let result3 = secondNumber*result2 - firstNumber*result1;
      let result4 = Math.pow(result1,2) + Math.pow(result2,2);
      let result5 = Math.pow(result4,0.5);
      let result6 = (firstNumber + thirdNumber)/2;
      let result7 = (secondNumber + fourthNumber)/2;
      let result8 = result1*result2;
      let result9 = result2*result3;
      let sign1 = " ";
      let sign2 = "+";

      result1 = Math.abs(result1);
      result2 = Math.abs(result2);
      result3 = Math.abs(result3);

      if(result8 < 0) {
        sign1 = "-";
      }

      if(result9 < 0) {
        sign2 = "-";
      }

      generatedProblem.push({
        key: `${step}-l2p`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6,
        result7: result7,
        sign1: sign1,
        sign2: sign2
      });
    }

      

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let firstFraction = displayFraction(equation.result1,equation.result2)
    let sign2 = equation.sign2
    if(firstFraction === 1){
      firstFraction = "x"
    }
    else if (firstFraction === 0) {
      if(sign2 === "+") {
        sign2 = ""
      }
      firstFraction = ""
    }
    else {
      firstFraction = `${firstFraction} x`
    }

    let secondFraction = displayFraction(equation.result3,equation.result2)
    if(secondFraction === 0) {
      sign2 = ""
      secondFraction = ""
    }
    return {
      key: equation.key,
      equation: `$(${equation.firstNumber},${equation.secondNumber}) (${equation.thirdNumber},${equation.fourthNumber})$`,
      answer: (
        <span>
        <span>{`$y = ${equation.sign1} ${firstFraction} ${sign2} ${secondFraction}$`}</span>
        <br/>
        <span>{`$distance = ${roundNumber(equation.result5)}$`}</span>
        <br/>
        <span>{`$midpoint = (${equation.result6},${equation.result7})$`}</span>
        <br/>
        </span>
      )
    };
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function helpText() {
    return `
      $$ (-3, 2) \\hspace{2mm} (4, 7) $$    
      \
      $$ Line: \\hspace{2mm} y = mx + b $$
      \
      $$ Slope = m = \\frac{y_{2} - y_{1}}{x_{2} - x_{1}} $$
      \
      $$ m = \\frac{2 - 7}{-3 - 4} $$
      \
      $$ m = \\frac{-5}{-7} $$
      \
      $$ m = \\frac{5}{7} $$
      \
      $$ Y - Intercept = b $$
      \
      $$ y = mx + b $$
      \
      $$ -mx \\hspace{7mm} -mx $$
      \
      $$ y - mx = b $$
      \
      $$ (x, y) = (-3, 2) $$
      \
      $$ 2 - \\frac{5}{7} \\cdot (-3) = b $$
      \
      $$ 2 - \\frac{5}{7} \\cdot \\frac{-3}{1} = b $$
      \
      $$ 2 - \\frac{-15}{7} = b $$
      \
      $$ \\frac{2 \\cdot 7}{7} + \\frac{15}{7} = b $$
      \
      $$ \\frac{14}{7} + \\frac{15}{7} = b $$
      \
      $$ b = \\frac{29}{7} $$
      \
      $$ Line: y = \\frac{5}{7} x + \\frac{29}{7} $$
      \
      $$ Distance = \\sqrt{(x_{1} - x_{2})^{2} + (y_{1} - y_{2})^{2}} $$
      \
      $$ Distance = \\sqrt{(-3 - 4)^{2} + (2 - 7)^{2}} $$
      \
      $$ Distance = \\sqrt{(-7)^{2} + (-5)^{2}} $$
      \
      $$ Distance = \\sqrt{49 + 25} $$
      \
      $$ Distance = \\sqrt{74} $$
      \
      $$ Distance = 8.6 $$
      \
      $$ Midpoint = \\left( \\frac{x_{1} + x_{2}}{2}, \\frac{y_{1} + y_{2}}{2} \\right) $$
      \
      $$ Midpoint = \\left( \\frac{-3 + 4}{2}, \\frac{2 + 7}{2} \\right) $$
      \
      $$ Midpoint = \\left( \\frac{1}{2}, \\frac{9}{2} \\right) $$
      \
      $$ Midpoint = (0.5, 4.5) $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Linear Two Points"}
        instructions={"Compute the equation, distance, and midpoint between the 2 points."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Points Help", helpTextFunc: helpText}}
      />
    </div>
  );
}