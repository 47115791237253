import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomIntFromInterval, randomNumFromSet } from '../../../../utils/mathHelpers';

export default function ArithmeticRounding() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const practice_problems_num = 10;
    const decimalRange = [
      {num: 2, word: "hundred", firstTransform: 1/100, secondTransform: 100},
      {num: 1, word: "ten", firstTransform: 1/10, secondTransform: 10},
      {num: 0, word: "one", firstTransform: 1, secondTransform: 1},
      {num: -1, word: "tenth", firstTransform: 10, secondTransform: 1/10},
      {num: -2, word: "hundredth", firstTransform: 100, secondTransform: 1/100},
      {num: -3, word: "thousandth", firstTransform: 1000, secondTransform: 1/1000}
    ]
    const generatedProblem = [];
    const seenNumbers = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let randomNumber = randomIntFromInterval(10000000, 99999999)
      let randomDecimalRange = randomNumFromSet(decimalRange)

      while(seenNumbers.includes(randomNumber)) {
        randomNumber = randomIntFromInterval(10000000, 99999999);
      }
      seenNumbers.push(randomNumber)
      randomNumber = randomNumber/10000

      generatedProblem.push({
        key: `${step}-asr`,
        randomNumber: randomNumber,
        decimalWord: randomDecimalRange.word,
        answer:  Math.round(randomNumber * randomDecimalRange.firstTransform) * randomDecimalRange.secondTransform
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\text{Round} \\; ${equation.randomNumber} \\; \\text{to the nearest} \\; ${equation.decimalWord}.$`,
      answer: `$${equation.answer}$`
    }
  }

  function helpText() {
    return `
      $$ Round \\hspace{2mm} 8247.3691 \\hspace{2mm} to \\hspace{2mm} the \\hspace{2mm} nearest \\hspace{2mm} hundreth.$$
      \  
      $$1. \\hspace{2mm} Locate \\hspace{2mm} position.$$
      \
      $$ 8247.36 $$
      \
      $$2. \\hspace{2mm} Look \\hspace{2mm} to \\hspace{2mm} the \\hspace{2mm} right.$$
      \
      $$ 8247.369 $$
      \
      $$3. \\hspace{2mm} Add \\hspace{2mm} 1 \\hspace{2mm} to \\hspace{2mm} position \\hspace{2mm} if \\hspace{2mm} digit \\hspace{2mm} to \\hspace{2mm} the \\hspace{2mm} right \\hspace{2mm} \\geq \\hspace{2mm} 5.$$
      \
      $$ 8249.37 $$
      \
      $$4. \\hspace{2mm} Otherwise \\hspace{2mm} keep \\hspace{2mm} position \\hspace{2mm} the \\hspace{2mm} same.$$
      \
      $$5. \\hspace{2mm} To \\hspace{2mm} the \\hspace{2mm} left \\hspace{2mm} of \\hspace{2mm} the \\hspace{2mm} decimal, \\hspace{2mm} keep \\hspace{2mm} trailing \\hspace{2mm} zeros.$$
      \
      $$6. \\hspace{2mm} To \\hspace{2mm} the \\hspace{2mm} right \\hspace{2mm} of \\hspace{2mm} the \\hspace{2mm} decimal, \\hspace{2mm} chop \\hspace{2mm} off \\hspace{2mm} trailing \\hspace{2mm} zeros.$$
      \
      $$ 8247.3700 = 8247.37$$
    `
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Rounding"}
        instructions={"Round the number."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "", helpTextFunc: helpText}}
      />
    </div>
  );
}
