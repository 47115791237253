import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticSquareRoots() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const practice_problems_num = 10;
    const generatedProblem = [];
    const seenNumbers = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];

      if(seenNumbers.includes(firstNumber)) {
        step--;
        continue;
      }

      let result1 = Math.pow(firstNumber,2);
      seenNumbers.push(firstNumber)

      generatedProblem.push({
        key: `${step}-asr`,
        firstNumber: firstNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\sqrt{${equation.result1}}$`,
      answer: `$\\pm ${equation.firstNumber}$`
    }
  }

  function helpText() {
    return `$$
    
      { \\sqrt{49}} $$
            
      \
    
      $$ {= \\sqrt{(\\pm 7)^{2}}} $$

      \

      $$ {= \\pm 7}

     $$`
  }


  return (
    <div>
      
      <EquationLayout
        title={"Arithmetic Square Roots"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Square Roots Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
