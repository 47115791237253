import React from 'react';
import Button from 'react-bootstrap/Button';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import HelpModal from '../../components/HelpModal'

export default function GenerateButtons({ generateProblems, setShowHideAnswers, viewAnswers, helpModalContent = null }) {

  function generatePDF() {
    // Generate PDF for equations
    storePDFData("pdf-equations")
    storePDFData("pdf-equations-with-answers")

    pollPDFData()
  }

  function storePDFData(elementId) {
    html2canvas(document.getElementById(elementId),
      {
        onclone: (clone) => {
          clone.getElementById(elementId).style.display = 'block'
        }
      }
    ).then(canvas => {
      // https://stackoverflow.com/questions/66963487/html2canvas-export-a4-size-regardless-of-window-size
      // https://stackoverflow.com/a/68204483/3519940
      var wid = canvas.width
      var hgt = canvas.height
      var img = canvas.toDataURL("image/png", wid, hgt)
      var hratio = hgt / wid

      localStorage.setItem(
        `${elementId}-data`,
        JSON.stringify(
          {
            img: img,
            hratio: hratio
          }
        )
      )
    });
  }

  function pollPDFData() {
    setTimeout(() => {
      let attemptNumber = Number(localStorage.getItem("pdf-generation-attempt"))
      console.log("attempt")
      console.log(attemptNumber)
      let storedData = fetchStoredPDFData()
      let equationsData = storedData.equationsData
      let equationsWithAnswersData = storedData.equationsWithAnswersData

      if (Object.keys(equationsData).length !== 0 && Object.keys(equationsWithAnswersData).length !== 0) {
        createPDFFromStoredData()
      }
      else if (attemptNumber > 10) {
        cleanUpStoredData()
        alert("Problem downloading PDF, please try again later.")
      }
      else {
        let attempt = attemptNumber === null ? 0 : attemptNumber + 1
        localStorage.setItem("pdf-generation-attempt", attempt.toString())
        pollPDFData()
      }
    }, 500);
  }

  function createPDFFromStoredData() {
    let equationsData = fetchStoredPDFData().equationsData
    let equationsWithAnswersData = fetchStoredPDFData().equationsWithAnswersData

    cleanUpStoredData()

    const pdf = new jsPDF(
      {
        orientation: 'landscape',
        format: 'a4',
        putOnlyUsedFonts: true,
      }
    );

    var width = pdf.internal.pageSize.width

    pdf.addImage(
      equationsData.img,
      'JPEG',
      5,
      5,
      width,
      width*equationsData.hratio
    )
    pdf.addPage('a4', 'landscape')
    pdf.addImage(
      equationsWithAnswersData.img,
      'JPEG',
      5,
      5,
      width,
      width*equationsWithAnswersData.hratio
    )
    pdf.save("equations.pdf")
  }

  function fetchStoredPDFData() {
    let equationsData = localStorage.getItem("pdf-equations-data")
    let equationsWithAnswersData = localStorage.getItem("pdf-equations-with-answers-data")

    return {
      equationsData: equationsData === null ? {} : JSON.parse(equationsData),
      equationsWithAnswersData: equationsWithAnswersData === null ? {} : JSON.parse(equationsWithAnswersData)
    }
  }

  function cleanUpStoredData() {
    localStorage.removeItem("pdf-equations-data")
    localStorage.removeItem("pdf-equations-with-answers-data")
  }

  return (
    <span>
      <Button variant="primary" onClick={() => generateProblems()}>
        Generate
      </Button>
      &nbsp;
      &nbsp;
      <Button variant="primary" onClick={() => setShowHideAnswers(!viewAnswers)}>
        {viewAnswers ? "Hide Answers" : "Show Answers"}
      </Button>
      &nbsp;
      &nbsp;
      {
        helpModalContent !== null &&
          <HelpModal
            title={helpModalContent.title}
            content={helpModalContent.helpTextFunc()}
          />
      }
      <Button variant="primary" onClick={() => generatePDF()}>
        Download
      </Button>
    </span>
  )
}