import React, { useState } from 'react';

import RationalOneStep from './RationalOneStep';
import RationalTwoStep from './RationalTwoStep';
import RationalQuadratic from './RationalQuadratic';
import RationalFeatures from './RationalFeatures';
import Button from 'react-bootstrap/Button';

const ONE_STEP = "one_step"
const TWO_STEP = "two_step"
const QUADRATIC = "quadratic"
const FEATURES = "features"
const FUNCTION_TYPES = [ONE_STEP, TWO_STEP, QUADRATIC, FEATURES];

const functionTypeDisplayNames = {
  "one_step": "One Step",
  "two_step": "Two Step",
  "quadratic": "Quadratic",
  "features": "Features"
}

export default function Rational() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(ONE_STEP);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case ONE_STEP: {
        return <RationalOneStep />;
      }
      case TWO_STEP: {
        return <RationalTwoStep />;
      }
      case QUADRATIC: {
        return <RationalQuadratic />;
      }
      case FEATURES: {
        return <RationalFeatures />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}