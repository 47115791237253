import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrix2x2() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];

      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }


      let resulta = firstNumber*fourthNumber-secondNumber*thirdNumber;
      let result1a = roundNumber((1/resulta)*(fourthNumber));
      let result2a = roundNumber((-1/resulta)*(secondNumber));
      let result3a = roundNumber((-1/resulta)*(thirdNumber));
      let result4a = roundNumber((1/resulta)*(firstNumber));
      let result5 = -(firstNumber+fourthNumber);
      let result6 = Math.pow(result5,2)-4*resulta;
      let result7 = (-result5)/2;
      let result8 = roundNumber(Math.pow(Math.abs(result6),0.5));
      let result9 = result8/2;
      let resultX = roundNumber((-result5 - result8))/2;
      let resultY = roundNumber((-result5 + result8))/2;
      let result10 = -secondNumber;
      let result11 = -thirdNumber;

      if(result6 < 0) {
        resultX = `${result7} - ${result9}i`;
      }

      if(result6 < 0) {
        resultY = `${result7} + ${result9}i`;
      }


      generatedProblem.push({
        key: `${step}-vm2`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        resulta: resulta,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a,
        result5: result5,
        result6: result6,
        result7: result7,
        result8: result8,
        result9: result9,
        result10: result10,
        result11: result11,
        resultX: resultX,
        resultY: resultY
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {
          
          `$M = \\begin{pmatrix}
          ${equation.firstNumber} & ${equation.secondNumber}\\\\
          ${equation.thirdNumber} & ${equation.fourthNumber}
          \\end{pmatrix}$`
          
          }

        </span>
      </span>
    )
    
    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 

        <span>
        <span>
        <br/>
         {`$M^{T} = \\begin{pmatrix}
          ${equation.firstNumber} & ${equation.thirdNumber}\\\\
          ${equation.secondNumber} & ${equation.fourthNumber} 
          \\end{pmatrix}$`}
        </span>
        <br/>
        <br/>
        <span>
        {`$M^{A} = \\begin{pmatrix}
          ${equation.fourthNumber} & ${equation.result10}\\\\
          ${equation.result11} & ${equation.firstNumber} 
          \\end{pmatrix}$`}
        </span>
        <br/>
        <br/>
        <span>
        {`$M^{-1} = \\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a}\\\\
          ${equation.result3a} & ${equation.result4a}
          \\end{pmatrix}$`}
        </span>
        <br/>
        <br/>
        <span>
        {`$Det(M) = ${equation.resulta}$`}
        </span>
        <br/>
        <br/>
        <span>
        {`$\\lambda = ${equation.resultX}, ${equation.resultY}$`}
        </span>
        </span>
    };
  }

  return (
    <div>
     

      <EquationLayout
      title={"Vector/Matrix Matrix 2x2"}
      instructions={"Compute the transpose, adjoint, inverse, determinant, and eigenvalues."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}