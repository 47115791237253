import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialCubic() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [1, 2, 3, 4, 5, 6];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,2);
      let result2 = result1*secondNumber;

      generatedProblem.push({
        key: `${step}-pc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result2: result2,
        result1: result1 == 1 ? "" : result1,
        secondNumber: secondNumber == 1 ? "" : secondNumber,
        unformattedSecondNumber: secondNumber
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$x^{3} - ${equation.secondNumber} x^{2} - ${equation.result1} x + ${equation.result2} = 0$`,
      answer: `$x = \\pm ${equation.firstNumber}, ${equation.unformattedSecondNumber}$`
    }
  }

  function helpText() {
    return `
      $$ x^{3} - 5x^{2} - 4x + 20 = 0  $$    
      \
      $$ (x^{3} - 5x^{2}) + (- 4x + 20) = 0 $$
      \
      $$ (x^{1} \\cdot x^{2} - 5 \\cdot x^{2}) + (- 4x + 20) = 0 $$
      \
      $$ (x^{1} - 5) \\cdot x^{2} + (- 4x + 20) = 0 $$
      \
      $$ (x - 5) \\cdot x^{2} + (- 4x + 20) = 0 $$
      \
      $$ (x - 5) \\cdot x^{2} + (- 4 \\cdot x + (-4) \\cdot (-5)) = 0 $$
      \
      $$ (x - 5) \\cdot x^{2} + (-4) \\cdot (x + (-5)) = 0 $$
      \
      $$ (x - 5) \\cdot x^{2} + (-4) \\cdot (x - 5) = 0 $$
      \
      $$ (x - 5) \\cdot x^{2} - 4 \\cdot (x - 5) = 0 $$
      \
      $$ \\textbf{(x - 5)} \\cdot x^{2} - 4 \\cdot \\textbf{(x - 5)} = 0 $$
      \
      $$ (x^{2} - 4) \\cdot \\textbf{(x - 5)} = 0 $$
      \
      $$ x^{2} - 4 = 0 $$
      \
      $$ +4 \\hspace{7mm} +4 $$
      \
      $$ x^{2} = 4 $$
      \
      $$ x^{2} = (\\pm 2)^{2} $$
      \
      $$ \\sqrt{x^{2}} = \\sqrt{(\\pm 2)^{2}} $$
      \
      $$ x = \\pm 2 $$
      \
      $$ x - 5 = 0 $$
      \
      $$ +5 \\hspace{7mm} +5 $$
      \
      $$ x = 5 $$
      \
      $$ x = -2, 2, 5 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Cubic"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Cubic Help", helpTextFunc: helpText}}
      />
    </div>
  );
}