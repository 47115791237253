export const ANGLE_RADIANS_SET = [0, 2, 3, 4, 6, 8, 9, 10, 12, 14, 15, 16, 18, 20, 21, 22, 24];
export const PI = 3.14159265359

export function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function randomUniqueIntFromInterval(min, max, numSet) { // min and max included
  let randomNum = randomIntFromInterval(min, max)
  while (numSet.includes(randomNum)) {
    randomNum = randomIntFromInterval(min, max)
  }

  return randomNum
}

export function randomNumFromSet(numSet) {
  return numSet[Math.floor(Math.random()*numSet.length)]
}

export function generateRandomAngle() {
  return randomIntFromInterval(15, 165)
}

export function roundNumber(number) {
  return Math.round(number*100)/100
}

export function generateSineCoordinates() {
  let angleRadiansSet = ANGLE_RADIANS_SET;

  let randomIndex = Math.floor(Math.random()*angleRadiansSet.length)
  let randomIndexMod8 = randomIndex % 8;

  let coordinate;
  if(randomIndexMod8 <= 4) {
    coordinate=  randomIndexMod8
  }
  else {
    coordinate = 4 - Math.abs(randomIndexMod8 - 4)
  }

  let sign = ""
  if (randomIndex >= 9 && randomIndex <= 15) {
    sign = "-"
  }

  return {
    numerator: angleRadiansSet[randomIndex],
    coordinate: coordinate,
    sign: sign
  }
}

export function generateCosineCoordinates() {
  let angleRadiansSet = ANGLE_RADIANS_SET;

  let randomIndex = Math.floor(Math.random()*angleRadiansSet.length)
  let randomIndexMod8 = randomIndex % 8;

  let coordinate;
  if(randomIndexMod8 <= 4) {
    coordinate=  randomIndexMod8
  }
  else {
    coordinate = 4 - Math.abs(randomIndexMod8 - 4)
  }

  let sign = ""
  if (randomIndex >= 5 && randomIndex <= 11) {
    sign = "-"
  }

  return {
    numerator: angleRadiansSet[randomIndex],
    coordinate: 4 - coordinate,
    sign: sign
  }
}

export function simplifyFraction(a, b) {
  if (b === 0) {
    return ["Does Not Exist", null]
  }

  if (b === 1) {
    return [a, null]
  }

  if (a === 0) {
    return [0, null]
  }

  // Check if result is an integer
  if ((a / b) % 1 === 0) {
    return [a / b, null]
  }

  let current_gcd = gcd(a,b)

  return [a/current_gcd, b/current_gcd]
}

export function displayFraction(a, b) {
  let fraction = simplifyFraction(a, b)

  fraction = fraction.filter(f => f === 0 || f)

  if (fraction.length === 0) {
    return ""
  }

  if(fraction.length === 1) {
    return fraction[0]
  }
  else {
    let numerator = fraction[0]
    let denominator = fraction[1]
    let sign = ""

    if ((numerator < 0 && denominator > 0) || (numerator > 0 && denominator < 0)) {
      sign = "-"
      numerator = Math.abs(numerator)
      denominator = Math.abs(denominator)
    } else if (numerator < 0 && denominator < 0) {
      numerator = Math.abs(numerator)
      denominator = Math.abs(denominator)
    }

    return `${sign}\\dfrac{${numerator}}{${denominator}}`
  }
}

export function gcd(x, y) {
  if ((typeof x !== 'number') || (typeof y !== 'number')) 
    return false;
  x = Math.abs(x);
  y = Math.abs(y);
  while(y) {
    var t = y;
    y = x % y;
    x = t;
  }
  return x;
}

export function logarithm(base, product) {
  return Math.log(product)/Math.log(base);
}