import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticOrderOfOperationsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let num1 = nums[Math.floor(Math.random()*nums.length)];
    let num2 = nums[Math.floor(Math.random()*nums.length)];
    let num3 = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(num1 === num2 || num1 === num3 || num2 === num3) {
      num1 = nums[Math.floor(Math.random()*nums.length)];
      num2 = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1 = num1 + num2 * num3;
    let result2 = (num1 + num2) * num3;
    let result3 = num1 * num2 - num3;
    let result4 = num1 * (num2 - num3);
    let result5 = num1 + num2 - num3;
    let result6 = (num1 + num2) - num3;
    let result7 = num1 + (num2 - num3);
    let result8 = num1 + Math.pow(num2 - num3,2);
    let result9 = num1 * Math.pow(num2 - num3,2);
    let result10 = num1 + (Math.pow(num2,2) - Math.pow(num3,2));
    let result11 = num1 * num2 - Math.pow(num3,2);
    let result12 = num1 * num2 * Math.pow(num3,2);
    let result13 = Math.pow(num1*num2,2) - num3;
    let result14 = Math.pow(num1,2) + num2 - num3;
    let result15 = (num1 + Math.pow(num2,2)) * (Math.pow(num3,2));

    generatedProblem.push({
      key: "1-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result1,
      format: <span>{`$ ${num1} + ${num2} \\cdot ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "2-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result2,
      format: <span>{`$ (${num1} + ${num2}) \\cdot ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "3-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result3,
      format: <span>{`$ ${num1} \\cdot ${num2} - ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "4-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result4,
      format: <span>{`$ ${num1} \\cdot (${num2} - ${num3}) $`}</span>
    });

    generatedProblem.push({
      key: "5-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result5,
      format: <span>{`$ ${num1} + ${num2} - ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "6-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result6,
      format: <span>{`$ (${num1} + ${num2}) - ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "7-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result7,
      format: <span>{`$ ${num1} + (${num2} - ${num3}) $`}</span>
    });

    generatedProblem.push({
      key: "8-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result8,
      format: <span>{`$ ${num1} + (${num2} - ${num3})^{2} $`}</span>
    });

    generatedProblem.push({
      key: "9-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result9,
      format: <span>{`$ ${num1} \\cdot (${num2} - ${num3})^{2} $`}</span>
    });

    generatedProblem.push({
      key: "10-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result10,
      format: <span>{`$ ${num1} + (${num2}^{2} - ${num3}^{2}) $`}</span>
    });

    generatedProblem.push({
      key: "11-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result11,
      format: <span>{`$ ${num1} \\cdot ${num2} - ${num3}^{2} $`}</span>
    });

    generatedProblem.push({
        key: "12-an",
        num1: num1,
        num2: num2,
        num3: num3,
        result: result12,
        format: <span>{`$ ${num1} \\cdot ${num2} (- ${num3})^{2} $`}</span>
      });

    generatedProblem.push({
      key: "13-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result13,
      format: <span>{`$ (${num1} \\cdot ${num2})^{2} - ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "14-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result14,
      format: <span>{`$ ${num1}^{2} + ${num2} - ${num3} $`}</span>
    });

    generatedProblem.push({
      key: "15-an",
      num1: num1,
      num2: num2,
      num3: num3,
      result: result15,
      format: <span>{`$ (${num1} + ${num2}^{2}) \\cdot (- ${num3})^{2} $`}</span>
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `
    $$ (6 + 4^{2}) \\cdot (-9)^{2} $$        
    \    
    $$ = (6 + 4^{2}) \\cdot (-9) \\cdot (-9) $$
    \
    $$ = (6 + 4^{2}) \\cdot 81 $$
    \
    $$ = (6 + 4 \\cdot 4) \\cdot 81 $$
    \
    $$ = (6 + 16) \\cdot 81 $$
    \
    $$ = (22) \\cdot 81 $$
    \
    $$ = 1782 $$
    `
  }

  return (
    <div>
     
      <EquationLayout
        title={"Arithmetic Order Of Operations A"}
        instructions={"Simplify following the Order of Operations."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={3}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Order Of Operations A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}