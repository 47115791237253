import React from 'react';
import { MathJaxContext } from "better-react-mathjax";
import Container from 'react-bootstrap/Container';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import MainLayout from '../MainLayout';
import ContainerLayout from '../ContainerLayout';
import Linear from '../Exercises/Linear';
import Polynomial from '../Exercises/Polynomial';
import Rational from '../Exercises/Rational';
import Exp_Log from '../Exercises/Exp_Log';
import Trigonometry from '../Exercises/Trigonometry';
import Vector_Matrix from '../Exercises/Vector_Matrix';
import Arithmetic from '../Exercises/Arithmetic';
import Calculus from '../Exercises/Calculus';
import Statistics from '../Exercises/Statistics';
import About from '../About';
import ContactUs from '../ContactUs';
import Advanced from '../Exercises/Advanced';

const config = {
  "fast-preview": {
    disabled: true
  },
  tex2jax: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"]
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"]
    ]
  },
  messageStyle: "none"
};

export default function MainContainer() {
  return (
    <Container>
      <MathJaxContext
        version={2}
        config={config}
        onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainLayout />}>
            <Route exact path="/" element={<Navigate replace to="exercises/linear" />}/>
            <Route exact path="/linear" element={<Navigate replace to="/exercises/linear" />}/>
              <Route path="exercises" element={<ContainerLayout />}>
                <Route exact path="" element={<Navigate replace to="linear" />}/>
                <Route path="arithmetic" element={<Arithmetic />} />
                <Route path="linear" element={<Linear />} />
                <Route path="polynomial" element={<Polynomial />} />
                <Route path="rational" element={<Rational />} />
                <Route path="exp_log" element={<Exp_Log />} />
                <Route path="trigonometry" element={<Trigonometry />} />
                <Route path="vector_matrix" element={<Vector_Matrix />} />                
                <Route path="calculus" element={<Calculus />} />
                <Route path="statistics" element={<Statistics />} />
                <Route path="advanced" element={<Advanced />} />
              </Route>
              <Route path="about" element={<About/>}/>
              <Route path="contact" element={<ContactUs/>}/>
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>Oooops page not found!</p>
                  </main>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </MathJaxContext>
    </Container>
  )
}
