export function trigFuncMappings() {
  return [
    {
      func: sinFormula,
      answer: sinFormulaAnswer,
      funcDisplay: `\\sin(x)`,
      funcName: 'sin'
    },
    {
      func: cosFormula,
      answer: cosFormulaAnswer,
      funcDisplay: `\\cos(x)`,
      funcName: 'cos'
    },
    {
      func: tanFormula,
      answer: tanFormulaAnswer,
      funcDisplay: `\\tan(x)`,
      funcName: 'tan'
    },
    {
      func: cscFormula,
      answer: cscFormulaAnswer,
      funcDisplay: `\\csc(x)`,
      funcName: 'csc'
    },
    {
      func: secFormula,
      answer: secFormulaAnswer,
      funcDisplay: `\\sec(x)`,
      funcName: 'sec'
    },
    {
      func: cotFormula,
      answer: cotFormulaAnswer,
      funcDisplay: `\\cot(x)`,
      funcName: 'cot'
    }
  ]
}

export function determineAngle(params, trigFuncName, negative) {
  let unitCircleMappingsValues = unitCircleMappings()

  let filteredByFuncName = []
  if(negative) {
    filteredByFuncName = unitCircleMappingsValues.filter(mappings => mappings.negatives.includes(trigFuncName))
  }
  else {
    filteredByFuncName = unitCircleMappingsValues.filter(mappings => !mappings.negatives.includes(trigFuncName))
  }

  let filteredByA = filteredByFuncName.filter(mappings => mappings.a === params.a)

  return filteredByA.map(mappings => mappings.x)
}

export function sinFormula(param) {
  return `\\sin \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function sinFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("sin")) {
    sign = "-"
  }

  let numerator = `\\sqrt{${param.a}}`
  let answer = `\\dfrac{${numerator}}{2}`
  if((Math.sqrt(param.a) % 1) === 0) {
    numerator = Math.sqrt(param.a)
    answer = `\\dfrac{${numerator}}{2}`

    if((numerator / 2) % 1 === 0){
      answer = `${numerator / 2}`
    }
  }

  return {
    value: answer,
    sign: sign
  }
}

export function cosFormula(param) {
  return `\\cos \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function cosFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("cos")) {
    sign = "-"
  }

  let numerator = `\\sqrt{${4 - param.a}}`
  let answer = `\\dfrac{${numerator}}{2}`
  if((Math.sqrt(4 - param.a) % 1) === 0) {
    numerator = Math.sqrt(4 - param.a)
    answer = `\\dfrac{${numerator}}{2}`

    if((numerator / 2) % 1 === 0){
      answer = `${numerator / 2}`
    }
  }

  return {
    value: answer,
    sign: sign
  }
}

export function tanFormula(param) {
  return `\\tan \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function tanFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("tan")) {
    sign = "-"
  }

  let numerator = `\\sqrt{${param.a}}`
  if((Math.sqrt(param.a) % 1) === 0) {
    numerator = Math.sqrt(param.a)
  }

  let denominator = `\\sqrt{${4 - param.a}}`
  if((Math.sqrt(4 - param.a) % 1) === 0) {
    if(Math.sqrt(4 - param.a) === 0) {
      return {
        value: `undefined`,
        sign: ``
      }
    }

    denominator = Math.sqrt(4 - param.a)
  }

  let answer = `\\dfrac{${numerator}}{${denominator}}`
  if ((numerator/denominator % 1) === 0) {
    answer = numerator/denominator
  }
  if (denominator === 1) {
    answer = numerator
  }
  if (numerator === denominator) {
    answer = 1
  }

  return {
    value: answer,
    sign: sign
  }
}

export function cscFormula(param) {
  return `\\csc \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function cscFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("csc")) {
    sign = "-"
  }

  let denominator = `\\sqrt{${param.a}}`
  if((Math.sqrt(param.a) % 1) === 0) {
    if(Math.sqrt(param.a) === 0) {
      return {
        value: `undefined`,
        sign: ``
      }
    }

    denominator = Math.sqrt(param.a)
  }

  let numerator = 2
  let answer = `\\dfrac{2}{${denominator}}`
  if ((numerator/denominator % 1) === 0) {
    answer = numerator/denominator
  }
  if (numerator === denominator) {
    answer = 1
  }

  return {
    value: answer,
    sign: sign
  }
}

export function secFormula(param) {
  return `\\sec \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function secFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("sec")) {
    sign = "-"
  }

  let numerator = 2
  let denominator = `\\sqrt{${4 - param.a}}`

  if((Math.sqrt(4 - param.a) % 1) === 0) {
    if(Math.sqrt(4 - param.a) === 0) {
      return {
        value: `undefined`,
        sign: ``
      }
    }

    denominator = Math.sqrt(4 - param.a)
  }

  let answer = `\\dfrac{${numerator}}{${denominator}}`
  if ((numerator/denominator % 1) === 0) {
    answer = numerator/denominator
  }
  if (denominator === 1) {
    answer = numerator
  }
  if (numerator === denominator) {
    answer = 1
  }

  return {
    value: answer,
    sign: sign
  }
}

export function cotFormula(param) {
  return `\\cot \\left( \\dfrac{${param.x}\\pi}{12} \\right)`
}

export function cotFormulaAnswer(param) {
  let negatives = param.negatives
  let sign = ""
  if (negatives.includes("cot")) {
    sign = "-"
  }

  let numerator = `\\sqrt{${4 - param.a}}`
  let denominator = `\\sqrt{${param.a}}`

  if((Math.sqrt(4 - param.a) % 1) === 0) {
    numerator = Math.sqrt(4 - param.a)
  }

  if((Math.sqrt(param.a) % 1) === 0) {
    if(Math.sqrt(param.a) === 0) {
      return {
        value: `undefined`,
        sign: ``
      }
    }

    denominator = Math.sqrt(param.a)
  }

  let answer = `\\dfrac{${numerator}}{${denominator}}`
  if ((numerator/denominator % 1) === 0) {
    answer = numerator/denominator
  }
  if (denominator === 1) {
    answer = numerator
  }
  if (numerator === denominator) {
    answer = 1
  }

  return {
    value: answer,
    sign: sign
  }
}

export function unitCircleMappings() {
  return [
    {
      x: 0,
      a: 0,
      negatives: []
    },
    {
      x: 2,
      a: 1,
      negatives: []
    },
    {
      x: 3,
      a: 2,
      negatives: []
    },
    {
      x: 4,
      a: 3,
      negatives: []
    },
    {
      x: 6,
      a: 4,
      negatives: []
    },
    {
      x: 8,
      a: 3,
      negatives: ['cos', 'sec', 'tan', 'cot']
    },
    {
      x: 9,
      a: 2,
      negatives: ['cos', 'sec', 'tan', 'cot']
    },
    {
      x: 10,
      a: 1,
      negatives: ['cos', 'sec', 'tan', 'cot']
    },
    {
      x: 12,
      a: 0,
      negatives: ['cos', 'sec']
    },
    {
      x: 14,
      a: 1,
      negatives: ['cos', 'sec', 'sin', 'csc']
    },
    {
      x: 15,
      a: 2,
      negatives: ['cos', 'sec', 'sin', 'csc']
    },
    {
      x: 16,
      a: 3,
      negatives: ['cos', 'sec', 'sin', 'csc']
    },
    {
      x: 18,
      a: 4,
      negatives: ['sin', 'csc']
    },
    {
      x: 20,
      a: 3,
      negatives: ['sin', 'csc', 'tan', 'cot']
    },
    {
      x: 21,
      a: 2,
      negatives: ['sin', 'csc', 'tan', 'cot']
    },
    {
      x: 22,
      a: 1,
      negatives: ['sin', 'csc', 'tan', 'cot']
    },
    {
      x: 24,
      a: 0,
      negatives: []
    },
  ]
}
