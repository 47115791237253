import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticPrimeFactorization() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 5, 7, 11, 13];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber*secondNumber*thirdNumber;

      generatedProblem.push({
        key: `${step}-ama`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: "*"
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.result}$`,
      answer: `$${equation.firstNumber} \\cdot ${equation.secondNumber} \\cdot ${equation.thirdNumber}$`
    }
  }

  function helpText() {
    return `
      $$ 90 $$
      \  
      $$ = 9 \\cdot 10 $$
      \
      $$ = 3 \\cdot 3 \\cdot 10 $$
      \
      $$ = 3 \\cdot 3 \\cdot 2 \\cdot 5 $$
      \
      $$ = 2 \\cdot 3^{2} \\cdot 5 $$
    `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Arithmetic Prime Factorization"}
        instructions={"Factor the following as far as possible."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Prime Factorization", helpTextFunc: helpText}}
      />
    </div>
  );
}