import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepLikeTermsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, -2, -3, -4, -5, -6, -7, -8, -9, -10];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = (firstNumber * secondNumber) + (thirdNumber * secondNumber);
      if(result === 0) {
        step--;
        continue;
      }

      let sign = "+"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-ltslta`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}x ${equation.sign} ${equation.thirdNumber}x = ${equation.result}$`,
      answer: `$x = ${equation.secondNumber}$`
    }
  }

  function helpText() {
    return `

    $$ 8x - 5x = -27 $$       
    \
    $$ (8 - 5) \\cdot x = -27 $$
    \
    $$ (3) \\cdot x = -27 $$
    \
    $$ 3x = -27 $$
    \
    $$ \\dfrac{3x = -27}{3} $$
    \
    $$ \\dfrac{3x}{3} = \\dfrac{-27}{3} $$
    \
    $$ \\dfrac{3 \\cdot x}{3 \\cdot 1} = \\dfrac{-9 \\cdot 3}{1 \\cdot 3} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{-9}{1} $$
    \
    $$ x = -9 $$

    `
  }

  return (
    <div>
      

      <EquationLayout
        title={"Linear Two Step Like Terms A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step Like Terms A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}