import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticSquares() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];
    const seenNumbers = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];

      if(seenNumbers.includes(firstNumber)) {
        step--;
        continue;
      }

      let result1 = Math.pow(firstNumber,3);

      seenNumbers.push(firstNumber);

      generatedProblem.push({
        key: `${step}-ac`,
        firstNumber: firstNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$(${equation.firstNumber})^{3}$`,
      answer: `$${equation.result1}$`
    }
  }

  function helpText() {
    return `$$
    
      { (-2)^{3}} $$
            
      \
    
      $$ {= (-2) \\cdot (-2) \\cdot (-2)} $$

      \

      $$ {= 4 \\cdot (-2)} $$

      \

      $$ {= -8}

     $$`
  }

  return (
    <div>
     

      <EquationLayout
      title={"Arithmetic Cubes"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Cubes Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
