import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { roundNumber, randomIntFromInterval } from '../../../../utils/mathHelpers';

export default function CalculusRiemannSums() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const nums2 = [3, 4, 5, 6];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {

      // Parameters initialization
      let n = randomIntFromInterval(3, 6);
      let d = randomIntFromInterval(1, 9);
      let a = randomIntFromInterval(-9, 9);
      let b = a + d;
      let c1 = randomIntFromInterval(-9, 9);
      let c2 = randomIntFromInterval(-9, 9);
      let c3 = randomIntFromInterval(-9, 9);
      let sign1 = "+";
      let sign2 = "+";
      let sign3 = "+";
      let ex1 = "x^{2}";
      let ex2 = "x"

      let dx = (b - a) / n;
      let v = [];
      for (let i = a; i <= b; i += dx / 2) {
        v.push(i);
      }

      // Create matrix m with dimensions n x 6 filled with zeros
      let m = Array.from({ length: v.length }, () => Array(6).fill(0));

      // Fill matrix m
      for (let i = 0; i < v.length; i++) {  // Ensure you don't exceed the matrix dimensions
        m[i][0] = v[i];
        m[i][1] = Math.pow(m[i][0], 3) + c1 * Math.pow(m[i][0], 2) + c2 * m[i][0] + c3;
        m[i][2] = m[i][1] * ((i + 1) % 2);
        if (i === v.length - 1) {
          m[i][2] = 0;  // Ensure not accessing out of bounds
        }
        m[i][3] = m[i][1] * ((i + 1) % 2);
        if (i === 0) {
          m[0][3] = 0;
        }
        m[i][4] = m[i][1] * (i % 2);
        m[i][5] = 2 * m[i][1];
        if (i === 0) {
          m[0][5] = m[0][1];  // Safe access for the first row
        }
        if (i === v.length - 1) {
          m[v.length - 1][5] = m[v.length - 1][1];  // Safe access for the last row
        }
        m[i][5] = m[i][5] * ((i + 1) % 2);
      }

      // Calculate L, R, M, and T
      let L = roundNumber(m.reduce((acc, row) => acc + row[2], 0) * dx);
      let R = roundNumber(m.reduce((acc, row) => acc + row[3], 0) * dx);
      let M = roundNumber(m.reduce((acc, row) => acc + row[4], 0) * dx);
      let T = roundNumber(m.reduce((acc, row) => acc + row[5], 0) * (dx / 2));

      // Ensure correct sign display
      if (c1 < 0) { c1 = Math.abs(c1); sign1 = "-"; }
      if (c2 < 0) { c2 = Math.abs(c2); sign2 = "-"; }
      if (c3 < 0) { c3 = Math.abs(c3); sign3 = "-"; }

      if (c1 == 0) { c1 = " "; sign1 = " "; ex1 = " ";}
      if (c2 == 0) { c2 = " "; sign2 = " "; ex2 = " ";}
      if (c3 == 0) { c3 = " "; sign3 = " ";}

      if (c1 == 1) { c1 = " "; }
      if (c2 == 1) { c2 = " "; }

      generatedProblem.push({
        key: `${step}-crs`,
        a: a,
        b: b,
        n: n,
        d: d,
        c1: c1,
        c2: c2,
        c3: c3,
        dx: dx,
        v: v,
        m: m,
        L: L,
        R: R,
        M: M,
        T: T,
        ex1: ex1,
        ex2: ex2,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach((problem) => {
      formattedProblemList.push(equationFormatter(problem));
    });

    setFormattedProblems(formattedProblemList);
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
          <span>{`$ \\int_{${equation.a}}^{${equation.b}} x^{3} ${equation.sign1} ${equation.c1} ${equation.ex1} ${equation.sign2} ${equation.c2} ${equation.ex2} ${equation.sign3} ${equation.c3} \\, dx \\, \\, \\, \\, \\, \\, n = ${equation.n} $`}</span>
        </span>
      ),
      answer: (
        <span>
          <span>{`$ \\text{Left} = ${equation.L} $`}</span>
          <br />
          <span>{`$ \\text{Right} = ${equation.R} $`}</span>
          <br />
          <span>{`$ \\text{Midpoint} = ${equation.M} $`}</span>
          <br />
          <span>{`$ \\text{Trapezoid} = ${equation.T} $`}</span>
          <br />
        </span>
      ),
    };
  }

  function helpText() {
    return `
      $$ -4x + 3y = 5 $$    
      \
      $$ m = \\frac{4}{3} $$
    `;
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Reimann Sums"}
        instructions={"Compute the Left, Right, Midpoint, and Trapezoid Reimann Sums to approximate the Integral."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{ title: "Riemann Sums Help", helpTextFunc: helpText }}
      />
    </div>
  );
}
