import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialQuadraticTwoStep() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = (firstNumber * firstNumber) + secondNumber;
      let sign = "+"

      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign = "-";
      }       

      generatedProblem.push({
        key: `${step}-qtws`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$x^${equation.exponent} ${equation.sign} ${equation.secondNumber} = ${equation.result}$`,
      answer: `$x= \\pm ${Math.abs(equation.firstNumber)}$`,
    }
  }

  function helpText() {
    return `
      $$ x^{2} - 3 = 61 $$    
      \
    $$ +3 \\hspace{7mm} +3 $$
      \
      $$ x^{2} = 64 $$
      \
      $$ x^{2} = (\\pm 8)^{2} $$
      \
      $$ ^{2} \\sqrt{x^{2}} = \\hspace{2mm} ^{2} \\sqrt{(\\pm 8)^{2}} $$
      \
      $$ \\sqrt{x^{2}} = \\sqrt{(\\pm 8)^{2}} $$
      \
      $$ x = \\pm 8 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Quadratic Two Step"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quadratic Two Step Help", helpTextFunc: helpText}}
      />
    </div>
  );
}