import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrix3x3() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 2;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eigthNumber = nums[Math.floor(Math.random()*nums.length)];
      let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }


      let resulta = (firstNumber*fifthNumber*ninthNumber)-(firstNumber*sixthNumber*eigthNumber)-(secondNumber*fourthNumber*ninthNumber)+(secondNumber*sixthNumber*seventhNumber)+(thirdNumber*fourthNumber*eigthNumber)-(thirdNumber*fifthNumber*seventhNumber);
      let result1a = roundNumber((1/resulta)*(fifthNumber*ninthNumber-sixthNumber*eigthNumber));
      let result2a = roundNumber((1/resulta)*(thirdNumber*eigthNumber-secondNumber*ninthNumber));
      let result3a = roundNumber((1/resulta)*(secondNumber*sixthNumber-thirdNumber*fifthNumber));
      let result4a = roundNumber((1/resulta)*(sixthNumber*seventhNumber-fourthNumber*ninthNumber));
      let result5a = roundNumber((1/resulta)*(firstNumber*ninthNumber-thirdNumber*seventhNumber));
      let result6a = roundNumber((1/resulta)*(thirdNumber*fourthNumber-firstNumber*sixthNumber));
      let result7a = roundNumber((1/resulta)*(fourthNumber*eigthNumber-fifthNumber*seventhNumber));
      let result8a = roundNumber((1/resulta)*(secondNumber*seventhNumber-firstNumber*eigthNumber));
      let result9a = roundNumber((1/resulta)*(firstNumber*fifthNumber-secondNumber*fourthNumber));

      

      generatedProblem.push({
        key: `${step}-vm3`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eigthNumber: eigthNumber,
        ninthNumber: ninthNumber,
        resulta: resulta,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a,
        result5a: result5a,
        result6a: result6a,
        result7a: result7a,
        result8a: result8a,
        result9a: result9a
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = (
      <span>
        <span>
          {
          
          `$M = \\begin{pmatrix}
          ${equation.firstNumber} & ${equation.secondNumber} & ${equation.thirdNumber}\\\\
          ${equation.fourthNumber} & ${equation.fifthNumber} & ${equation.sixthNumber}\\\\
          ${equation.seventhNumber} & ${equation.eigthNumber} & ${equation.ninthNumber}
          \\end{pmatrix}$`
          
          }

        </span>
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 

        <span>
        <span>
         {`$M^{T} = \\begin{pmatrix}
          ${equation.firstNumber} & ${equation.fourthNumber} & ${equation.seventhNumber}\\\\
          ${equation.secondNumber} & ${equation.fifthNumber} & ${equation.eigthNumber}\\\\
          ${equation.thirdNumber} & ${equation.sixthNumber} & ${equation.ninthNumber}
          \\end{pmatrix}$`}
        </span>
        <br/>
        <span>
        {`$M^{-1} = \\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a} & ${equation.result3a}\\\\
          ${equation.result4a} & ${equation.result5a} & ${equation.result6a}\\\\
          ${equation.result7a} & ${equation.result8a} & ${equation.result9a}
          \\end{pmatrix}$`}
        </span>
        <br/>
        <span>
        {`$Det(M) = ${equation.resulta}$`}
        </span>
        </span>
    };
  }

  return (
    <div>
     

      <EquationLayout
      title={"Vector/Matrix Matrix 3x3"}
      instructions={"Compute the transpose, inverse, and determinant."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}