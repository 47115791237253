import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function StatisticsCombinatorics() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      while (firstNumber < secondNumber) {
        secondNumber = nums[Math.floor(Math.random()*nums.length)];
      }
      let result1 = factorialize(firstNumber);
      let result2 = factorialize(secondNumber);
      let result3 = firstNumber - secondNumber;
      let result4 = factorialize(result3);
      let result5 = result1/result4;
      let result6 = result5/result2;
      let problemType = step % 2 === 0 ? "C" : "P";
      let result = problemType === "C" ? result6 : result5


      generatedProblem.push({
        key: `${step}-sc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        problemType: problemType

      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function factorialize(num) {
    if (num === 0 || num === 1) {
      return 1;
    }

    let result = num;
    while (num > 1) {
      num--;
      result *= num;
    }

    return result
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.problemType}(${equation.firstNumber},${equation.secondNumber})$`,
      answer: `$${equation.result}$`
    }
  }

  return (
    <div>
      <EquationLayout
        title={"Statistics Combinatorics"}
        instructions={"Compute the Combination and Permutation."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}
