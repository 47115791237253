import { randomIntFromInterval, randomNumFromSet } from '../../../../utils/mathHelpers';
import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';;

export default function ArithmeticMultiplicationC() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [0, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
        let firstNumber = randomIntFromInterval(100, 999);
        let secondNumber = randomIntFromInterval(100, 999);
        let result = firstNumber*secondNumber;

      generatedProblem.push({
        key: `${step}-amc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        sign: "*"
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber} \\cdot ${equation.secondNumber}$`,
      answer: `$${equation.result}$`
    }
  }

  return (
    <div>
      

      <EquationLayout
      title={"Arithmetic Multiplication C"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}