import React, { useState } from 'react';

import TrigonometryRightTriangles from './TrigonometryRightTriangles';
import TrigonometryGenericTriangles from './TrigonometryGenericTriangles';
import TrigonometryCircles from './TrigonometryCircles';
import TrigonometryUnitCircleA from './TrigonometryUnitCircleA';
import TrigonometryUnitCircleB from './TrigonometryUnitCircleB';
import Button from 'react-bootstrap/Button';

const RIGHT_TRIANGLES = "right_triangles"
const GENERIC_TRIANGLES = "generic_triangles"
const CIRCLES = "circles"
const UNIT_CIRCLE_A = "unit_circle_a"
const UNIT_CIRCLE_B = "unit_circle_b"
const FUNCTION_TYPES = [RIGHT_TRIANGLES, GENERIC_TRIANGLES, CIRCLES, UNIT_CIRCLE_A, UNIT_CIRCLE_B];

const functionTypeDisplayNames = {
  "right_triangles": "Right Triangles",
  "generic_triangles": "Generic Triangles",
  "circles": "Circles",
  "unit_circle_a": "Unit Circle A",
  "unit_circle_b": "Unit Circle B"
}

export default function Trigonometry() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(RIGHT_TRIANGLES);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case RIGHT_TRIANGLES: {
        return <TrigonometryRightTriangles />;
      }
      case GENERIC_TRIANGLES: {
        return <TrigonometryGenericTriangles />;
      }
      case CIRCLES: {
        return <TrigonometryCircles />;
      }
      case UNIT_CIRCLE_A: {
        return <TrigonometryUnitCircleA />;
      }
      case UNIT_CIRCLE_B: {
        return <TrigonometryUnitCircleB />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}