import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { ANGLE_RADIANS_SET, generateCosineCoordinates, randomNumFromSet } from '../../../../utils/mathHelpers';
import genericCircle from '../images/generic-circle.png';

export default function TrigonometryCircles() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = ANGLE_RADIANS_SET;
    const radius_set = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let sineCoordinates = generateCosineCoordinates()
      let sineCoordinatesFirst = sineCoordinates.numerator
      let sineCoordinatesSecond = sineCoordinates.coordinate

      let radius = randomNumFromSet(radius_set)
      let thetaCoef = randomNumFromSet(ANGLE_RADIANS_SET)
      let thetaDenom = 12
      let degrees = (30 * thetaCoef) / 2
      let diameter = 2 * radius
      let area = radius ^ 2

      generatedProblem.push({
        key: `${step}-c`,
        radius: radius,
        thetaCoef: thetaCoef,
        thetaDenom: thetaDenom,
        degrees: degrees,
        diameter: diameter,
        area: area
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$\\text{Angle In Degrees} = ${equation.degrees}^\\circ$`}</span>
      <br/>
      <span>{`$\\text{Diameter} = ${equation.diameter}$`}</span>
      <br/>
      <span>{`$\\text{Circumference} = ${equation.radius * 2}\\pi$`}</span>
      <br/>
      <span>{`$\\text{Area} = ${Math.pow(equation.radius, 2)}\\pi$`}</span>
      <br/>
      <span>{`$\\text{Area Of Sector} = \\dfrac{${equation.thetaCoef * Math.pow(equation.radius,2)}\\pi}{24}$`}</span>
      <br/>
      <span>{`$\\text{Arc Length} = \\dfrac{${equation.thetaCoef * equation.radius}\\pi}{${equation.thetaDenom}}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$\\theta = \\dfrac{${equation.thetaCoef}\\pi}{${equation.thetaDenom}}, r = ${equation.radius}$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
    $$ \\theta^{\\circ} = \\theta \\hspace{1mm} \\text{rad} \\cdot \\frac{180}{\\pi} $$
    \
    $$ \\theta \\hspace{1mm} \\text{rad} = \\theta^{\\circ} \\cdot \\frac{\\pi}{180} $$
    \
    $$ \\text{Diameter} = d = 2r $$
    \
    $$ \\text{Circumference} = c = 2 \\pi r = \\pi d $$
    \
    $$ \\text{Area of Circle} = \\pi r^{2}$$
    \
    $$ \\% = \\frac{\\theta \\hspace{1mm} \\text{rad}}{2 \\pi \\hspace{1mm} \\text{rad}} = \\frac{\\theta ^{\\circ}}{360^{\\circ}} $$
    \
    $$ \\text{Area of Sector} =  \\% \\cdot \\text{Area of Circle} = \\frac{\\theta r^{2}}{2} \\hspace{1mm} \\text{(angle in radians)} $$
    \
    $$ \\text{Arc Length} = \\% \\cdot \\text{Circumference} = \\theta r \\hspace{1mm} \\text{(angle in radians)} $$
    `
  }

  return (
    <div>
      
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <img src={genericCircle} alt="Circle" height={200} width={200}/>
      </div>

      <EquationLayout
        title={"Trigonometry Circles"}
        instructions={"Compute angle in degrees, diameter, circumference, area, area of sector, and arc length."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Circles Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
