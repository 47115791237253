import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function Vector_MatrixVectorLinearCombinations() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstCoefficient = randomNumFromSet(nums)
      let secondCoefficient = randomNumFromSet(nums)
      let sign = "+"

      let firstSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }
      let secondSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }

      let firstSetMultiplied = [firstCoefficient * firstSet.one, firstCoefficient * firstSet.two, firstCoefficient * firstSet.three]
      let secondSetMultiplied = [secondCoefficient * secondSet.one, secondCoefficient * secondSet.two, secondCoefficient * secondSet.three]

      let finalSet = {
        one: firstSetMultiplied[0] + secondSetMultiplied[0],
        two: firstSetMultiplied[1] + secondSetMultiplied[1],
        three: firstSetMultiplied[2] + secondSetMultiplied[2]
      }

      if(secondCoefficient < 0) {
        secondCoefficient = Math.abs(secondCoefficient);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-lv`,
        firstCoefficient: firstCoefficient,
        secondCoefficient: secondCoefficient,
        firstSet: firstSet,
        secondSet: secondSet,
        finalSet: finalSet,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstCoefficient} \\: \\cdot <${equation.firstSet.one}, ${equation.firstSet.two}, ${equation.firstSet.three}> ${equation.sign} \\; ${equation.secondCoefficient} \\: \\cdot <${equation.secondSet.one}, ${equation.secondSet.two}, ${equation.secondSet.three}>$`,
      answer: `$<${equation.finalSet.one}, ${equation.finalSet.two}, ${equation.finalSet.three}>$`
    }
  }

  function helpText() {
    return `
    $$ 2 \\hspace{1mm} \\cdot < 4, \\hspace{1mm} -6, \\hspace{1mm} 5> - \\hspace{1mm} 3 \\hspace{1mm} \\cdot <7, \\hspace{1mm} 8, \\hspace{1mm} -1> $$
    \
    $$ = \\hspace{1mm} 2 \\hspace{1mm} \\cdot < 4, \\hspace{1mm} -6, \\hspace{1mm} 5> + \\hspace{1mm} (- 3 \\hspace{1mm} \\cdot <7, \\hspace{1mm} 8, \\hspace{1mm} -1>) $$
    \
    $$ = \\hspace{1mm} < 2 \\cdot 4, \\hspace{1mm} 2 \\cdot (-6), \\hspace{1mm} 2 \\cdot 5> + \\hspace{1mm} (<-3 \\cdot 7, \\hspace{1mm} -3 \\cdot 8, \\hspace{1mm} -3 \\cdot (-1)>) $$
    \
    $$ = \\hspace{1mm} < 8, \\hspace{1mm} -12, \\hspace{1mm} 10> + \\hspace{1mm} (<-21, \\hspace{1mm} -24, \\hspace{1mm} 3>) $$
    \
    $$ = \\hspace{1mm} < 8 + (-21), \\hspace{1mm} -12 + (-24), \\hspace{1mm} 10 + 3> $$
    \
    $$ = \\hspace{1mm} < 8 - 21, \\hspace{1mm} -12 - 24, \\hspace{1mm} 10 + 3> $$
    \
    $$ = \\hspace{1mm} < -13, \\hspace{1mm} -36, \\hspace{1mm} 13> $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Vector Linear Combinations"}
        instructions={"Compute the following."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Vector Linear Combinations Help", helpTextFunc: helpText}}
      />
    </div>
  );
}