import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

import { randomIntFromInterval } from '../../../../utils/mathHelpers';

export default function StatisticsCenter() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [5, 6, 7, 8, 9]; // number of numbers allowed in the set
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let numberSize = nums[Math.floor(Math.random()*nums.length)];
      let numberSet = []
      for (let x = 0; x < numberSize; x++) {
        numberSet.push(randomIntFromInterval(0, 20))
      }
      let ogSet = numberSet.map((x) => x)

      let mean = numberSet.reduce((a, b) => a + b, 0) / numberSet.length

      let sortedSet = numberSet.sort(function(a, b) {
        return a - b;
      });
      let mid = sortedSet.length / 2;
      let median =  mid % 1 ? sortedSet[mid - 0.5] : (sortedSet[mid - 1] + sortedSet[mid]) / 2;

      let modeSet = {}
      numberSet.forEach(num =>{
        if(modeSet[num]) {
          modeSet[num] = modeSet[num] + 1
        }
        else {
          modeSet[num] = 1
        }
      });
      let maxCount = 2;
      let mode = [];
      for (var key in modeSet){
        if(modeSet[key] === maxCount) {
          mode.push(key)
        }
        else if(modeSet[key] > maxCount) {
          maxCount = modeSet[key]
          mode = []
          mode.push(key)
        }
      }

      generatedProblem.push({
        key: `${step}-sc`,
        numberSet: ogSet,
        mean: mean,
        median: median,
        mode: mode.length === 0 ? "none" : mode
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$ \\text{Mean} = ${Math.round(equation.mean*100)/100}$`}</span>
      <br/>
      <span>{`$ \\text{Median} = ${equation.median}$`}</span>
      <br/>
      <span>{`$ \\text{Mode} = ${equation.mode}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$ \\{ ${equation.numberSet} \\}$`,
      answer: answerToDisplay
    }
  }

  return (
    <div>
      
      <EquationLayout
       title={"Statistics Center"}
       instructions={"Compute the mean, median, and mode."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}
