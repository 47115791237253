import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogLogExponent() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = thirdNumber*Math.pow(secondNumber,thirdNumber);
      let result2 = firstNumber*thirdNumber;
      let result3 = Math.pow(secondNumber,thirdNumber);

      generatedProblem.push({
        key: `${step}-le`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        result3: result3
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$Log_{${equation.secondNumber}} (x^{${equation.firstNumber}}) = ${equation.result2}$`,
      answer: `$x = ${equation.result3}$`,
    }
  }

  function helpText() {
    return `
    $$ Log_{7}(x^{3}) = 6 $$
    \
      $$ 3 \\cdot Log_{7}(x) = 6 $$    
      \
      $$ \\frac{3 \\cdot Log_{7}(x) = 6}{3} $$
      \
      $$ \\frac{3 \\cdot Log_{7}(x)}{3} = \\frac{6}{3} $$
      \
      $$ Log_{7}(x) = 2 $$
      \
      $$ 7^{2} = x $$
      \
      $$ 49 = x $$
      `
  }

  return (
    <div>
      

      <EquationLayout
        title={"Logarithm Exponent"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Logarithm Exponent Help", helpTextFunc: helpText}}
      />
    </div>
  );
}