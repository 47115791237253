import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet } from '../../../../utils/mathHelpers';
import { funcMappings, summation } from '../../../../utils/calculusSummationHelper';

export default function CalculusSeries() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums1 = [2, 3, 4, 5, 6, 7];
    const nums2 = [2, 3, 4];
    const funcMappingsHash = funcMappings()
    const funMappingKeys = Object.keys(funcMappingsHash)
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let randomFuncName = randomNumFromSet(funMappingKeys);
      let randomFuncObj = funcMappingsHash[randomFuncName];
      const { display, computed } = randomFuncObj;

      let firstNumber = randomNumFromSet(nums1);
      let secondNumber = randomNumFromSet(nums2) + firstNumber;
      let result = summation(firstNumber, secondNumber, computed)

      generatedProblem.push({
        key: `${step}-cs`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        display: display,
        result: result,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\displaystyle\\sum_{${equation.firstNumber}}^{${equation.secondNumber}} ${equation.display}$`,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `
        $$ \\displaystyle\\sum_{3}^{5} n^{2} - 2n $$
        \
        $$ = \\Bigl( (3)^{2} - 2(3) \\Bigr) + \\Bigl( (4)^{2} - 2(4) \\Bigr) + \\Bigl( (5)^{2} - 2(5) \\Bigr) $$
        \
        $$ = (9 - 6) + (16 - 8) + (25 - 10) $$
        \
        $$ = (3) + (8) + (15) $$
        \
        $$ = 3 + 8 + 15 $$
        \
        $$ = 26 $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Series"}
        instructions={"Compute the summation."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Series Help", helpTextFunc: helpText}}
      />
    </div>
  );
}