import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticPercentA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums1 = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12 ,13 ,14 ,15, 16, 17, 18, 19, 20];
    const nums2 = [1, 2, 4, 5, 10, 20, 25, 50, 100]
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums1[Math.floor(Math.random()*nums1.length)];
      let secondNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let result1 = 100/secondNumber;
      let result2 = firstNumber*secondNumber;

      generatedProblem.push({
        key: `${step}-apa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.result1} \\% \\; of \\; ${equation.result2}$`,
      answer: `$${equation.firstNumber}$`
    }
  }

  function helpText() {
    return `$$
      { 20 \\% \\hspace{2mm} of \\hspace{2mm} 75} $$
      
      \

      $$ { = \\frac{20}{100} \\cdot 75} $$
            
      \
      
      $$ { = \\frac{20}{100} \\cdot \\frac{75}{1}} $$
      
      \
      
      $$ { = \\frac{20 \\cdot 75}{100 \\cdot 1}} $$
      
      \
      
      $$ { = \\frac{1500}{100}} $$
    
      \
      
      $$ { = \\frac{15 \\cdot 100}{1 \\cdot 100}} $$
      
      \
      
      $$ { = \\frac{15}{1}} $$
      
      \
      
      $$ { = 15}
    $$`
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Percent A"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Percent A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}