import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { gcd } from '../../../../utils/mathHelpers';

export default function PolynomialFactoring() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const nums2 = [-2, -3, -4, -5, -6, -8, -10, -12, -14, -15, -16, -18, -20, -21, -22, -24, -25, 
        2, 3, 4, 5, 6, 8, 10, 12, 14, 15, 16, 18, 20, 21, 22, 24, 25]
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let secondNumber = Math.abs(nums[Math.floor(Math.random()*nums.length)]);
      let thirdNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let fourthNumber = Math.abs(nums[Math.floor(Math.random()*nums.length)]);
      let fifthNumber = nums2[Math.floor(Math.random()*nums2.length)];
      let sixthNumber = Math.abs(nums[Math.floor(Math.random()*nums.length)]);

      while (fourthNumber === secondNumber || fourthNumber === sixthNumber || secondNumber === sixthNumber ) {
        secondNumber =  Math.abs(nums[Math.floor(Math.random()*nums.length)]);
        fourthNumber = Math.abs(nums[Math.floor(Math.random()*nums.length)]);
      }

      while (fourthNumber == sixthNumber) {
        secondNumber = nums[Math.floor(Math.random()*nums.length)];
      }

      let result1 = gcd(firstNumber,fifthNumber); 
      let result2 = gcd(firstNumber,thirdNumber);
      let result3 = gcd(thirdNumber,fifthNumber); 
      let result4 = [result1, result2, result3];
      let result5 = [secondNumber, fourthNumber, sixthNumber]; 
      let result6 = Math.min(...result4);
      let result7 = Math.min(...result5);
      let result8 = firstNumber/result6;
      let result9 = secondNumber - result7;
      let result10 = thirdNumber/result6;
      let result11 = fourthNumber - result7;
      let result12 = fifthNumber/result6;
      let result13 = sixthNumber - result7;
      let sign1 = "+";
      let sign2 = "+";
      let ex1 = `x^{${result9}}`;
      let ex2 = `x^{${result11}}`;
      let ex3 = `x^{${result13}}`;

      if (result9 == 0) {
        ex1 = " ";
      }

      if (result11 == 0) {
        ex2 = " ";
      }

      if (result13 == 0) {
        ex3 = " ";
      }

      if (result9 == 1) {
        ex1 = "x";
      }

      if (result11 == 1) {
        ex2 = "x";
      }

      if (result13 == 1) {
        ex3 = "x";
      }
    
      if (thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        result10 = Math.abs(result10);
        sign1 = "-";
      }

      if (fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        result12 = Math.abs(result12);
        sign2 = "-";
      }

      if (result6 == 1) {
        result6 = " ";
      }

      if(result8 == -1 && ex1 !== " ") {
        result8 = "-"
      }

      if (result8 == 1 && ex1 !== " ") {
        result8 = " ";
      }

      if (result10 == 1 && ex2 !== " ") {
        result10 = " ";
      }

      if (result12 == 1 && ex3 !== " ") {
        result12 = " ";
      }

      if(result12 == 1 && ex3 == " ") {
        result12 = "1"
      }

      if(result10 == 1 && ex2 == " ") {
        result10 = "1"
      }

      if(result8 == 1 && ex1 == " ") {
        result8 = "1"
      }

      

      generatedProblem.push({
        key: `${step}-pfa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6,
        result7: result7,
        result8: result8,
        result9: result9,
        result10: result10,
        result11: result11,
        result12: result12,
        result13: result13,
        sign1: sign1,
        sign2: sign2,
        ex1: ex1,
        ex2: ex2,
        ex3: ex3
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let equationToDisplay = `$ ${equation.firstNumber}x^{${equation.secondNumber}} ${equation.sign1} ${equation.thirdNumber}x^{${equation.fourthNumber}} ${equation.sign2} ${equation.fifthNumber}x^{${equation.sixthNumber}} $`

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$ ${equation.result6}x^{${equation.result7}} (${equation.result8} ${equation.ex1} ${equation.sign1} ${equation.result10} ${equation.ex2} ${equation.sign2} ${equation.result12} ${equation.ex3} ) $`,
    }
  }

  function helpText() {
    return `
      $$ -8x^{2} + 24x^{3} - 2x^{11} $$    
      \
      $$ = -4 \\cdot 2 \\cdot x^{2} + 12 \\cdot 2 \\cdot x^{3} - 1 \\cdot 2 \\cdot x^{11} $$
      \
      $$ = -4 \\cdot 2 \\cdot x^{2} \\cdot x^{0} + 12 \\cdot 2 \\cdot x^{2} \\cdot x^{1} - 1 \\cdot 2 \\cdot x^{2} \\cdot x^{9} $$
      \
      $$ = -4 \\cdot \\underline{2 \\cdot x^{2}} \\cdot x^{0} + 12 \\cdot \\underline{2 \\cdot x^{2}} \\cdot x^{1} - 1 \\cdot \\underline{2 \\cdot x^{2}} \\cdot x^{9} $$
      \
      $$ = \\underline{2 \\cdot x^{2}} \\cdot (-4 \\cdot x^{0} + 12 \\cdot x^{1} - 1 \\cdot x^{9}) $$
      \
      $$ = \\underline{2x^{2}} \\cdot (-4 \\cdot 1 + 12 \\cdot x - 1 \\cdot x^{9}) $$
      \
      $$ = \\underline{2x^{2}} (-4 + 12x - x^{9}) $$
      \
      $$ = 2x^{2} (-4 + 12x - x^{9}) $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Factoring"}
        instructions={"Pull out the factor with the biggest coefficient and biggest exponent possible."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Factoring Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
