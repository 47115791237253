import React from 'react';
import Container from 'react-bootstrap/Container';

export default function About() {
  return (
    <Container>
      <h1 style={{ color: "#0d6efd" }}>About</h1>
      Unlock endless math practice with answers and step-by-step guidance across algebra, calculus, arithmetic, and trigonometry, empowering educators, students, and homeschoolers to master fundamental mathematical skills effortlessly.
    </Container>
  )
}
