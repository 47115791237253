import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialFoil() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber + secondNumber;
      let result2 = firstNumber*secondNumber;
      let sign1 = "+"
      let sign2 = "+"
      let sign3 = "+"
      let sign4 = "+"

      if(firstNumber < 0) {
        firstNumber = Math.abs(firstNumber);
        sign1 = "-";
    }
    if(secondNumber < 0) {
      secondNumber = Math.abs(secondNumber);
      sign2 = "-";
    }       

      if(result1 < 0) {
          result1 = Math.abs(result1);
          sign3 = "-";
      }
      if(result2 < 0) {
        result2 = Math.abs(result2);
        sign4 = "-";
      }       

      generatedProblem.push({
        key: `${step}-qf`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        sign4: sign4
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1;
    let answerToDisplay = `$x^${equation.exponent} ${equation.sign3} ${equation.result1}x ${equation.sign4} ${equation.result2}$`
    if(middleTerm === 1) {
      answerToDisplay = `$x^${equation.exponent} ${equation.sign3} x ${equation.sign4} ${equation.result2}$`
    }
    else if(middleTerm === 0) {
      answerToDisplay = `$x^${equation.exponent} ${equation.sign4} ${equation.result2}$`
    }

    return {
      key: equation.key,
      equation: `$(x ${equation.sign1} ${equation.firstNumber})(x ${equation.sign2} ${equation.secondNumber})$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
      $$ (x - 7)(x + 9) $$    
      \
      $$ \\textbf{F}irst = (x) \\cdot (x) $$
      \
      $$ \\textbf{O}utside =  (x) \\cdot (9) $$
      \
      $$ \\textbf{I}nside = (-7) \\cdot (x) $$
      \
      $$ \\textbf{L}ast = (-7) \\cdot (9) $$
      \
      $$ \\textbf{F} + \\textbf{O} + \\textbf{I} + \\textbf{L} $$
      \
      $$ (x) \\cdot (x) + (x) \\cdot (9) + (-7) \\cdot (x) + (-7) \\cdot (9) $$
      \
      $$ (x^{2}) + (9x) + (-7x) + (-63) $$
      \
      $$ x^{2} + 9x - 7x - 63 $$
      \
      $$ x^{2} + (9 - 7) \\cdot x - 63 $$
      \
      $$ x^{2} + (2) \\cdot x - 63 $$
      \
      $$ x^{2} + 2x - 63 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Foil"}
        instructions={"Distribute the factors."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Foil Help", helpTextFunc: helpText}}
      />
    </div>
  );
}