import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function CalculusRelatedRates() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirdNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(secondNumber === firstNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    while(secondNumber === thirdNumber) {
        secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }
   
    generatedProblem.push({
      key: "1-crr",
      result: (
        <span>
        <br/>
        <span>{`$ \\dfrac{dx}{dt} = \\dot{x} = \\dfrac{z\\dot{z} - y\\dot{y}}{x} $`}</span>
        <br/>
        <br/>
        <span>{`$ \\dfrac{dy}{dt} = \\dot{y} = \\dfrac{z\\dot{z} - x\\dot{x}}{y}  $`}</span>
        <br/>
        <br/>
        <span>{`$ \\dfrac{dz}{dt} = \\dot{z} = \\dfrac{x\\dot{x} + y\\dot{y}}{z}  $`}</span>
        <br/>
        </span>
      ),
      format: `$ z^{2} = x^{2} + y^{2} $`
    });

    generatedProblem.push({
      key: "2-crr",
      firstNumber: firstNumber,
      result: (
        <span>
        <br/>
        <span>{`$ \\dfrac{dx}{dt} = \\dot{x} = \\dfrac{\\dot{z} - ${firstNumber}x\\dot{y}}{${firstNumber}y} $`}</span>
        <br/>
        <br/>
        <span>{`$ \\dfrac{dy}{dt} = \\dot{y} = \\dfrac{\\dot{z} - ${firstNumber}x\\dot{y}}{${firstNumber}x}  $`}</span>
        <br/>
        <br/>
        <span>{`$ \\dfrac{dz}{dt} = \\dot{z} = ${firstNumber}(\\dot{x}y + x\\dot{y}) $`}</span>
        <br/>
        </span>
      ),
      format: `$ z = ${firstNumber}xy $`
    });

    generatedProblem.push({
        key: "3-crr",
        secondNumber: secondNumber,
        result: (
          <span>
          <br/>
          <span>{`$ \\dfrac{dx}{dt} = \\dot{x} = \\dfrac{\\dot{z}}{${secondNumber}cos(x + y)} - \\dot{y} $`}</span>
          <br/>
          <br/>
          <span>{`$ \\dfrac{dy}{dt} = \\dot{y} = \\dfrac{\\dot{z}}{${secondNumber}cos(x + y)} - \\dot{x}  $`}</span>
          <br/>
          <br/>
          <span>{`$ \\dfrac{dz}{dt} = \\dot{z} = ${secondNumber}cos(x + y)(\\dot{x} + \\dot{y}) $`}</span>
          <br/>
          </span>
        ),
        format: `$ z = ${secondNumber}sin(x + y) $`
      });

      generatedProblem.push({
        key: "4-crr",
        thirdNumber: thirdNumber,
        result: (
          <span>
          <br/>
          <span>{`$ \\dfrac{dx}{dt} = \\dot{x} = \\dfrac{${thirdNumber}\\dot{y}x - \\dot{z}x^{2}}{${thirdNumber}y} $`}</span>
          <br/>
          <br/>
          <span>{`$ \\dfrac{dy}{dt} = \\dot{y} = \\dfrac{\\dot{z}x^{2} - ${thirdNumber}\\dot{y}x}{${thirdNumber}x} $`}</span>
          <br/>
          <br/>
          <span>{`$ \\dfrac{dz}{dt} = \\dot{z} = \\dfrac{${thirdNumber}(\\dot{y}x - y\\dot{x})}{x^{2}} $`}</span>
          <br/>
          </span>
        ),
        format: `$ z = \\dfrac{${thirdNumber}y}{x} $`
      });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function helpText() {
    return `
        $$ z^{3} = x Ln(y) $$
        \
        $$ \\dfrac{d}{dt} \\Bigl( z^{3} = x Ln(y) \\Bigr) $$
        \
        $$ \\dfrac{d}{dt} \\Bigl( z^{3} \\Bigr) = \\dfrac{d}{dt} \\Bigl( x Ln(y) \\Bigr) $$
        \
        $$ \\dfrac{d(z^{3})}{dz} \\cdot \\dfrac{dz}{dt} = \\dfrac{d}{dt} \\Bigl( x \\Bigr) \\cdot Ln(y) + x \\cdot \\dfrac{d}{dt} \\Bigl( Ln(y) \\Bigr) $$
        \
        $$ 3z^{2} \\cdot \\dot{z} = \\dfrac{d(x)}{dx} \\cdot \\dfrac{dx}{dt} \\cdot Ln(y) + x \\cdot \\dfrac{d(Ln(y))}{dy} \\cdot \\dfrac{dy}{dt} $$
        \
        $$ 3z^{2} \\cdot \\dot{z} = 1 \\cdot \\dot{x} \\cdot Ln(y) + x \\cdot \\dfrac{1}{y} \\cdot \\dot{y} $$
        \
        $$ 3z^{2} \\dot{z} = \\dot{x} Ln(y) + \\dfrac{x \\dot{y}}{y} $$
        \
        $$ - \\dfrac{x \\dot{y}}{y} \\hspace{7mm} - \\dfrac{x \\dot{y}}{y} $$
        \
        $$ 3z^{2} \\dot{z} - \\dfrac{x \\dot{y}}{y} = \\dot{x} Ln(y) $$
        \
        $$ \\dfrac{3z^{2} \\dot{z} - \\dfrac{x \\dot{y}}{y} = \\dot{x} Ln(y)}{Ln(y)} $$
        \
        $$ \\dfrac{3z^{2} \\dot{z} - \\dfrac{x \\dot{y}}{y}}{Ln(y)} = \\dfrac{\\dot{x} Ln(y)}{Ln(y)} $$
        \
        $$ \\dot{x} = \\dfrac{3z^{2} \\dot{z} - \\dfrac{x \\dot{y}}{y}}{Ln(y)} $$
        \
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Related Rates"}
        instructions={"Compute and isolate $ \\dot{x}, \\; \\dot{y}, \\; \\dot{z} $ using Implicit Differentiation."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Related Rates Help", helpTextFunc: helpText}}
      />
    </div>
  );
}