import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticFactorialB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [0, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      while (firstNumber <= secondNumber) {
        firstNumber = nums[Math.floor(Math.random()*nums.length)];
        secondNumber = nums[Math.floor(Math.random()*nums.length)];
      }
      let result1 = factorialize(firstNumber);
      let result2 = factorialize(secondNumber);
      let result3 = result1/result2;

      generatedProblem.push({
        key: `${step}-afb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result3: result3
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function factorialize(num) {
    if (num === 0 || num === 1) {
      return 1;
    }

    let result = num;
    while (num > 1) {
      num--;
      result *= num;
    }

    return result
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}!}{${equation.secondNumber}!}$`,
      answer: `$${equation.result3}$`
    }
  }

  function helpText() {
    return `
      $$ \\frac{7!}{4!} $$    
      \
      $$ = \\frac{7 \\cdot 6 \\cdot 5 \\cdot 4 \\cdot 3 \\cdot 2 \\cdot 1}{4 \\cdot 3 \\cdot 2 \\cdot 1} $$
      \
      $$ = \\frac{7 \\cdot 6 \\cdot 5}{1} \\cdot \\frac{4 \\cdot 3 \\cdot 2 \\cdot 1}{4 \\cdot 3 \\cdot 2 \\cdot 1} $$
      \
      $$ = \\frac{210}{1} \\cdot \\frac{1}{1} $$
      \
      $$ = 210 \\cdot 1 $$
      \
      $$ = 210 $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Factorial B"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Factorial B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}


