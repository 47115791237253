import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogExpTwoStepB() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,secondNumber);
      let result2 = secondNumber/thirdNumber;
      let result3 = secondNumber*thirdNumber;
      let sign = (step % 2 === 0) ? "*" : "/";

      generatedProblem.push({
        key: `${step}-etsb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let formattedDisplay;
    let answer;

    if(equation.sign === "*") {
      formattedDisplay = `$${equation.firstNumber}^{${equation.thirdNumber}x} = ${equation.result1}$`
      answer = equation.result2
    } 
    else {
      formattedDisplay = `$${equation.firstNumber}^{\\tfrac{x}{${equation.thirdNumber}}} = ${equation.result1}$`
      answer = equation.result3
    }

    return {
      key: equation.key,
      equation: formattedDisplay,
      answer: `$x = ${Math.round(answer*100)/100}$`,
    }
  }

  function helpText() {
    return `
    $$ 2^{4x} = 32 $$
    \
      $$ 2^{4x} = 2^{5}  $$    
      \
      $$ 4x = 5 $$
      \
      $$ \\frac{4x = 5}{4} $$
      \
      $$ \\frac{4x}{4} = \\frac{5}{4} $$
      \
      $$ x = \\frac{5}{4} = 1.25 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Exponential Two Step B"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Exponential Two Step B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}