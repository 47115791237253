import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticMultiplicationA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [0, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber*secondNumber;

      generatedProblem.push({
        key: `${step}-ama`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        sign: "*"
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber} \\cdot ${equation.secondNumber}$`,
      answer: `$${equation.result}$`
    }
  }

  function helpText() {
    return `$$
    
      {\\LARGE 3 \\cdot 4 = 4 + 4 + 4 = 12} $$
            
      \
    
      $$ {\\LARGE 4 \\cdot 3 = 3 + 3 + 3 + 3 = 12}
      
    $$`
  }

  return (
    <div>
      
      <EquationLayout
      title={"Arithmetic Multiplication A"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Multiplication A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}