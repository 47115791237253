import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function RationalQuadratic() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = -1*(firstNumber + secondNumber);
      let result2 = -firstNumber*secondNumber;
      let sign1 = "+"

      if(result1 < 0) {
          result1 = Math.abs(result1);
          sign1 = "-";
      }
       
      generatedProblem.push({
        key: `${step}-rq`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        sign1: sign1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1
    let equationToDisplay = `$\\dfrac{${equation.result2}}{x ${equation.sign1} ${equation.result1}} = x$`
    if(middleTerm === 1) {
      equationToDisplay = `$\\dfrac{${equation.result2}}{x ${equation.sign1} ${equation.result1}} = x$`
    }
    else if(middleTerm === 0) {
      equationToDisplay = `$\\dfrac{${equation.result2}}{x} = x`
    }

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$x = ${equation.firstNumber}, ${equation.secondNumber}$`,
    }
  }

  function helpText() {
    return `
      $$ \\frac{36}{x - 5} = x  $$    
      \
      $$ (x - 5) \\cdot \\left( \\frac{36}{x - 5} = x \\right) $$
      \
      $$ \\frac{36(x - 5)}{x - 5} = x(x - 5) $$
      \
      $$ \\frac{36 \\cdot (x - 5)}{1 \\cdot (x - 5)} = x(x - 5) $$
      \
      $$ \\frac{36 \\cdot (x - 5)}{1 \\cdot (x - 5)} = x(x - 5) $$
      \
      $$ \\frac{36}{1} = x(x - 5) $$
      \
      $$ 36 = x(x - 5) $$
      \
      $$ 36 = x^{2} - 5x $$
      \
      $$ -36 \\hspace{7mm} -36 $$
      \
      $$ 0 = x^{2} - 5x - 36 $$
      \
      $$ 0 = (x - 9)(x + 4) $$
      \
      $$ x - 9 = 0 $$
      \
      $$ +9 \\hspace{7mm} +9 $$
      \
      $$ x = 9 $$
      \
      $$ x + 4 = 0 $$
      \
      $$ -4 \\hspace{7mm} -4 $$
      \
      $$ x = -4 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Rational Quadratic"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quadratic Help", helpTextFunc: helpText}}
      />
    </div>
  );
}