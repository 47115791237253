import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticMultiplyingFractionsA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8];
    const practice_problems_num = 8;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*fourthNumber*sixthNumber-secondNumber*thirdNumber*fifthNumber;
      let result2 = secondNumber*thirdNumber*sixthNumber;

      generatedProblem.push({
        key: `${step}-aafb`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}}{${equation.secondNumber}} \\div \\dfrac{${equation.thirdNumber}}{${equation.fourthNumber}} - \\dfrac{${equation.fifthNumber}}{${equation.sixthNumber}}$`,
      answer: `$${displayFraction(equation.result1, equation.result2)}$`
    }
  }

  function helpText() {
    return `$$
    
      { \\dfrac{8}{3} \\div \\dfrac{2}{7} - \\dfrac{5}{4}} $$
            
      \
    
      $$ {= \\left(\\dfrac{8}{3} \\div \\dfrac{2}{7} \\right) - \\dfrac{5}{4}} $$

      \

      $$ {= \\left(\\dfrac{8}{3} \\cdot \\dfrac{7}{2} \\right) - \\dfrac{5}{4}} $$

      \

      $$ {= \\left(\\dfrac{8 \\cdot 7}{3 \\cdot 2} \\right) - \\dfrac{5}{4}} $$

      \

      $$ {= \\dfrac{56}{6} - \\dfrac{5}{4}} $$

      \

      $$ {= \\dfrac{56 \\cdot 4}{6 \\cdot 4} - \\dfrac{5 \\cdot 6}{4 \\cdot 6}} $$

      \
      
      $$ {= \\dfrac{224}{24} - \\dfrac{30}{24}} $$

      \

      $$ {= \\dfrac{224 - 30}{24}} $$

      \

      $$ {= \\dfrac{194}{24}}

     $$`
  }

  return (
    <div>
      

      <EquationLayout
      title={"Arithmetic Associative Fractions B"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Associative Fractions B Help", helpTextFunc: helpText}}
      />
    </div>
  );
}