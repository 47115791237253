import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { generateRandomAngle } from '../../../../utils/mathHelpers';
import genericTrinagle from '../images/generic-triangle.png';

export default function TrigonometryGenericTriangles() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = generateRandomAngle();
      let result2 = 3.14159265359*result1/180;
      let result3 = Math.sqrt(Math.pow(firstNumber,2) + Math.pow(secondNumber,2) - 2*firstNumber*secondNumber*Math.cos(result2));
      let result4 = (180*Math.acos((Math.pow(firstNumber,2)-Math.pow(secondNumber,2)-Math.pow(result3,2))/(-2*secondNumber*result3)))/3.14159265359;
      let result5 = 180 - result4 - result1;
      let result6 = firstNumber + secondNumber + result3;
      let result7 = result6/2;
      let result8 = Math.sqrt(result7*(result7 - firstNumber)*(result7 - secondNumber)*(result7 - result3));
     
      generatedProblem.push({
        key: `${step}-trt`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        result6: result6,
        result7: result7,
        result8: result8
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function roundNumber(number) {
    return Math.round(number*100)/100
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$C = ${roundNumber(equation.result3)}$`}</span>
      <br/>
      <span>{`$a = ${roundNumber(equation.result4)}^\\circ$`}</span>
      <br/>
      <span>{`$b = ${roundNumber(equation.result5)}^\\circ$`}</span>
      <br/>
      <span>{`$Area = ${roundNumber(equation.result8)}$`}</span>
      <br/>
      <span>{`$Perimeter = ${roundNumber(equation.result6)}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$A = ${equation.firstNumber}, B = ${equation.secondNumber}, c = ${equation.result1}^\\circ$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
    $$ \\text{Law of Cosines} $$
    \
    $$ A^{2} = B^{2} + C^{2} - (2BC \\cdot cos(a)) $$
    \
    $$ B^{2} = A^{2} + C^{2} - (2AC \\cdot cos(b)) $$
    \
    $$ C^{2} = A^{2} + B^{2} - (2AB \\cdot cos(c)) $$
    \
    $$ \\text{Law of Sines} $$
    \
    $$ \\frac{sin(a)}{A} = \\frac{sin(b)}{B} = \\frac{sin(c)}{C} $$
    \
    $$ \\text{Pythagorean Theorem} $$
    \
    $$ A^{2} + B^{2} = H^{2} $$
    \
      $$ \\text{Perimeter} = A + B + C $$
      \
      $$ S = \\frac{\\text{Perimeter}}{2} $$
      \
      $$ \\text{Area of a Triangle} = \\sqrt{S(S - A)(S - B)(S - C)} $$
      \
      $$ a + b + c = 180^{\\circ} $$ 
      `
  }

  return (
    <div>
     
      <div style={{marginTop: '20px', marginBottom: '20px'}}>
        <img src={genericTrinagle} alt="Right Trinagle" height={200} width={250}/>
      </div>

      <EquationLayout
        title={"Trigonometry Generic Triangle"}
        instructions={"Compute all sides, angles, area, and perimeter."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Generic Triangles Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
