import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogExpSubtract() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,secondNumber);
      let result2 = secondNumber + thirdNumber;
      let sign1 = "";
      
      if(result2 < 0) {
          result2 = Math.abs(result2);
          sign1 = "-";
      }
      generatedProblem.push({
        key: `${step}-es`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2,
        sign1: sign1,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.firstNumber}^{x}}{${equation.firstNumber}^{${equation.thirdNumber}}}= ${equation.result1}$`,
      answer: `$x = ${equation.result2}$`
    }
  }

  function helpText() {
    return `
    $$ \\frac{3^{x}}{3^{4}} = 9 $$
    \
      $$ 3^{x - 4} = 9 $$    
      \
      $$ 3^{x - 4} = 3^{2} $$
      \
      $$ x - 4 = 2 $$
      \
      $$ +4 \\hspace{7mm} +4 $$
      \
      $$ x = 6 $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Exponential Subtract"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Exponential Subtract Help", helpTextFunc: helpText}}
      />
    </div>
  );
}