import { roundNumber } from './mathHelpers'

export function funcMappings() {
  return {
    ...squared(),
    ...squareRoot(),
    ...twoN(),
    ...nMinusTwo(),
    ...nPlusTwo(),
    ...twoMinusN(),
    ...twoDividedByN(),
    ...squaredMinus2N(),
    ...twoPowerN(),
  }
}

export function summation(a, b, func) {
  let result = 0
  for (let x = a; x <= b; x++) {
    result += func(x)
  }

  return roundNumber(result)
}

export function squared() {
  return {
    squared: {
      display: `n^{2}`,
      computed: (n) => Math.pow(n,2)
    }
  }
}

export function squareRoot() {
  return {
    squareRoot: {
      display: `\\sqrt{n}`,
      computed: (n) => Math.sqrt(n)
    }
  }
}

export function twoN() {
  return {
    twoN : {
      display: `2n`,
      computed: (n) =>  2 * n
    }
  }
}

export function nMinusTwo() {
  return {
    nMinusTwo: {
      display: `n - 2`,
      computed: (n) => n - 2
    }
  }
}

export function nPlusTwo() {
  return {
    nPlusTwo: {
      display: `n + 2`,
      computed: (n) => n + 2
    }
  }
}

export function twoMinusN() {
  return {
    twoMinusN: {
      display: `2 - n`,
      computed: (n) => 2 - n
    }
  }
}

export function twoDividedByN() {
  return {
    twoDividedByN: {
      display: `\\dfrac{2}{n}`,
      computed: (n) => 2/n
    }
  }
}

export function squaredMinus2N() {
  return {
    squaredMinus2N: {
      display: `n^{2} - 2n`,
      computed: (n) => Math.pow(n, 2) - (2 * n)
    }
  }
}

export function twoPowerN() {
  return {
    twoPowerN: {
      display: `2^{n}`,
      computed: (n) => Math.pow(2, n)
    }
  }
}
