import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialCubicFunctions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, -2, -3, -4, -5];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let eighthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = firstNumber*Math.pow(fourthNumber,3) + secondNumber*Math.pow(fourthNumber,2) + thirdNumber*fourthNumber + eighthNumber;
      let seventhNumber = firstNumber*Math.pow(fifthNumber,3) + secondNumber*Math.pow(fifthNumber,2) + thirdNumber*fifthNumber + eighthNumber
     
      let sign1 = "+";
      let sign2 = "+";
      let sign3 = "+";

      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign1 = "-";
      }

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign2 = "-";
      }

      if(eighthNumber < 0) {
        eighthNumber = Math.abs(eighthNumber);
        sign3 = "-";
      }

      generatedProblem.push({
        key: `${step}-lf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eighthNumber: eighthNumber,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
        <span>{`$y(x) = ${equation.firstNumber} x^{3} ${equation.sign1} ${equation.secondNumber} x^{2} ${equation.sign2} ${equation.thirdNumber} x ${equation.sign3} ${equation.eighthNumber} \\; \\; | \\; \\; y(${equation.fourthNumber}), \\; y(${equation.fifthNumber})$`}</span>
        <br/>
        </span>

      ),

      answer: (
        <span>
        <span>{`$y(${equation.fourthNumber}) = ${equation.sixthNumber}$`}</span>
        <br/>
        <span>{`$y(${equation.fifthNumber}) = ${equation.seventhNumber}$`}</span>
        <br/>
        <span>{`$Domain = (-\\infty, \\infty)$`}</span>
        <br/>
        <span>{`$Range = (-\\infty, \\infty)$`}</span>
        <br/>
        </span>
      )
    };
  }

  function helpText() {
    return `
      $$ y(x) = 2x^{3} - 3x^{2} + 5x + 4 \\hspace{2mm} | \\hspace{2mm} y(-2), \\hspace{1mm} y(1) $$    
      \
      $$ y(-2) = 2(-2)^{3} - 3(-2)^{2} + 5(-2) + 4 $$
      \
      $$ y(-2) = 2 \\cdot (-8) - (3 \\cdot 4) + (-10) + 4 $$
      \
      $$ y(-2) = - 16 - 12 - 10 + 4 $$
      \
      $$ y(-2) = -34 $$
      \
      $$ y(1) = 2(1)^{3} - 3(1)^{2} + 5(1) + 4 $$
      \
      $$ y(1) = 2 \\cdot 1 - 3 \\cdot 1 + 5 + 4 $$
      \
      $$ y(1) = 2 - 3 + 5 + 4 $$
      \
      $$ y(1) = 8 $$
      \
      $$ Domain = (-\\infty, \\infty) $$
      \
      This Domain is true for all Polynomials, including all Cubics.
      \
      All Polynomials and therefore all Cubics go all the way to the left and all the way to the right.
      \
      $$ Range = (-\\infty, \\infty) $$
      \
      This Range is true for all Cubics.
      \
      All Cubics go all the way up and all the way down.
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Polynomial Cubic Functions"}
        instructions={"Plug in the 2 values. Compute Domain and Range."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Cubic Functions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}