import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Outlet, NavLink } from "react-router-dom";

export default function ContainerLayout() {
  return (
    <Container>
      <div>
        {/* Problem Navigation */}
        <div>
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/arithmetic">Arithmetic</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/linear">Linear</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/polynomial">Polynomial</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/rational">Rational</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/exp_log">Exp/Log</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/trigonometry">Trigonometry</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/vector_matrix">Vector/Matrix</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/calculus">Calculus</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/statistics">Statistics</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="/exercises/advanced">Advanced</Nav.Link>
            </Nav.Item>
          </Nav>
          <br />

          {/* Content */}
          <Outlet />
        </div>
      </div>
    </Container>
  )
}
