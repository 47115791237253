import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { funcMappings } from '../../../../utils/calculusHelper';
import { randomNumFromSet } from '../../../../utils/mathHelpers';

export default function CalculusQuotientRule() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [3, 4, 5, 6, 7, 8 ,9];
    const funcMappingsHash = funcMappings()
    const funMappingKeys = Object.keys(funcMappingsHash)
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let randomFuncName = randomNumFromSet(funMappingKeys);
      let secondRandomFuncName = randomNumFromSet(funMappingKeys);
      while (randomFuncName == secondRandomFuncName) {
        secondRandomFuncName = randomNumFromSet(funMappingKeys);
      }

      let randomFuncObj = funcMappingsHash[randomFuncName];
      let secondRandomFuncObj = funcMappingsHash[secondRandomFuncName];
      const { normalFunc, primeFunc, params } = randomFuncObj;
      const { normalFunc: secondNormalFunc, primeFunc: secondPrimeFunc, params: secondParams } = secondRandomFuncObj;
      let paramsForFunc = [];
      let paramsForSecondFunc = [];

      let x = 0
      while (x < params) {
        x = x + 1;
        paramsForFunc.push(randomNumFromSet(nums))
      }

      let y = 0
      while (y < secondParams) {
        y = y + 1;
        paramsForSecondFunc.push(randomNumFromSet(nums))
      }

      generatedProblem.push({
        key: `${step}-cqr`,
        firstNormalExpression: normalFunc(paramsForFunc),
        secondNormalExpression: secondNormalFunc(paramsForSecondFunc),
        firstPrimeExpression: primeFunc(paramsForFunc),
        secondPrimeExpression: secondPrimeFunc(paramsForSecondFunc),
        name: randomFuncName
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{d}{dx} \\left( \\dfrac{${equation.firstNormalExpression}}{${equation.secondNormalExpression}} \\right) $`,
      answer: `$\\dfrac{${equation.firstPrimeExpression} \\cdot ${equation.secondNormalExpression} - ${equation.firstNormalExpression} \\cdot ${equation.secondPrimeExpression}}{(${equation.secondNormalExpression})^2}$`
    }
  }

  function helpText() {
    return `
        $$ \\frac{d}{dx} \\left( \\frac{\\text{Top}(x)}{\\text{Bottom}(x)} \\right) = \\frac{d}{dx} \\left( \\frac{T(x)}{B(x)} \\right) = \\frac{d}{dx} \\left( \\frac{T}{B} \\right) $$
        \
        $$ = \\frac{\\frac{d}{dx} \\left( T \\right) \\cdot B - T \\cdot \\frac{d}{dx} \\left( B \\right)}{B^{2}} $$
        \
        $$ = \\frac{T' \\cdot B - T \\cdot B'}{B^{2}} $$
        \
        $$ \\frac{d}{dx} \\left( \\frac{sin(x)}{4 x^{3}} \\right) $$
        \
        $$ = \\frac{\\frac{d}{dx} \\left( sin(x) \\right) \\cdot 4 x^{3} - sin(x) \\cdot \\frac{d}{dx} \\left( 4 x^{3} \\right)}{(4 x^{3})^{2}} $$
        \
        $$ = \\frac{ cos(x) \\cdot 4 x^{3} - sin(x) \\cdot 12 x^{2}}{16 x^{6}} $$
        \
        $$ = \\frac{ 4 x^{2} \\cdot (x cos(x) - 3 sin(x))}{4 x^{2} \\cdot (4 x^{4})} $$
        \
        $$ = \\frac{xcos(x) - 3sin(x)}{4 x^{4}} $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Calculus Quotient Rule"}
        instructions={"Compute the derivative."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quotient Rule Help", helpTextFunc: helpText}}
      />
    </div>
  );
}