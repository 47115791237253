import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearFunctions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 6;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = firstNumber*thirdNumber+secondNumber;
      let sixthNumber = firstNumber*fourthNumber+secondNumber;
      let sign = "+";

      if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-lf`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        sign: sign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: (
        <span>
        <span>{`$y(x) = ${equation.firstNumber} x ${equation.sign} ${equation.secondNumber} \\; \\; | \\; \\; y(${equation.thirdNumber}), \\; y(${equation.fourthNumber})$`}</span>
        <br/>
        </span>

      ),

      answer: (
        <span>
        <span>{`$y(${equation.thirdNumber}) = ${equation.fifthNumber}$`}</span>
        <br/>
        <span>{`$y(${equation.fourthNumber}) = ${equation.sixthNumber}$`}</span>
        <br/>
        <span>{`$Domain = (-\\infty, \\infty)$`}</span>
        <br/>
        <span>{`$Range = (-\\infty, \\infty)$`}</span>
        <br/>
        </span>
      )
    };
  }

  function helpText() {
    return `
      $$ y(x) = -2x - 9 \\hspace{2mm} | \\hspace{2mm} y(-7), y(3) $$    
      \
      $$ y(-7) = -2(-7) - 9 $$
      \
      $$ y(-7) = -2 \\cdot (-7) - 9 $$
      \
      $$ y(-7) = 14 - 9 $$
      \
      $$ y(-7) = 5 $$
      \
      $$ y(x) = -2x - 9 $$
      \
      $$ y(3) = -2(3) - 9 $$
      \
      $$ y(3) = -2 \\cdot 3 - 9 $$
      \
      $$ y(3) = -6 - 9 $$
      \
      $$ y(3) = -15 $$
      \
      $$ Domain = all \\hspace{1mm} real \\hspace{1mm} numbers = \\mathbb{R} = (-\\infty, \\infty) $$
      \
        This is true for all straight lines.
      \
        They exist all the way to the left and all the way to the right.
      \
      $$ Range =  all \\hspace{1mm} real \\hspace{1mm} numbers = \\mathbb{R} = (-\\infty, \\infty) $$
      \
        This is true for all straight lines that are not flat/constant lines.
        \
        Non flat lines go all the way up and all the way down.
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Linear Functions"}
        instructions={"Plug in the 2 values. Compute Domain and Range."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Functions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}