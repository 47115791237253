import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function CalculusLimits() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);
  const [exampleFunctionToDisplay, setExampleFunctionToDisplay] = useState("");

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random() * nums.length)];
    let secondNumber = nums[Math.floor(Math.random() * nums.length)];
    let thirdNumber = nums[Math.floor(Math.random() * nums.length)];
    let result1 = secondNumber - firstNumber;
    let result2 = firstNumber * secondNumber;
    let result3 = secondNumber + thirdNumber;
    let result4 = secondNumber * thirdNumber;
    let result5 = firstNumber + secondNumber;
    let result6 = secondNumber - thirdNumber;
    let sign1 = "+";

    while (thirdNumber == secondNumber) {
      secondNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    if (result1 < 0) {
      result1 = Math.abs(result1);
      sign1 = "-";
    }

    let middleTerm = result1;
    let equationToDisplay = `x^2 ${sign1} ${result1}x - ${result2}`;
    if (middleTerm === 1) {
      equationToDisplay = `x^2 ${sign1} x - ${result2}`;
    } else if (middleTerm === 0) {
      equationToDisplay = `x^2 - ${result2}`;
    }

    setExampleFunctionToDisplay(
      `$ y(x) = \\dfrac{${equationToDisplay}}{x^{2} + ${secondNumber + thirdNumber}x + ${secondNumber * thirdNumber}} $`
    );

    generatedProblem.push({
      key: "2-cl",
      result: `$ 0 $`,
      format: <span>{`$ y(${firstNumber}) $`}</span>,
    });

    generatedProblem.push({
      key: "3-cl",
      result: `$ \\text{Undefined} $`,
      format: <span>{`$ y(- ${secondNumber}) $`}</span>,
    });

    generatedProblem.push({
      key: "4-cl",
      result: `$ \\text{Undefined} $`,
      format: <span>{`$ y(- ${thirdNumber}) $`}</span>,
    });

    generatedProblem.push({
      key: "5-cl",
      result: `$ 0 $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; ${firstNumber}^{-}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "8-cl",
      result: `$ ${displayFraction(result5, result6)} $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${secondNumber}^{-}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "11-cl",
      result: `$ + \\infty $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${thirdNumber}^{-}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "6-cl",
      result: `$ 0 $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; ${firstNumber}^{+}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "9-cl",
      result: `$ ${displayFraction(result5, result6)} $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${secondNumber}^{+}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "12-cl",
      result: `$ - \\infty $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${thirdNumber}^{+}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "7-cl",
      result: `$ 0 $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; ${firstNumber}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "10-cl",
      result: `$ ${displayFraction(result5, result6)} $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${secondNumber}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "13-cl",
      result: `$ \\text{Does Not Exist} $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - ${thirdNumber}} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "1-cl",
      result: `$ ${displayFraction(-firstNumber, thirdNumber)} $`,
      format: <span>{`$ y(0) $`}</span>,
    });

    generatedProblem.push({
      key: "14-cl",
      result: `$ 1 $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; - \\infty} y(x) $`}</span>,
    });

    generatedProblem.push({
      key: "15-cl",
      result: `$ 1 $`,
      format: <span>{`$ \\displaystyle \\lim_{x \\rightarrow \\; + \\infty} y(x) $`}</span>,
    });

    let formattedProblemList = [];
    generatedProblem.forEach((problem) => {
      formattedProblemList.push(equationFormatter(problem));
    });
    setFormattedProblems(formattedProblemList);
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result,
    };
  }

  function helpText() {
    return `
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) $$
        \
        $$ \\text{Put your finger on the left side of the graph.} $$
        \
        $$ \\text{Approach} \\; a \\; \\text{as you move your finger to the right.} $$
        \
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x) $$
        \
        $$ \\text{Put your finger on the right side of the graph.} $$
        \
        $$ \\text{Approach} \\; a \\; \\text{as you move your finger to the left.}  $$
        \
        $$ \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) $$
        \
        $$ \\text{If} \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) = \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x) = c, \\text{then} \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) = c. $$
        \
        $$ \\text{If} \\displaystyle \\lim_{x \\rightarrow \\; a^{-}} y(x) \\neq \\displaystyle \\lim_{x \\rightarrow \\; a^{+}} y(x), \\text{then} \\displaystyle \\lim_{x \\rightarrow \\; a} y(x) = \\text{Does Not Exist}. $$
      `;
  }

  return (
    <div>
      <EquationLayout
        title={"Calculus Limits"}
        instructions={"Compute the limits."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={3}
        exampleFunction={exampleFunctionToDisplay}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{ title: "Limits Help", helpTextFunc: helpText }}
        />
    </div>
  );
}
