import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogLogSubtraction() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,secondNumber);
      let result2 = result1*thirdNumber;

      generatedProblem.push({
        key: `${step}-ls`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$Log_{${equation.firstNumber}} (x) - Log_{${equation.firstNumber}} (${equation.thirdNumber}) = ${equation.secondNumber}$`,
      answer: `$x = ${equation.result2}$`,
    }
  }

  function helpText() {
    return `
    $$ Log_{4}(x) - Log_{4}(5) = 3 $$
    \
      $$ Log_{4} \\left( \\frac{x}{5} \\right) = 3 $$    
      \
      $$ 4^{3} = \\frac{x}{5} $$
      \
      $$ 64 = \\frac{x}{5} $$
      \
      $$ 5 \\cdot \\left(64 = \\frac{x}{5} \\right) $$
      \
      $$ 5 \\cdot 64 = \\frac{5x}{5} $$
      \
      $$ 320 = x $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Logarithm Subtraction"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Logarithmic Subtraction Help", helpTextFunc: helpText}}
      />
    </div>
  );
}