import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function CalculusSecondOrderODE() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = -1*(firstNumber + secondNumber);
      let result2 = firstNumber*secondNumber;
      let sign1 = "+"
      let sign2 = "+"

      if(result1 < 0) {
          result1 = Math.abs(result1);
          sign1 = "-";
      }
      if(result2 < 0) {
        result2 = Math.abs(result2);
        sign2 = "-";
      }       

      generatedProblem.push({
        key: `${step}-qu`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        sign1: sign1,
        sign2: sign2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1
    let equationToDisplay = `$y'' ${equation.sign1} ${equation.result1}y' ${equation.sign2} ${equation.result2}y = 0$`
    if(middleTerm === 1) {
      equationToDisplay = `$y'' ${equation.sign1}y' ${equation.sign2} ${equation.result2}y = 0$`
    }
    else if(middleTerm === 0) {
      equationToDisplay = `$y'' ${equation.sign2} ${equation.result2} = 0$`
    }

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$y = C_{1} e^{${equation.firstNumber}x} + C_{2} e^{${equation.secondNumber}x}$`,
    }
  }

  function helpText() {
    return `
        $$ y'' - 5y' - 14y = 0 $$
        \
        $$ r^{2} - 5r - 14 = 0 $$
        \
        $$ (r - 7)(r + 2) = 0 $$
        \
        $$ r - 7 = 0 $$
        \
        $$ +7 \\hspace{7mm} +7 $$
        \
        $$ r_{1} = 7 $$
        \
        $$ r + 2 = 0 $$
        \
        $$ -2 \\hspace{7mm} -2 $$
        \
        $$ r_{2} = -2 $$
        \
        $$ y = C_{1} e^{r_{1} x} + C_{2} e^{r_{2} x} $$
        \
        $$ y = C_{1} e^{7x} + C_{2} e^{-2x} $$
      `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Calculus Second Order ODE"}
        instructions={"Solve the Differential Equation."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Second Order ODE Help", helpTextFunc: helpText}}
      />
    </div>
  );
}