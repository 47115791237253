import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { randomNumFromSet, roundNumber } from '../../../../utils/mathHelpers';

export default function Vector_MatrixCrossProduct() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }
      let secondSet = { one: randomNumFromSet(nums), two: randomNumFromSet(nums), three: randomNumFromSet(nums) }

      let finalSet = {
        one: (firstSet.two * secondSet.three) - (secondSet.two * firstSet.three),
        two: -1 * ((firstSet.one * secondSet.three) - (secondSet.one * firstSet.three)),
        three:(firstSet.one * secondSet.two) - (secondSet.one * firstSet.two)
      }

      let area =  Math.sqrt(Math.pow(finalSet.one, 2) + Math.pow(finalSet.two, 2) + Math.pow(finalSet.three, 2))

      generatedProblem.push({
        key: `${step}-lv`,
        firstSet: firstSet,
        secondSet: secondSet,
        finalSet: finalSet,
        area: area
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$<${equation.finalSet.one}, ${equation.finalSet.two}, ${equation.finalSet.three}>$`}</span>
      <br/>
      <span>{`$\\text{Area of Parallelogram} = ${roundNumber(equation.area)}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$<${equation.firstSet.one}, ${equation.firstSet.two}, ${equation.firstSet.three}> \\times <${equation.secondSet.one}, ${equation.secondSet.two}, ${equation.secondSet.three}>$`,
      answer: answerToDisplay
    }
  }

  function helpText() {
    return `
    $$ < 4, \\hspace{1mm} -6, \\hspace{1mm} 5> \\times <7, \\hspace{1mm} 8, \\hspace{1mm} -1> $$
    
    \

    $$ = \\begin{vmatrix}
    \\hat{x} & \\hat{y} & \\hat{z}\\\\
    4 & -6 & 5\\\\
    7 & 8 & -1
    \\end{vmatrix} $$

    \

    $$ = \\begin{vmatrix}
    -6 & 5\\\\
    8 & -1
    \\end{vmatrix} \\hat{x}
    
    -
    
    \\begin{vmatrix}
    4 & 5\\\\
    7 & -1
    \\end{vmatrix} \\hat{y}
    
    +
    
    \\begin{vmatrix}
    4 & -6\\\\
    7 & 8
    \\end{vmatrix} \\hat{z} $$

    \

    $$ = \\begin{pmatrix}
    ((-6) \\cdot (-1) - 8 \\cdot 5 ) \\hat{x} \\\\
    - \\\\
    (4 \\cdot (-1) - 7 \\cdot 5) \\hat{y} \\\\
    + \\\\
    (4 \\cdot 8 - 7 \\cdot (-6)) \\hat{z}
    \\end{pmatrix} $$

    \
    $$ = (6 - 40) \\hat{x} - (-4 - 35) \\hat{y} + (32 + 42) \\hat{z} $$
    \
    $$ = (-34) \\hat{x} - (-39) \\hat{y} + (74) \\hat{z} $$
    \
    $$ = -34 \\hat{x} + 39 \\hat{y} + 74 \\hat{z} $$
    \
    $$ = \\hspace{1mm} <-34, \\hspace{1mm} 39, \\hspace{1mm} 74> $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Vector/Matrix Cross Product"}
        instructions={"Compute the following and find the area of the parallelogram."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Cross Product Help", helpTextFunc: helpText}}
      />
    </div>
  );
}