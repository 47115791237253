import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialQuadraticOneStep() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15].sort( () => .5 - Math.random() );
    const generatedProblem = [];

    for (let step = 0; step < nums.length; step++) {
      let firstNumber = nums[step];
      let result = firstNumber * firstNumber;   

      generatedProblem.push({
        key: `${step}-qos`,
        firstNumber: firstNumber,
        exponent: 2,
        result: result
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$x^${equation.exponent} = ${equation.result}$`,
      answer: `$x= \\pm ${Math.abs(equation.firstNumber)}$`,
    }
  }

  function helpText() {
    return `
      $$ x^{2} = 49 $$    
      \
      $$ x^{2} = (\\pm 7)^{2} $$
      \
      $$ ^{2} \\sqrt{x^{2}} = \\hspace{2mm} ^{2} \\sqrt{(\\pm 7)^{2}} $$
      \
      $$ \\sqrt{x^{2}} = \\sqrt{(\\pm 7)^{2}} $$
      \
      $$ x = \\pm 7 $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Quadratic One Step"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quadratic One Step Help", helpTextFunc: helpText}}
      />
    </div>
  );
}