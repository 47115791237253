import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialParabola() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = -1*(firstNumber + secondNumber);
      let result2 = firstNumber*secondNumber;
      let result3 = (firstNumber + secondNumber)/2;
      let result4 = Math.pow(result3,2)+(result1*result3)+result2;
      let result5 = firstNumber*secondNumber;
      let sign1 = "+"
      let sign2 = "+"
      let sign3 = ""
      
      if(result1 < 0) {
          result1 = Math.abs(result1);
          sign1 = "-";
      }
      if(result2 < 0) {
        result2 = Math.abs(result2);
        sign2 = "-";
      }
      if(result5 < 0) {
        result5 = Math.abs(result5);
        sign3 = "-";
      }

      generatedProblem.push({
        key: `${step}-qp`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1
    let equationToDisplay = `${equation.sign1} ${equation.result1}x ${equation.sign2} ${equation.result2}`
    if(middleTerm === 1) {
      equationToDisplay = `${equation.sign1} x ${equation.sign2} ${equation.result2}`
    }
    else if(middleTerm === 0){
      equationToDisplay = `${equation.sign2} ${equation.result2}`
    }
    let answerToDisplay = (
      <span>
      <span>{`$x-int = (${equation.firstNumber}, 0)  (${equation.secondNumber}, 0)$`}</span>
      <br/>
      <span>{`$y-int = (0,${equation.sign3} ${equation.result5})$`}</span>
      <br/>
      <span>{`$vertex = (${equation.result3},${equation.result4})$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$f(x) = x^2 ${equationToDisplay}$`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
      $$ f(x) = x^{2} + 3x - 40 $$    
      \
      $$ X-Intercepts: f(x) = y = 0 $$
      \
      $$ f(x) = (x + 8)(x - 5) $$
      \
      $$ 0 = (x + 8)(x - 5) $$
      \
      $$ x + 8 = 0 $$
      \
      $$ -8 \\hspace{7mm} -8 $$
      \
      $$ x = -8 $$
      \
      $$ x - 5 = 0 $$
      \
      $$ +5 \\hspace{7mm} +5 $$
      \
      $$ x = 5 $$
      \
      $$ X-Intercepts = (-8, 0), (5, 0) $$
      \
      $$ Y-Intercept: x = 0 $$
      \
      $$ f(x) = x^{2} + 3x - 40 $$
      \
      $$ f(0) = (0)^{2} + 3(0) - 40 $$
      \
      $$ f(0) = 0 + 0 - 40 $$
      \
      $$ f(0) = -40 $$
      \
      $$ Y-Intercept = (0, -40) $$
      \
      $$ Vertex = (m, f(m)) $$
      \
      $$ f(x) = ax^{2} + bx + c $$
      \
      $$ f(x) = 1 \\cdot x^{2} + 3x - 40 $$
      \
      $$ a = 1, b = 3, c = -40 $$
      \
      $$ m = \\frac{-b}{2a} = \\frac{-3}{2 \\cdot 1} = \\frac{-3}{2} = -1.5 $$
      \
      $$ f(m) = f \\left( \\frac{-3}{2} \\right) = \\left( \\frac{-3}{2} \\right)^{2} + 3 \\left( \\frac{-3}{2} \\right) - 40 $$
      \
      $$ f(m) = \\frac{9}{4} + \\frac{-9}{2} - 40 $$
      \
      $$ f(m) = \\frac{9}{4} + \\frac{-18}{4} - \\frac{160}{4} $$
      \
      $$ f(m) = \\frac{9 - 18 - 160}{4} $$
      \
      $$ f(m) = \\frac{-169}{4} = -42.25 $$
      \
      $$ Vertex = (m, f(m)) = (-1.5, -42.25) $$

      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Parabola"}
        instructions={"Compute the x-int, y-int, and vertex."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Parabola Help", helpTextFunc: helpText}}
      />
    </div>
  );
}