import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepC() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * (secondNumber + thirdNumber);
      let sign = "+"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-ltsc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(x ${equation.sign} ${equation.thirdNumber}) = ${equation.result}$`,
      answer: `$x = ${equation.secondNumber}$`
    }
  }

  function helpText() {
    return `

    $$ 4(x + 12) = 80 $$       
    \
    $$ \\dfrac{4(x + 12) = 80}{4} $$
    \
    $$ \\dfrac{4(x + 12)}{4} = \\dfrac{80}{4} $$
    \
    $$ \\dfrac{4 \\cdot (x + 12)}{4 \\cdot 1} = \\dfrac{4 \\cdot 20}{4 \\cdot 1} $$
    \
    $$ \\dfrac{x + 12}{1} = \\dfrac{20}{1} $$
    \
    $$ x + 12 = 20 $$
    \
    $$ -12 \\hspace{5mm} -12 $$
    \
    $$ x + 0 = 20 - 12 $$
    \
    $$ x = 8 $$

    `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear Two Step C"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step C Help", helpTextFunc: helpText}}
      />
    </div>
  );
}