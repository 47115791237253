import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

import { randomIntFromInterval } from '../../../../utils/mathHelpers';

export default function StatisticsSpread() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  const median = (arr) => {
    let mid = arr.length / 2;
    return mid % 1 ? arr[mid - 0.5] : (arr[mid - 1] + arr[mid]) / 2;
  }

  const splitSet = (arr) => {
    let midPoint = arr.length / 2
    let toAdd = arr.length % 2

    return {
      firstHalf: arr.slice(0, midPoint),
      secondHalf: arr.slice(midPoint + toAdd, arr.length)
    }
  }

  function equationMaker() {
    const nums = [5, 6, 7, 8, 9]; // number of numbers allowed in the set
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let numberSize = nums[Math.floor(Math.random()*nums.length)];
      let initialNumberSet = []
      for (let x = 0; x < numberSize; x++) {
        initialNumberSet.push(randomIntFromInterval(0, 20))
      }
      let ogSet = initialNumberSet.map((x) => x)

      let numberSet = initialNumberSet.sort(function(a, b) {
        return a - b;
      });
      let mean = numberSet.reduce((a, b) => a + b, 0) / numberSet.length
      console.log("mean " + mean)
      let setDifference = numberSet.map((x) => x - mean)
      console.log("setDifference " + setDifference)
      let setDifferenceSquared = setDifference.map((x) => x*x )
      console.log("setDifferenceSquared " + setDifferenceSquared)
      let sumOfDifferenceSquared = setDifferenceSquared.reduce((a, b) => a + b, 0)
      console.log("sumOfDifferenceSquared " + sumOfDifferenceSquared)
      let splitSetInHalfs = splitSet(numberSet)
      console.log("splitSetInHalfs " + splitSetInHalfs)
      let firstHalf = splitSetInHalfs.firstHalf
      console.log("firstHalf " + firstHalf)
      let secondHalf = splitSetInHalfs.secondHalf
      console.log("secondHalf " + secondHalf)
      let q1 = median(firstHalf)
      console.log("q1 " + q1)
      let q3 = median(secondHalf)
      console.log("q3 " + q3)
      let sampleVairance = sumOfDifferenceSquared / (numberSet.length - 1)
      console.log("sampleVairance " + sampleVairance)
      let populationVariance = sumOfDifferenceSquared / numberSet.length
      console.log("populationVariance " + populationVariance)

      generatedProblem.push({
        key: `${step}-sc`,
        numberSet: ogSet,
        sampleSize: numberSet.length,
        min: Math.min.apply(Math, numberSet),
        max: Math.max.apply(Math, numberSet),
        range: Math.max.apply(Math, numberSet) - Math.min.apply(Math, numberSet),
        q1: q1,
        q3: q3,
        interQ: q3 - q1,
        sampleVariance: sampleVairance,
        populationVariance: populationVariance,
        sampleStd: Math.sqrt(sampleVairance),
        popStd: Math.sqrt(populationVariance)
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let answerToDisplay = (
      <span>
      <span>{`$ \\text{Sample Size} = ${equation.sampleSize}$`}</span>
      <br/>
      <span>{`$ \\text{Min} = ${equation.min}$`}</span>
      <br/>
      <span>{`$ \\text{Max} = ${equation.max}$`}</span>
      <br/>
      <span>{`$ \\text{Range} = ${equation.range}$`}</span>
      <br/>
      <span>{`$ \\text{Quartile 1} = ${equation.q1}$`}</span>
      <br/>
      <span>{`$ \\text{Quartile 3} = ${equation.q3}$`}</span>
      <br/>
      <span>{`$ \\text{Inter Quartile Range} = ${equation.interQ}$`}</span>
      <br/>
      <span>{`$ \\text{Sample Variance} = ${Math.round(equation.sampleVariance*100)/100}$`}</span>
      <br/>
      <span>{`$ \\text{Population Variance} = ${Math.round(equation.populationVariance*100)/100}$`}</span>
      <br/>
      <span>{`$ \\text{Sample Standard Deviation} = ${Math.round(equation.sampleStd*100)/100}$`}</span>
      <br/>
      <span>{`$ \\text{Population Standard Deviation} = ${Math.round(equation.popStd*100)/100}$`}</span>
      <br/>
      </span>
    )

    return {
      key: equation.key,
      equation: `$ \\{ ${equation.numberSet} \\}$`,
      answer: answerToDisplay
    }
  }

  return (
    <div>
      

      <EquationLayout
       title={"Statistics Spread"}
       instructions={"Compute all measures of spread."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}
