import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialFoil() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, -2, -3, -4, -5, -6, -7, -8, -9];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*thirdNumber;
      let result2 = firstNumber*fourthNumber + secondNumber*thirdNumber;
      let result3 = firstNumber*fifthNumber + secondNumber*fourthNumber;
      let result4 = firstNumber*sixthNumber + secondNumber*fifthNumber;
      let result5 = sixthNumber*secondNumber;
      let sign1 = "+"
      let sign2 = "+"
      let sign3 = "+"
      let sign4 = "+"
      let sign5 = "+"
      let sign7 = "+"
      let sign8 = "+"
      let sign9 = "+"

    if(secondNumber < 0) {
        secondNumber = Math.abs(secondNumber);
        sign1 = "-";
    }
    if(result2 < 0) {
        result2 = Math.abs(result2);
        sign2 = "-";
    }       

    if(result3 < 0) {
        result3 = Math.abs(result3);
        sign3 = "-";
    }
    if(result4 < 0) {
        result4 = Math.abs(result4);
        sign4 = "-";
    }       
    if(result5 < 0) {
        result5 = Math.abs(result5);
        sign5 = "-";
    }  
    if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign7 = "-";
    }
    if(fifthNumber < 0) {
        fifthNumber = Math.abs(fifthNumber);
        sign8 = "-";
    }
    if(sixthNumber < 0) {
        sixthNumber = Math.abs(sixthNumber);
        sign9 = "-";
    }
      generatedProblem.push({
        key: `${step}-qf`,
        firstNumber: firstNumber,
        exponent: 2,
        middleTerm: result1,
        secondNumber: secondNumber,
        thirdNumber: removeOne(thirdNumber),
        fourthNumber: removeOne(fourthNumber),
        fifthNumber: removeOne(fifthNumber),
        sixthNumber: sixthNumber,
        result1: removeOne(result1),
        result2: removeOne(result2),
        result3: removeOne(result3),
        result4: removeOne(result4),
        result5: result5,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3,
        sign4: sign4,
        sign5: sign5,
        sign7: sign7,
        sign8: sign8,
        sign9: sign9
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function removeOne(num) {
    return num === 1 ? "" : num
  }

  function equationFormatter(equation) {
    let middleTerm = equation.middleTerm;
    let answerToDisplay = `$${equation.thirdNumber}x^{3} ${equation.sign7} ${equation.fourthNumber}x^{2} ${equation.sign8} ${equation.fifthNumber}x ${equation.sign9} ${equation.sixthNumber}$`
    if(middleTerm === 1) {
      answerToDisplay = `$${equation.thirdNumber}x^{3} ${equation.sign7} ${equation.fourthNumber}x^{2} ${equation.sign8} ${equation.fifthNumber}x ${equation.sign9} ${equation.sixthNumber}$`
    }
    else if(middleTerm === 0) {
      answerToDisplay = `$${equation.thirdNumber}x^{3} ${equation.sign7} ${equation.fourthNumber}x^{2} ${equation.sign8} ${equation.fifthNumber}x ${equation.sign9} ${equation.sixthNumber}$`
    }

    let equationNumarator = `${equation.result1}x^{4}`
    if (equation.result2 !== 0) {
      equationNumarator = equationNumarator.concat(`${equation.sign2} ${equation.result2}x^{3}`)
    }
    if (equation.result3 !== 0) {
      equationNumarator = equationNumarator.concat(`${equation.sign3} ${equation.result3}x^{2}`)
    }
    if (equation.result4 !== 0) {
      equationNumarator = equationNumarator.concat(`${equation.sign4} ${equation.result4}x`)
    }
    equationNumarator = equationNumarator.concat(`${equation.sign5} ${equation.result5}`)

    let equationDenominator = `${equation.firstNumber}x ${equation.sign1} ${equation.secondNumber}`
    return {
      key: equation.key,
      equation: `$\\dfrac{${equationNumarator}}{${equationDenominator}}$`,//`$\\dfrac{${equation.result1}x^{4} ${equation.sign2} ${equation.result2}x^{3} ${equation.sign3} ${equation.result3}x^{2} ${equation.sign4} ${equation.result4}x ${equation.sign5} ${equation.result5}}{${equation.firstNumber}x ${equation.sign1} ${equation.secondNumber}}$`,
      answer: answerToDisplay,
    }
  }

  return (
    <div>
      

      <EquationLayout
        title={"Polynomial Division"}
        instructions={"Divide the Polynomials."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}