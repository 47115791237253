import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function CalculusPowerRule() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [-12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12];
    const posnums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = posnums[Math.floor(Math.random()*posnums.length)];
      let fourthNumber = posnums[Math.floor(Math.random()*posnums.length)];
      let resultCoeff = firstNumber * secondNumber;
      let resultExp = secondNumber - 1;

      generatedProblem.push({
        key: `${step}-cdpr`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        resultCoeff: resultCoeff,
        resultExp: resultExp,
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{d}{dx} \\Bigl( ${equation.firstNumber}x^{${equation.secondNumber}} + ${equation.thirdNumber}x + ${equation.fourthNumber} \\Bigr) $`,
      answer: `$${equation.resultCoeff}x^{${equation.resultExp}} + ${equation.thirdNumber} $`
    }
  }

  function helpText() {
    return `
        $$ \\frac{d}{dx} (a x^{n}) = (n \\cdot a) x^{n - 1} $$
        \
        $$ \\frac{d}{dx} \\Bigl( 5x^{-7} + 9x + 3\\Bigr) $$
        \
        $$ = \\frac{d}{dx} \\Bigl( 5x^{-7} \\Bigr) + \\frac{d}{dx} \\Bigl( 9x \\Bigr) + \\frac{d}{dx} \\Bigl( 3 \\Bigr) $$
        \
        $$ = \\frac{d}{dx} \\Bigl( 5x^{-7} \\Bigr) + \\frac{d}{dx} \\Bigl( 9x^{1} \\Bigr) + \\frac{d}{dx} \\Bigl( 3x^{0} \\Bigr) $$
        \
        $$ = \\Bigl( (-7 \\cdot 5) \\cdot x^{-7 - 1} \\Bigr) + \\Bigl( (1 \\cdot 9) \\cdot x^{1 - 1} \\Bigr) + \\Bigl( (0 \\cdot 3) \\cdot x^{0 - 1} \\Bigr) $$
        \
        $$ = \\Bigl( (-35) \\cdot x^{-8} \\Bigr) + \\Bigl( (9) \\cdot x^{0} \\Bigr) + \\Bigl( (0) \\cdot x^{-1} \\Bigr) $$
        \
        $$ = \\Bigl( -35 \\cdot x^{-8} \\Bigr) + \\Bigl( 9 \\cdot 1 \\Bigr) + \\Bigl( 0 \\cdot x^{-1} \\Bigr) $$
        \
        $$ = -35x^{-8} + 9 + 0 $$
        \
        $$ = -35x^{-8} + 9 $$
      `
  }

  return (
    <div>
      

      <EquationLayout
      title={"Calculus Power Rule"}
      instructions={"Compute the derivative."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Power Rule Help", helpTextFunc: helpText}}
      />
    </div>
  );
}