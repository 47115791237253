import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';


export default function ArithmeticSubtractionC() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    let vector = [];

  for (let i = 10; i <= 99; i++) {
    vector.push(i);
  }
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = vector[Math.floor(Math.random()*vector.length)];
      let secondNumber = vector[Math.floor(Math.random()*vector.length)];
      let result = firstNumber - secondNumber;

      generatedProblem.push({
        key: `${step}-asc`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result: result,
        sign: "-"
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber} ${equation.sign} ${equation.secondNumber}$`,
      answer: `$${equation.result}$`
    }
  }

  return (
    <div>
      <EquationLayout
        title={"Arithmetic Subtraction C"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
      />
    </div>
  );
}