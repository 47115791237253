import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearThreeStepA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * (secondNumber * thirdNumber + fourthNumber);
      let sign = "+"

      if(fourthNumber < 0) {
        fourthNumber = Math.abs(fourthNumber);
        sign = "-";
      }

      generatedProblem.push({
        key: `${step}-lthsa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        forthNumber: fourthNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$${equation.firstNumber}(${equation.secondNumber}x ${equation.sign} ${equation.forthNumber}) = ${equation.result}$`,
      answer: `$x = ${equation.thirdNumber}$`
    };
  }

  function helpText() {
    return `

    $$ 8(2x + 7) = -40 $$       
    \
    $$ \\dfrac{8(2x + 7) = -40}{8} $$
    \
    $$ \\dfrac{8(2x + 7)}{8} = \\dfrac{-40}{8} $$
    \
    $$ \\dfrac{8 \\cdot (2x + 7)}{8 \\cdot 1} = \\dfrac{-5 \\cdot 8}{1 \\cdot 8} $$
    \
    $$ \\dfrac{(2x + 7)}{1} = \\dfrac{-5}{1} $$
    \
    $$ 2x + 7 = -5 $$
    \
    $$ -7 \\hspace{7mm} -7 $$
    \
    $$ 2x + 0 = -5 - 7 $$
    \
    $$ 2x = -12 $$
    \
    $$ \\dfrac{2x = -12}{2} $$
    \
    $$ \\dfrac{2x}{2} = \\dfrac{-12}{2} $$
    \
    $$ \\dfrac{2 \\cdot x}{2 \\cdot 1} = \\dfrac{-6 \\cdot 2}{1 \\cdot 2} $$
    \
    $$ \\dfrac{x}{1} = \\dfrac{-6}{1} $$
    \
    $$ x = -6 $$
    `
  }

  return (
    <div>

      <EquationLayout
        title={"Linear Three Step A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Three Step A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}