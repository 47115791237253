import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialCompleteTheSquare() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber + secondNumber;
      let result2 = Math.pow(result1,2);
      let result3 = 2*firstNumber;
      let result4 = Math.pow(secondNumber,2)+2*secondNumber*firstNumber;
      let result5 = -1*result1 - firstNumber;
      let sign1 = "+"
      let sign2 = "+"
      let sign3 = "+"

      if(result3 < 0) {
          result3 = Math.abs(result3);
          sign3 = "-";
      }

      generatedProblem.push({
        key: `${step}-qcts`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        resutl2: result2,
        result3: result3,
        result4: result4,
        result5: result5,
        sign1: sign1,
        sign2: sign2,
        sign3: sign3
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: ``,
      answer: ``,
    }
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$x^2 ${equation.sign3} ${equation.result3} x = ${equation.result4}$`,
      answer: `$x = ${equation.secondNumber}, ${equation.result5}$`,
    }
  }

  function helpText() {
    return `
      $$ x^{2} + 18x = -56 $$    
      \
      $$ x^{2} + \\textbf{18} x = -56 $$
      \
      $$ \\left( \\frac{\\textbf{18}}{2} \\right)^{2} = 9^{2} = 81 $$
      \
      $$ x^{2} + 18x = -56 $$
      \
      $$ +81 \\hspace{7mm} +81 $$
      \
      $$ x^{2} + 18x + 81 = -56 + 81 $$
      \
      $$ x^{2} + 18x + 81 = 25 $$
      \
      $$ (x + 9)(x + 9) = 25$$
      \
      $$ (x + 9)^{2} = (\\pm 5)^{2} $$
      \
      $$ \\sqrt{(x + 9)^{2}} = \\sqrt{(\\pm 5)^{2}} $$
      \
      $$ x + 9 = \\pm 5 $$
      \
      $$ -9 \\hspace{7mm} -9 $$
      \
      $$ x = \\pm 5 - 9 $$
      \
      $$ x = 5 - 9 = -4 $$
      \
      $$ x = - 5 - 9 = - 14 $$
      \
      $$ x = -14, -4 $$
      `
  }

  return (
    <div>
  
      <EquationLayout
        title={"Polynomial Complete the Square"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Complete The Square Help", helpTextFunc: helpText}}
      />
    </div>
  );
}