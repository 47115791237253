import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Exp_LogLogTwoStepA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = Math.pow(firstNumber,secondNumber)-thirdNumber;

      generatedProblem.push({
        key: `${step}-etsa`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$Log_{${equation.firstNumber}} (x+${equation.thirdNumber}) = ${equation.secondNumber}$`,
      answer: `$x = ${equation.result1}$`,
    }
  }

  function helpText() {
    return `
    $$ Log_{2}(x + 7) = 3 $$
    \
      $$ 2^{3} = x + 7 $$    
      \
      $$ 8 = x + 7 $$
      \
      $$ -7 \\hspace{7mm} -7 $$
      \
      $$ 1 = x $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Logarithm Two Step A"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Logarithmic Two Step A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}