import { randomIntFromInterval, randomNumFromSet } from '../../../../utils/mathHelpers';
import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticMixedNumbers() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = randomIntFromInterval(2, 13);
      let secondNumber = randomIntFromInterval(2, 13);
      let thirdNumber = randomIntFromInterval(2, 13);
     
      while(firstNumber >= secondNumber) {
        firstNumber = randomIntFromInterval(2, 13);
        secondNumber = randomIntFromInterval(2, 13);
      }

      let result1 = firstNumber + secondNumber*thirdNumber;

      generatedProblem.push({
        key: `${step}-aif`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ ${equation.thirdNumber} \\frac{${equation.firstNumber}}{${equation.secondNumber}} $`,
      answer: `$\\dfrac{${equation.result1}}{${equation.secondNumber}}$`
    }
  }

  function helpText() {
    return `
    
      $$ 4 \\frac{5}{7} $$
      \
      $$ = 4 + \\frac{5}{7} $$
      \
      $$ = \\frac{4}{1} + \\frac{5}{7} $$
      \
      $$ = \\dfrac{4 \\cdot 7}{1 \\cdot 7} + \\dfrac{5}{7} $$
      \
      $$ = \\dfrac{28}{7} + \\dfrac{5}{7} $$
      \
      $$ = \\dfrac{28 + 5}{7} $$
      \
      $$ = \\dfrac{33}{7} $$
      
    `
  }

  return (
    <div>
     
      <EquationLayout
        title={"Arithmetic Mixed Numbers"}
        instructions={"Convert into an Improper Fraction."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Mixed Numbers Help", helpTextFunc: helpText}}
      />
    </div>
  );
}