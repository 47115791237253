import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function LinearTwoStepD() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13, -14, -15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let result = firstNumber * (secondNumber + thirdNumber);
      let sign = "-"

      if(thirdNumber < 0) {
        thirdNumber = Math.abs(thirdNumber);
        sign = "+";
      }

      generatedProblem.push({
        key: `${step}-ltsd`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        result: result,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{x}{${equation.firstNumber}} ${equation.sign} ${equation.thirdNumber} = ${equation.secondNumber}$`,
      answer: `$x = ${equation.result}$`
    }
  }

  function helpText() {
    return `

    $$ \\dfrac{x}{4} - 6 = 7 $$       
    \
    $$ + 6 \\hspace{7mm} + 6 $$
    \
    $$ \\dfrac{x}{4} + 0 = 7 + 6 $$
    \
    $$ \\dfrac{x}{4} = 13 $$
    \
    $$ 4 \\cdot \\left(\\dfrac{x}{4} = 13 \\right) $$
    \
    $$ \\dfrac{4x}{4} = 4 \\cdot 13 $$
    \
    $$ \\dfrac{4 \\cdot x}{4 \\cdot 1} = 4 \\cdot 13 $$
    \
    $$ \\dfrac{x}{1} = 52 $$
    \
    $$ x = 52 $$

    `
  }

  return (
    <div>
      
      <EquationLayout
        title={"Linear Two Step D"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Two Step D Help", helpTextFunc: helpText}}
      />
    </div>
  );
}