import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { logarithm } from '../../../../utils/mathHelpers';

export default function CalculusAverageRateofChange() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9];
    const generatedProblem = [];

    let firstNumber = nums[Math.floor(Math.random()*nums.length)];
    let secondNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
    let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
    let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
    let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
    let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
    let eighthNumber = nums[Math.floor(Math.random()*nums.length)];
    let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
    let tenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let eleventhNumber = nums[Math.floor(Math.random()*nums.length)];
    let twelfthNumber = nums[Math.floor(Math.random()*nums.length)];
    let thirteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let fourteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let fifteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let sixteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let seventeenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let eighteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let nineteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentyNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentyoneNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentytwoNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentythreeNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentyfourNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentyfiveNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentysixNumber = nums[Math.floor(Math.random()*nums.length)];
    let twentysevenNumber = nums[Math.floor(Math.random()*nums.length)];

    // Make sure numbers are not the same
    while(thirdNumber >= fourthNumber) {
      thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      fourthNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(seventhNumber >= eighthNumber) {
        seventhNumber = nums[Math.floor(Math.random()*nums.length)];
        eighthNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(twelfthNumber >= thirteenthNumber) {
        twelfthNumber = nums[Math.floor(Math.random()*nums.length)];
        thirteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(fourteenthNumber >= fifteenthNumber) {
        fourteenthNumber = nums[Math.floor(Math.random()*nums.length)];
        fifteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(seventeenthNumber >= eighteenthNumber) {
        seventeenthNumber = nums[Math.floor(Math.random()*nums.length)];
        eighteenthNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(twentyNumber >= twentyoneNumber) {
        twentyNumber = nums[Math.floor(Math.random()*nums.length)];
        twentyoneNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(twentythreeNumber >= twentyfourNumber) {
        twentythreeNumber = nums[Math.floor(Math.random()*nums.length)];
        twentyfourNumber = nums[Math.floor(Math.random()*nums.length)];
    }
    while(twentysixNumber >= twentysevenNumber) {
        twentysixNumber = nums[Math.floor(Math.random()*nums.length)];
        twentysevenNumber = nums[Math.floor(Math.random()*nums.length)];
    }

    let result1a = firstNumber*thirdNumber + secondNumber;
    let result1b = firstNumber*fourthNumber + secondNumber;
    let result1c = (result1b - result1a)/(fourthNumber - thirdNumber);
    let result2a = fifthNumber*seventhNumber + sixthNumber;
    let result2b = fifthNumber*eighthNumber + sixthNumber;
    let result2c = (result2b - result2a)/(eighthNumber - seventhNumber);
    let result3a = ninthNumber*Math.pow(twelfthNumber,2) + tenthNumber*twelfthNumber + eleventhNumber;
    let result3b = ninthNumber*Math.pow(thirteenthNumber,2) + tenthNumber*thirteenthNumber + eleventhNumber;
    let result3c = (result3b - result3a)/(thirteenthNumber - twelfthNumber);
    let result4a = thirdNumber*Math.pow(fourteenthNumber,2) + fourthNumber*fourteenthNumber + fifthNumber;
    let result4b = thirdNumber*Math.pow(fifteenthNumber,2) + fourthNumber*fifteenthNumber + fifthNumber;
    let result4c = (result4b - result4a)/(fifteenthNumber - fourteenthNumber);
    let result5a = Math.pow(sixteenthNumber,seventeenthNumber);
    let result5b = Math.pow(sixteenthNumber,eighteenthNumber);
    let result5c = Math.round(100*(result5b - result5a)/(eighteenthNumber - seventeenthNumber))/100;
    let result6a = Math.pow(nineteenthNumber,twentyNumber);
    let result6b = Math.pow(nineteenthNumber,twentyoneNumber);
    let result6c = Math.round(100*(result6b - result6a)/(twentyoneNumber - twentyNumber))/100;
    let result7a = logarithm(twentytwoNumber,twentythreeNumber);
    let result7b = logarithm(twentytwoNumber,twentyfourNumber);
    let result7c = Math.round(100*(result7b - result7a)/(twentyfourNumber - twentythreeNumber))/100;
    let result8a = logarithm(twentyfiveNumber, twentysixNumber);
    let result8b = logarithm(twentyfiveNumber,twentysevenNumber);
    let result8c = Math.round(100*(result8b - result8a)/(twentysevenNumber - twentysixNumber))/100;

    generatedProblem.push({
      key: "1-caroc",
      firstNumber: firstNumber,
      secondNumber: secondNumber,
      thirdNumber: thirdNumber,
      fourthNumber: fourthNumber,
      result1a: result1a,
      result1b: result1b,
      result1c: result1c,
      result: `$ ${result1c} $`,
      format: <span>{`$ y = ${firstNumber}x + ${secondNumber} \\; \\; [${thirdNumber}, ${fourthNumber}] $`}</span>
    });

    generatedProblem.push({
      key: "2-caroc",
      fifthNumber: fifthNumber,
      sixthNumber: sixthNumber,
      seventhNumber: seventhNumber,
      eighthNumber: eighthNumber,
      result2a: result2a,
      result2b: result2b,
      result2c: result2c,
      result: `$ ${result2c} $`,
      format: <span>{`$ y = ${fifthNumber}x + ${sixthNumber} \\; \\; [${seventhNumber}, ${eighthNumber}] $`}</span>
    });

    generatedProblem.push({
        key: "3-caroc",
        ninthNumber: ninthNumber,
        tenthNumber: tenthNumber,
        eleventhNumber: eleventhNumber,
        twelfthNumber: twelfthNumber,
        thirteenthNumber: thirteenthNumber,
        result3a: result3a,
        result3b: result3b,
        result3c: result3c,
        result: `$ ${result3c} $`,
        format: <span>{`$ y = ${ninthNumber} x^{2} + ${tenthNumber} x + ${eleventhNumber} \\; \\; [${twelfthNumber}, ${thirteenthNumber}] $`}</span>
      });

      generatedProblem.push({
        key: "4-caroc",
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        fourteenthNumber: fourteenthNumber,
        fifteenthNumber: fifteenthNumber,
        result4a: result4a,
        result4b: result4b,
        result4c: result4c,
        result: `$ ${result4c} $`,
        format: <span>{`$ y = ${thirdNumber} x^{2} + ${fourthNumber} x + ${fifthNumber} \\; \\; [${fourteenthNumber}, ${fifteenthNumber}] $`}</span>
      });

      generatedProblem.push({
        key: "5-caroc",
        sixteenthNumber: sixteenthNumber,
        seventeenthNumber: seventeenthNumber,
        eighteenthNumber: eighteenthNumber,
        result5a: result5a,
        result5b: result5b,
        result5c: result5c,
        result: `$ ${result5c} $`,
        format: <span>{`$ y = ${sixteenthNumber}^{x} \\; \\; [${seventeenthNumber}, ${eighteenthNumber}] $`}</span>
      });

      generatedProblem.push({
        key: "6-caroc",
        nineteenthNumber: nineteenthNumber,
        twentyNumber: twentyNumber,
        twentyoneNumber: twentyoneNumber,
        result6a: result6a,
        result6b: result6b,
        result6c: result6c,
        result: `$ ${result6c} $`,
        format: <span>{`$ y = ${nineteenthNumber}^{x} \\; \\; [${twentyNumber}, ${twentyoneNumber}] $`}</span>
      });

      generatedProblem.push({
        key: "7-caroc",
        twentytwoNumber: twentytwoNumber,
        twentythreeNumber: twentythreeNumber,
        twentyfourNumber: twentyfourNumber,
        result7a: result7a,
        result7b: result7b,
        result7c: result7c,
        result: `$ ${result7c} $`,
        format: <span>{`$ y = Log_{${twentytwoNumber}} (x) \\; \\; [${twentythreeNumber}, ${twentyfourNumber}] $`}</span>
      });

      generatedProblem.push({
        key: "8-caroc",
        twentyfiveNumber: twentyfiveNumber,
        twentysixNumber: twentysixNumber,
        twentysevenNumber: twentysevenNumber,
        result8a: result8a,
        result8b: result8b,
        result8c: result8c,
        result: `$ ${result8c} $`,
        format: <span>{`$ y = Log_{${twentyfiveNumber}} (x) \\; \\; [${twentysixNumber}, ${twentysevenNumber}] $`}</span>
      });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ \\text{Average Rate of Change of} \\; y(x) \\; \\text{between} \\; a \\; \\text{and} \\; b $$
        \
        $$ = \\dfrac{y(b) - y(a)}{b - a} $$
        \
        $$ y = 2x^{2} - 5x + 7 \\; \\; [1, 4] $$
        \
        $$ y(4) = 2(4^{2}) - 5(4) + 7 $$
        \
        $$ y(4) = 2(16) - 20 + 7 $$
        \
        $$ y(4) = 32 - 13 $$
        \
        $$ y(4) = 19 $$
        \
        $$ y(1) = 2(1^{2}) - 5(1) + 7 $$
        \
        $$ y(1) = 2(1) - 5 + 7 $$
        \
        $$ y(1) = 2 + 2 $$
        \
        $$ y(1) = 4 $$
        \
        $$ \\text{Average Rate of Change} = \\dfrac{y(4) - y(1)}{4 - 1} $$
        \
         $$ = \\dfrac{19 - 4}{4 - 1} $$
        \
         $$ = \\dfrac{15}{3} $$
        \
        $$ = 5 $$
        
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Calculus Average Rate of Change"}
        instructions={"Compute the slope between the 2 points."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Average Rate of Change Help", helpTextFunc: helpText}}
      />
    </div>
  );
}