import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function PolynomialQuartic() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 5, 6, 7, 8, 10, 11, 12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = -1*(firstNumber + secondNumber);
      let result2 = firstNumber*secondNumber; 

      generatedProblem.push({
        key: `${step}-qu`,
        firstNumber: firstNumber,
        exponent: 2,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let middleTerm = equation.result1
    let equationToDisplay = `$x^4 ${equation.result1}x^{2} + ${equation.result2} = 0$`

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: `$x = \\pm \\sqrt{${equation.firstNumber}}, \\pm \\sqrt{${equation.secondNumber}}$`,
    }
  }

  function helpText() {
    return `
      $$ x^4 - 10x^{2} + 21 = 0  $$    
      \
      $$ (x^{2})^{2} - 10(x^{2}) + 21 = 0 $$
      \
      $$ \\text{Let} \\hspace{2mm} w = x^{2} $$
      \
      $$ (w)^{2} - 10(w) + 21 = 0 $$
      \
      $$ a + b = -7 - 3 = -10 $$
      \
      $$ a \\cdot b = (-7) \\cdot (-3) = 21 $$
      \
      $$ (w - 7)(w - 3) = 0 $$
      \
      $$ w - 7 = 0 $$
      \
      $$ +7 \\hspace{7mm} +7 $$
      \
      $$ w = 7 $$
      \
      $$ w - 3 = 0 $$
      \
      $$ +3 \\hspace{7mm} +3 $$
      \
      $$ w = 3 $$
      \
      $$ \\text{Remember} \\hspace {2mm} w = x^{2} $$
      \
      $$ x^{2} = 7 $$
      \
      $$ \\sqrt{x^{2}} = \\sqrt{7} $$
      \
      $$ x = \\pm \\sqrt{7} $$
      \
      $$ x^{2} = 3 $$
      \
      $$ \\sqrt{x^{2}} = \\sqrt{3} $$
      \
      $$ x = \\pm \\sqrt{3} $$
      \
      $$ x = \\pm \\sqrt{3}, \\hspace{1mm} \\pm \\sqrt{7} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Polynomial Quartic"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Quartic Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
