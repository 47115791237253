import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function Vector_MatrixMatrix2x2LinearCombinations() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, -2, -3, -4, -5, -6, -7, -8];
    const practice_problems_num = 4;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let thirdNumber = nums[Math.floor(Math.random()*nums.length)];
      let fourthNumber = nums[Math.floor(Math.random()*nums.length)];
      let fifthNumber = nums[Math.floor(Math.random()*nums.length)];
      let sixthNumber = nums[Math.floor(Math.random()*nums.length)];
      let seventhNumber = nums[Math.floor(Math.random()*nums.length)];
      let eighthNumber = nums[Math.floor(Math.random()*nums.length)];
      let ninthNumber = nums[Math.floor(Math.random()*nums.length)];
      let tenthNumber = nums[Math.floor(Math.random()*nums.length)];
 
      
      function roundNumber(number) {
        return Math.round(number*100)/100
      }

      let result1a = ninthNumber*firstNumber + tenthNumber*fifthNumber;
      let result2a = ninthNumber*secondNumber + tenthNumber*sixthNumber;
      let result3a = ninthNumber*thirdNumber + tenthNumber*seventhNumber;
      let result4a = ninthNumber*fourthNumber + tenthNumber*eighthNumber;

      generatedProblem.push({
        key: `${step}-vm3`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        thirdNumber: thirdNumber,
        fourthNumber: fourthNumber,
        fifthNumber: fifthNumber,
        sixthNumber: sixthNumber,
        seventhNumber: seventhNumber,
        eighthNumber: eighthNumber,
        ninthNumber: ninthNumber,
        tenthNumber: tenthNumber,
        result1a: result1a,
        result2a: result2a,
        result3a: result3a,
        result4a: result4a
      });
    }

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    let sign = "+";
    let tenthNumber = Math.abs(equation.tenthNumber)
    if (equation.tenthNumber < 0) {
      sign = "-"
    }

    let equationToDisplay = (
      <span>
        {
          `$${equation.ninthNumber} \\cdot \\begin{pmatrix}
          ${equation.firstNumber} & ${equation.secondNumber}\\\\
          ${equation.thirdNumber} & ${equation.fourthNumber}
          \\end{pmatrix} 
          
          ${sign}
          
          ${tenthNumber} \\cdot \\begin{pmatrix}
          ${equation.fifthNumber} & ${equation.sixthNumber}\\\\
          ${equation.seventhNumber} & ${equation.eighthNumber}
          \\end{pmatrix}$`
        }
      </span>
    )

    return {
      key: equation.key,
      equation: equationToDisplay,
      answer: 
        <span>
         {`$\\begin{pmatrix}
          ${equation.result1a} & ${equation.result2a}\\\\
          ${equation.result3a} & ${equation.result4a}
          \\end{pmatrix}$`}
        </span>
    }
  }

  function helpText() {
    return `
        $$ 5 \\cdot 
        \\left( \\begin{matrix}
        2 & -6 \\\\
        -4 & 7 \\\\
        \\end{matrix} \\right) 
        
        -

        2 \\cdot 
        \\left( \\begin{matrix}
        1 & 7 \\\\
        4 & -9 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
        5 \\cdot 2 & 5 \\cdot -6\\\\
        5 \\cdot -4 & 5 \\cdot 7\\\\
        \\end{matrix} \\right) 
        
        -
 
        \\left( \\begin{matrix}
        2 \\cdot 1 & 2 \\cdot 7 \\\\
        2 \\cdot 4 & 2 \\cdot -9 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
        10 & -30 \\\\
        -20 & 35 \\\\
        \\end{matrix} \\right) 
            
        -
     
        \\left( \\begin{matrix}
        2 & 14 \\\\
        8 & -18 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
        10 - 2 & -30 - 14 \\\\
        -20 - 8 & 35 - -18 \\\\
        \\end{matrix} \\right) $$

        \

        $$ = \\left( \\begin{matrix}
        8 & -44 \\\\
        -28 & 53 \\\\
        \\end{matrix} \\right) $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Vector/Matrix Matrix 2x2 Linear Combinations"}
        instructions={"Compute the Linear Combination of the Matricies."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Matrix 2x2 Linear Combinations Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
