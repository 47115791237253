import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function RationalOneStep() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = nums[Math.floor(Math.random()*nums.length)];
      let secondNumber = nums[Math.floor(Math.random()*nums.length)];
      let result1 = firstNumber*secondNumber;

      generatedProblem.push({
        key: `${step}-ros`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.result1}}{x} = ${equation.firstNumber}$`,
      answer: `$x = ${equation.secondNumber}$`,
    }
  }

  function helpText() {
    return `
      $$ \\frac{10}{x} = -2  $$    
      \
      $$ x \\cdot \\left( \\frac{10}{x} = -2 \\right) $$
      \
      $$ \\frac{10x}{x} = -2x $$
      \
      $$ \\frac{10 \\cdot x}{1 \\cdot x} = -2x $$
      \
      $$ \\frac{10}{1} = -2x $$
      \
      $$ 10 = -2x $$
      \
      $$ \\frac{10 = - 2x}{-2} $$
      \
      $$ \\frac{10}{-2} = \\frac{-2x}{-2} $$
      \
      $$ \\frac{(-2) \\cdot (-5)}{-2 \\cdot 1} = \\frac{-2 \\cdot x}{-2 \\cdot 1} $$
      \
      $$ \\frac{-5}{1} = \\frac{x}{1} $$
      \
      $$ -5 = x $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Rational One Step"}
        instructions={"Solve for x."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />


      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "One Step Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
