import { randomIntFromInterval, randomNumFromSet } from '../../../../utils/mathHelpers';
import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function ArithmeticDivisionA() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, [1]);

  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8 ,9, 10, 11, 12, 13, 14, 15];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let firstNumber = randomIntFromInterval(2, 9);
      let secondNumber = randomIntFromInterval(10, 99);
      let result1 = Math.floor(secondNumber/firstNumber);
      let result2 = secondNumber % firstNumber;

      generatedProblem.push({
        key: `${step}-ad`,
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        result1: result1,
        result2: result2
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$\\dfrac{${equation.secondNumber}}{${equation.firstNumber}}$`,
      answer: `$${equation.result1} \\; r ${equation.result2}$`
    }
  }

  function helpText() {
    return `$$
    
      { \\dfrac{19}{7}} $$
            
      \
    
      $$ {= \\dfrac{14 + 5}{7}} $$

      \

      $$ {= \\dfrac{14}{7} + \\dfrac{5}{7}} $$

      \

      $$ {= \\dfrac{2 \\cdot 7}{1 \\cdot 7} + \\dfrac{5}{7}}$$

      \

      $$ {= \\dfrac{2}{1} + \\dfrac{5}{7}} $$

      \

      $$ {= 2 + \\dfrac{5}{7}} $$

      \

      $$ {= 2 \\dfrac{5}{7}} $$

      \

      $$ {= 2 r 5} 
      
    $$`
  }

  return (
    <div>
     
      <EquationLayout
        title={"Arithmetic Division A"}
        instructions={"Compute the following."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Division A Help", helpTextFunc: helpText}}
      />
    </div>
  );
}